import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Grid, Button, Typography, Box, Menu, MenuItem, Paper, CircularProgress } from '@mui/material';
import { dashboardStyles } from './DashboardStyle';

import { IBoard } from '../spotlight/spotlight';
import { DashboardModal } from './DashboardModal';
import ConfirmationModal from '../spotlight/modal/ConfirmModal';

import { deleteBoard, fetchAllBoard } from '../../services/spotlightService';
import { retrieveChartDetails } from '../../slices/chartSlice';
import { RootState, useAppDispatch } from '../../store';

import AlertUtil from '../../utils/AlertUtil';
import { getBoardImg } from '../../utils/BoardUtil';
import { modifiedDate } from '../../utils/DateUtil';
import { amplitudeEvent } from '../../utils/amplitudeUtil';
import { copyBoardLink } from '../../utils/stringUtil';

import activePublicIcon from '../../images/board/active-public-icon.svg';

export default function DashboardComponent() {
  const classes = dashboardStyles();
  const history = useHistory();
  const dispatch = useAppDispatch();

  const [openDashboardToggle, toggleDashboardModal] = useState(false);
  const [activeId, setActiveId] = useState('');
  const [boards, setBoards] = useState<Array<IBoard>>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const [showConfirmModal, toggleConfirmModal] = useState(false);

  const [surveyAnchor, setSurveyAnchor] = React.useState<null | HTMLElement>(null);

  //tracking context
  const tracking_info = useSelector((state: RootState) => state.tracking);

  useEffect(() => {
    if (tracking_info) {
      amplitudeEvent(tracking_info, `dashboard tab viewed`, {});
    }
  }, [tracking_info]);

  const fetchDashboards = async () => {
    const results = await fetchAllBoard();
    if (results) {
      setBoards(results);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchDashboards();
  }, []);

  const createBtnHandler = () => {
    if (boards.length < 20) {
      toggleDashboardModal(true);
    } else {
      AlertUtil.fire({
        icon: 'error',
        title: 'Max 20 dashboards can be created',
      });
    }
  };

  // For delete the board
  const handleDeleteBoard = async () => {
    const deleteResult = await deleteBoard(activeId);
    if (deleteResult && deleteResult.message) {
      AlertUtil.fire({
        icon: 'success',
        title: 'Deleted successfully',
      });
      fetchDashboards();
      dispatch(retrieveChartDetails());
    }
  };

  const handleBoardRedirect = (board_id: string, name: string) => {
    if (tracking_info) {
      amplitudeEvent(tracking_info, 'dashboard viewed', { name, board_id });
    }
    history.push(`/board/${board_id}`);
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" mt="25%">
        <CircularProgress className={classes.loading} />
      </Box>
    );
  }

  return (
    <div>
      {openDashboardToggle && <DashboardModal toggleModal={toggleDashboardModal} open={openDashboardToggle} />}

      {showConfirmModal && (
        <ConfirmationModal
          toggleModal={toggleConfirmModal}
          open={showConfirmModal}
          handleDeleteBoard={handleDeleteBoard}
        />
      )}

      <Grid container lg={12} alignItems={'center'} justifyContent={'space-between'}>
        <Typography variant="h2">Dashboards</Typography>

        <Button variant="contained" onClick={() => createBtnHandler()}>
          + Create dashboard
        </Button>
      </Grid>

      <div>
        {boards.length === 0 ? (
          <Box display="flex" alignItems={'center'} justifyContent="center" mt={2}>
            <Typography variant={'body2'} color={'text.secondary'}>
              No dashboards display
            </Typography>
          </Box>
        ) : (
          <Grid container spacing={3} alignItems="stretch" mt={2}>
            {boards.map(({ name, description, created_at, created_by, board_link, board_id, public_access }, idx) => (
              <Grid item lg={4} key={idx} onClick={() => handleBoardRedirect(board_id, name)}>
                <Paper className={classes.dashboardPaper}>
                  <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      className={classes.boardImgBox}
                      style={{ backgroundColor: `${getBoardImg(board_id).color}` }}
                    >
                      {getBoardImg(board_id).image}
                    </Box>

                    <div style={{ marginLeft: 'auto' }}>
                      {public_access === 'public' && (
                        <Box display={'flex'} alignItems={'center'}>
                          <img src={activePublicIcon} alt="" />
                          <Typography variant="subtitle2" color="#1CA764">
                            Public
                          </Typography>
                        </Box>
                      )}

                      {/* <Box
                        p={0.5}
                        mr={-1.6}
                        onClick={e => {
                          e.stopPropagation();
                          setSurveyAnchor(e.currentTarget);
                          setActiveId(board_id);
                        }}
                      >
                        <MoreVertIcon className={classes.moreIcon} />
                      </Box> */}

                      <Menu
                        id="long-menu"
                        anchorEl={surveyAnchor}
                        open={board_id === activeId}
                        onClose={e => {
                          setSurveyAnchor(null);
                          setActiveId('');
                        }}
                        onClick={e => {
                          e.stopPropagation();
                        }}
                      >
                        {public_access === 'public' && (
                          <MenuItem
                            onClick={() => {
                              copyBoardLink(board_link);
                              setSurveyAnchor(null);
                              setActiveId('');
                            }}
                          >
                            <Typography variant="subtitle1">Copy Link</Typography>
                          </MenuItem>
                        )}

                        <MenuItem onClick={() => toggleConfirmModal(true)}>
                          <Typography variant="subtitle1">Delete</Typography>
                        </MenuItem>
                      </Menu>
                    </div>
                  </Box>
                  <Box height={'80px'}>
                    <Typography className={classes.dashboardTitle} pt={1.25}>
                      {name}
                    </Typography>
                    <Typography variant={'subtitle1'} pt={0.25} className={classes.dashboardDescription}>
                      {description}
                    </Typography>
                  </Box>

                  <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} mt={1}>
                    <Typography variant={'subtitle2'} color="text.secondary">
                      Created {modifiedDate(new Date(created_at).toString(), '', true)}
                    </Typography>
                    {created_by && (
                      <Typography variant={'subtitle2'} color="text.secondary">
                        Created by {created_by}
                      </Typography>
                    )}
                  </Box>
                </Paper>
              </Grid>
            ))}
          </Grid>
        )}
      </div>
    </div>
  );
}
