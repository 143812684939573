export const demoSurvey = {
  platform: 'android',
  user_segment: 'all',
  trigger_with_events: [],
  triggers: [],
  survey_end_date: '',
  survey_daily_limit: 0,
  survey_response_limit: 0,
  is_continuous: false,
  survey_retake_days: 2,
  show_thanks_card: true,
  show_intro: false,
  is_published: false,
  survey_repeat_days: 0,
  survey_repeat_frequency: 'show_once',
  default_language: 'en',
  thanks_card_title: 'Thank you for your help',
  thanks_card_desc: 'This will help us improve your experience',
  intro_card_title: 'Hey, got a minute for a few questions that can improve your  experience?',
  intro_card_cta: 'Let’s Go',
  status: 'draft',
  survey_id: '1',
  survey_name: 'preview survey',
  created_at: '',
  user_cohort: '',
  condition: [],
  attribute_values: {},
  languages: [],
  activeLanguage: 'en',
  trigger_delay: 0,
  is_dismissible: true,
  allow_anonymous: true,
  background_image: '',
  research_type: 'survey',
  is_backend_trigger: false,
  backend_triggers: [],
  user_cohort_condition: 'in_list',
  survey_views_limit: 1,
  survey_repeat_loop: 1,
  enable_advanced_resurvey_options: false,
  enable_advanced_survey_views: false,
  all_question_logo: '',
  trigger_variant: 'default',
  trigger_sequence: [],
  survey_redirect_link: '',
  survey_prompt: {
    objective: '',
    hypothesis: '',
    question_count: 3,
    prompt_type: 'generative',
  },
  takeaways: [],
  results_page: [],
  questions: [
    {
      properties: {
        randomize: 'in_set',
        labels: { left: '', right: '' },
        options: [
          { _id: '1', option_text: 'Feature #1', order: 1, has_text_input: false },
          { _id: '2', option_text: 'Feature #2', order: 2, has_text_input: false },
          { _id: '3', option_text: 'Feature #3', order: 3, has_text_input: false },
        ],
      },
      question_text: 'Which of these features would be most valuable to you?',
      question_desc: 'This will help us improve your experience',
      question_order: 1,
      cta_link: '',
      cta_text: 'SUBMIT',
      survey_id: '1',
      workspace_id: 'w-1',
      question_type: 'single_select_feedback',
      question_id: 'q-1',
      question_img: '',
      question_variant: '',
      rule_sets: [],
      is_mandatory: false,
      prototype_link: '',
      placeholder: '',
      validations: {},
      max_selections: -1,
      quiz_explanation: '',
      show_as_dropdown: false,
      default_rule_skip_to: 2,
    },
    {
      properties: { randomize: 'in_set', options: [], labels: { left: '', right: '' } },
      question_text: 'What is your biggest challenge when it comes to premium features?',
      question_desc: 'This will help us improve your experience',
      question_order: 2,
      cta_link: '',
      cta_text: 'Submit',
      survey_id: '1',
      workspace_id: 'w-1',
      question_id: 'q-2',
      question_type: 'input_feedback',
      question_img: '',
      question_variant: '',
      rule_sets: [],
      is_mandatory: false,
      prototype_link: '',
      placeholder: 'Type something here..',
      validations: {},
      max_selections: -1,
      quiz_explanation: '',
      show_as_dropdown: false,
      default_rule_skip_to: 3,
    },
    {
      properties: {
        randomize: 'in_set',
        options: [
          { _id: '1', option_text: 'Difficult to use', order: 1, has_text_input: false },
          { _id: '2', option_text: 'Not useful to me', order: 2, has_text_input: false },
          { _id: '3', option_text: 'Lacks features/functionality', order: 3, has_text_input: false },
        ],
        labels: { left: '', right: '' },
      },
      question_text: 'What is the main reason why you canceled your premium plan?',
      question_desc: 'This will help us improve your experience',
      question_order: 3,
      cta_link: '',
      cta_text: 'Submit',
      survey_id: '1',
      workspace_id: 'w-1',
      question_id: 'q-3',
      question_type: 'multi_select_feedback',
      question_img: '',
      question_variant: '',
      rule_sets: [],
      is_mandatory: false,
      prototype_link: '',
      placeholder: '',
      validations: {},
      max_selections: -1,
      quiz_explanation: '',
      show_as_dropdown: false,
      default_rule_skip_to: 4,
    },
    {
      properties: {
        labels: { left: 'Not likely', right: 'Extremely likely' },
        randomize: 'in_set',
        options: [],
      },
      question_text: 'How important to you is the premium features?',
      question_desc: 'This will help us improve your experience',
      question_order: 4,
      cta_link: '',
      cta_text: 'Submit',
      survey_id: '1',
      workspace_id: 'w-1',
      question_id: 'q-4',
      question_type: 'nps_feedback',
      question_img: '',
      question_variant: '',
      rule_sets: [],
      is_mandatory: false,
      prototype_link: '',
      placeholder: '',
      validations: {},
      max_selections: -1,
      quiz_explanation: '',
      show_as_dropdown: false,
      default_rule_skip_to: 5,
    },
    {
      properties: {
        randomize: 'in_set',
        options: [],
        labels: { left: '', right: '' },
      },
      question_text: 'What is the main reason why you canceled your premium plan?',
      question_desc: 'This will help us improve your experience',
      question_order: 5,
      cta_link: '',
      cta_text: '',
      survey_id: '1',
      workspace_id: 'w-1',
      question_id: 'q-5',
      question_type: 'star_feedback',
      question_img: '',
      question_variant: '',
      rule_sets: [],
      is_mandatory: false,
      prototype_link: '',
      placeholder: '',
      validations: {},
      max_selections: -1,
      quiz_explanation: '',
      show_as_dropdown: false,
      default_rule_skip_to: 6,
    },
    {
      properties: { randomize: 'in_set', options: [], labels: { left: '', right: '' } },
      question_text: 'What is your biggest challenge when it comes to premium features?',
      question_desc: 'This will help us improve your experience',
      question_order: 6,
      cta_link: '',
      cta_text: 'Submit',
      survey_id: '1',
      workspace_id: 'w-1',
      question_id: 'q-6',
      question_type: 'data_collection',
      question_img: '',
      question_variant: '',
      rule_sets: [],
      is_mandatory: false,
      prototype_link: '',
      placeholder: 'Type something here..',
      validations: {
        field_type: 'string',
        min_length: 1,
        max_length: 255,
      },
      max_selections: -1,
      quiz_explanation: '',
      show_as_dropdown: false,
      default_rule_skip_to: -1,
    },
  ],
};
