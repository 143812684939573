import { postCall, putCall, getCall } from '../apiCall';
import {
  CREATE_SURVEY,
  CREATE_SURVEY_TEMPLATE,
  FETCH_SURVEY_COLLECTIONS,
  FETCH_TEMPLATES_BY_TAG,
  FETCH_SURVEY_BY_ID,
  EDIT_SURVEY,
  FETCH_SURVEY_LIST,
  FETCH_SURVEY_FORM,
  FETCH_SURVEY_DETAILS,
  EDIT_SURVEY_STATUS,
  SYNC_OTHER_LANG_SURVEYS,
  EDIT_SURVEY_SETTINGS,
  FETCH_TEMPLATES_BY_ID,
  FETCH_SURVEY_RESPONSE_GROUPS,
  FETCH_SURVEY_GRAPH_BY_QUESTION_ID,
  MIGRATE_SURVEY,
  FETCH_QUICK_CONTINUOUS_TEMPLATES,
  FETCH_SURVEY_VIEWS,
  CREATE_SURVEY_FROM_PROMPT,
  FETCH_SURVEY_SEQUENCE,
  EDIT_SURVEY_SEQUENCE,
  FETCH_COHORT_SURVEY_COUNT,
  CREATE_FOLDER,
  FETCH_ALL_FOLDERS_IN_WORKSPACE,
  UPDATE_FOLDER,
  ADD_SURVEY_TO_FOLDER,
  FETCH_SURVEY_LIST_IN_FOLDER,
  REMOVE_SURVEY_FROM_FOLDER,
  DELETE_FOLDER,
  FETCH_CHAT_HISTORY,
  ADD_NEW_CHAT_ITEM,
} from './apiEndPoints';
import { ICollection, IContinuousTemplate, ITemplateWithCategory, ITemplateWithId } from '../types/template';
import {
  AllResponses,
  IPerformanceCondition,
  ISurvey,
  ISurveyDetails,
  ISurveyItem,
  ISurveySequence,
  IFolder,
  ISurveyChat,
} from '../types/survey';

export const createSurvey = async (payload: any) => {
  return new Promise<any>((resolve, reject) => {
    try {
      postCall(CREATE_SURVEY(), payload)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          if (err && err.data && err.data.error) {
            reject(err.data.error);
          } else {
            reject('Error, try again later! ');
          }
        });
    } catch (error) {
      reject('SYSTEM_ERROR');
    }
  });
};

export const createSurveyFromPrompt = async (payload: any, id: string) => {
  return new Promise<any>((resolve, reject) => {
    try {
      postCall(CREATE_SURVEY_FROM_PROMPT(id), payload)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          if (err && err.data && err.data.error) {
            reject(err.data.error);
          } else {
            reject('Error, try again later! ');
          }
        });
    } catch (error) {
      reject('SYSTEM_ERROR');
    }
  });
};

export const createSurveyTemplate = async (payload: any, id: string) => {
  return new Promise<any>((resolve, reject) => {
    try {
      postCall(CREATE_SURVEY_TEMPLATE(id), payload)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          if (err && err.data && err.data.error) {
            reject(err.data.error);
          } else {
            reject('Error, try again later! ');
          }
        });
    } catch (error) {
      reject('SYSTEM_ERROR');
    }
  });
};

export const fetchSurveyCollections = async () => {
  return new Promise<Array<ICollection>>((resolve, reject) => {
    try {
      getCall(FETCH_SURVEY_COLLECTIONS())
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          if (err && err.data && err.data.error) {
            reject(err.data.error);
          } else {
            reject('Error try again, later! ');
          }
        });
    } catch (error) {
      reject('SYSTEM_ERROR');
    }
  });
};

export const fetchSurveyTemplates = async (category_tag: string) => {
  return new Promise<ITemplateWithCategory>((resolve, reject) => {
    try {
      getCall(FETCH_TEMPLATES_BY_TAG(category_tag))
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          if (err && err.data && err.data.error) {
            reject(err.data.error);
          } else {
            reject('Error try again, later! ');
          }
        });
    } catch (error) {
      reject('SYSTEM_ERROR');
    }
  });
};

export const fetchCategoryTemplateById = async (category_tag: string, template_id: string) => {
  return new Promise<ITemplateWithId>((resolve, reject) => {
    try {
      getCall(FETCH_TEMPLATES_BY_ID(category_tag, template_id))
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          if (err && err.data && err.data.error) {
            reject(err.data.error);
          } else {
            reject('Error try again, later! ');
          }
        });
    } catch (error) {
      reject('SYSTEM_ERROR');
    }
  });
};

export const fetchSurveyById = async (survey_id: string, language_code: string) => {
  return new Promise<ISurvey>((resolve, reject) => {
    try {
      getCall(FETCH_SURVEY_BY_ID(survey_id, language_code))
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          if (err && err.data && err.data.error) {
            reject(err.data.error);
          } else {
            reject('Error try again, later! ');
          }
        });
    } catch (error) {
      reject('SYSTEM_ERROR');
    }
  });
};

export const fetchAllSurveys = async (page_count: number, status: string, platform: string, search_str: string) => {
  return new Promise<Array<ISurveyItem>>((resolve, reject) => {
    try {
      getCall(FETCH_SURVEY_LIST(page_count, status, platform, search_str))
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          if (err && err.data && err.data.error) {
            reject(err.data.error);
          } else {
            reject('Error try again, later! ');
          }
        });
    } catch (error) {
      reject('SYSTEM_ERROR');
    }
  });
};

export const fetchSurveyDetails = async (survey_id: string, payload: any) => {
  return new Promise<ISurveyDetails>((resolve, reject) => {
    try {
      postCall(FETCH_SURVEY_DETAILS(survey_id), payload)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          if (err && err.data && err.data.error) {
            reject(err.data.error);
          } else {
            reject('Error try again, later! ');
          }
        });
    } catch (error) {
      reject('SYSTEM_ERROR');
    }
  });
};

export const fetchSurveyViews = async (survey_id: string, payload) => {
  return new Promise<Array<any>>((resolve, reject) => {
    try {
      postCall(FETCH_SURVEY_VIEWS(survey_id), payload)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          if (err && err.data && err.data.error) {
            reject(err.data.error);
          } else {
            reject('Error try again, later! ');
          }
        });
    } catch (error) {
      reject('SYSTEM_ERROR');
    }
  });
};

export const fetchSurveyResponseGroups = async (survey_id: string, payload: any) => {
  return new Promise<Array<Array<AllResponses>>>((resolve, reject) => {
    try {
      postCall(FETCH_SURVEY_RESPONSE_GROUPS(survey_id), payload)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          if (err && err.data && err.data.error) {
            reject(err.data.error);
          } else {
            reject('Error try again, later! ');
          }
        });
    } catch (error) {
      reject('SYSTEM_ERROR');
    }
  });
};

export const editSurvey = async (payload: any, id: string, language_code: string) => {
  return new Promise<any>((resolve, reject) => {
    try {
      putCall(EDIT_SURVEY(id, language_code), payload)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          if (err && err.data && err.data.error) {
            reject(err.data.error);
          } else {
            reject('Error, try again later! ');
          }
        });
    } catch (error) {
      reject('SYSTEM_ERROR');
    }
  });
};

export const editSurveySettings = async (payload: any, id: string) => {
  return new Promise<any>((resolve, reject) => {
    try {
      putCall(EDIT_SURVEY_SETTINGS(id), payload)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          if (err && err.data && err.data.error) {
            reject(err.data.error);
          } else {
            reject('Error, try again later! ');
          }
        });
    } catch (error) {
      reject('SYSTEM_ERROR');
    }
  });
};

export const editSurveyStatus = async (survey_id: string, status: string) => {
  return new Promise<any>((resolve, reject) => {
    try {
      putCall(EDIT_SURVEY_STATUS(survey_id, status), {})
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          if (err && err.data && err.data.error) {
            reject(err.data.error);
          } else {
            reject('Error, try again later! ');
          }
        });
    } catch (error) {
      reject('SYSTEM_ERROR');
    }
  });
};

export const fetchSurveyForm = async (id: string) => {
  return new Promise<Array<any>>((resolve, reject) => {
    try {
      getCall(FETCH_SURVEY_FORM(id))
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          if (err && err.data && err.data.error) {
            reject(err.data.error);
          } else {
            reject('Error try again, later! ');
          }
        });
    } catch (error) {
      reject('SYSTEM_ERROR');
    }
  });
};

export const syncOtherLanguageSurveys = async (payload: any, id: string, language_code: string) => {
  return new Promise<any>((resolve, reject) => {
    try {
      postCall(SYNC_OTHER_LANG_SURVEYS(id, language_code), payload)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          if (err && err.data && err.data.error) {
            reject(err.data.error);
          } else {
            reject('Error, try again later! ');
          }
        });
    } catch (error) {
      reject('SYSTEM_ERROR');
    }
  });
};

export const fetchGraphByQuestionId = async (payload: any, survey_id: string, question_id: string) => {
  return new Promise<any>((resolve, reject) => {
    try {
      postCall(FETCH_SURVEY_GRAPH_BY_QUESTION_ID(survey_id, question_id), payload)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          if (err && err.data && err.data.error) {
            reject(err.data.error);
          } else {
            reject('Error, try again later! ');
          }
        });
    } catch (error) {
      reject('SYSTEM_ERROR');
    }
  });
};

export const migrateSurvey = async (survey_id: string, workspace_id: string) => {
  return new Promise<any>((resolve, reject) => {
    try {
      putCall(MIGRATE_SURVEY(survey_id, workspace_id), {})
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          if (err && err.data && err.data.error) {
            reject(err.data.error);
          } else {
            reject('Error, try again later! ');
          }
        });
    } catch (error) {
      reject('SYSTEM_ERROR');
    }
  });
};

export const fetchContinuousTemplates = async () => {
  return new Promise<IContinuousTemplate>((resolve, reject) => {
    try {
      getCall(FETCH_QUICK_CONTINUOUS_TEMPLATES())
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          if (err && err.data && err.data.error) {
            reject(err.data.error);
          } else {
            reject('Error try again, later! ');
          }
        });
    } catch (error) {
      reject('SYSTEM_ERROR');
    }
  });
};

export const fetchSurveySequence = async (survey_id: string, platform: string, trigger_name: string) => {
  return new Promise<Array<ISurveySequence>>((resolve, reject) => {
    try {
      getCall(FETCH_SURVEY_SEQUENCE(survey_id, platform, trigger_name))
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          if (err && err.data && err.data.error) {
            reject(err.data.error);
          } else {
            reject('Error try again, later! ');
          }
        });
    } catch (error) {
      reject('SYSTEM_ERROR');
    }
  });
};

export const updateSurveySequence = async (payload: any, survey_id: string) => {
  return new Promise<any>((resolve, reject) => {
    try {
      putCall(EDIT_SURVEY_SEQUENCE(survey_id), payload)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          if (err && err.data && err.data.error) {
            reject(err.data.error);
          } else {
            reject('Error try again, later! ');
          }
        });
    } catch (error) {
      reject('SYSTEM_ERROR');
    }
  });
};

export const getSurveyCohortCount = async (payload: any) => {
  return new Promise<any>((resolve, reject) => {
    try {
      postCall(FETCH_COHORT_SURVEY_COUNT(), payload)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          if (err && err.data && err.data.error) {
            reject(err.data.error);
          } else {
            reject('Error try again, later! ');
          }
        });
    } catch (error) {
      reject('SYSTEM_ERROR');
    }
  });
};

export const createNewFolder = async (payload: any) => {
  return new Promise<any>((resolve, reject) => {
    try {
      postCall(CREATE_FOLDER(), payload)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          if (err && err.data && err.data.error) {
            reject(err.data.error);
          } else {
            reject('Error, try again later! ');
          }
        });
    } catch (error) {
      reject('SYSTEM_ERROR');
    }
  });
};

export const deleteFolder = async (folder_id: string, payload: any) => {
  return new Promise<any>((resolve, reject) => {
    try {
      postCall(DELETE_FOLDER(folder_id), payload)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          if (err && err.data && err.data.error) {
            reject(err.data.error);
          } else {
            reject('Error, try again later! ');
          }
        });
    } catch (error) {
      reject('SYSTEM_ERROR');
    }
  });
};

export const updateFolderName = async (folder_id: string, payload: any) => {
  return new Promise<any>((resolve, reject) => {
    try {
      postCall(UPDATE_FOLDER(folder_id), payload)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          if (err && err.data && err.data.error) {
            reject(err.data.error);
          } else {
            reject('Error, try again later! ');
          }
        });
    } catch (error) {
      reject('SYSTEM_ERROR');
    }
  });
};

export const addSurveyToFolder = async (folder_id: string, payload: any) => {
  return new Promise<any>((resolve, reject) => {
    try {
      postCall(ADD_SURVEY_TO_FOLDER(folder_id), payload)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          if (err && err.data && err.data.error) {
            reject(err.data.error);
          } else {
            reject('Error, try again later! ');
          }
        });
    } catch (error) {
      reject('SYSTEM_ERROR');
    }
  });
};

export const removeSurveyFromFolder = async (folder_id: string, payload: any) => {
  return new Promise<any>((resolve, reject) => {
    try {
      postCall(REMOVE_SURVEY_FROM_FOLDER(folder_id), payload)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          if (err && err.data && err.data.error) {
            reject(err.data.error);
          } else {
            reject('Error, try again later! ');
          }
        });
    } catch (error) {
      reject('SYSTEM_ERROR');
    }
  });
};

export const getAllFolders = async () => {
  return new Promise<Array<IFolder>>((resolve, reject) => {
    try {
      getCall(FETCH_ALL_FOLDERS_IN_WORKSPACE())
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          if (err && err.data && err.data.error) {
            reject(err.data.error);
          } else {
            reject('Error try again, later! ');
          }
        });
    } catch (error) {
      reject('SYSTEM_ERROR');
    }
  });
};

export const fetchAllSurveysInFolder = async (
  folder_id: string,
  page_count: number,
  status: string,
  platform: string,
  search_str: string,
) => {
  return new Promise<{ surveys: Array<ISurveyItem>; folder_name: string }>((resolve, reject) => {
    try {
      getCall(FETCH_SURVEY_LIST_IN_FOLDER(folder_id, page_count, status, platform, search_str))
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          if (err && err.data && err.data.error) {
            reject(err.data.error);
          } else {
            reject('Error try again, later! ');
          }
        });
    } catch (error) {
      reject('SYSTEM_ERROR');
    }
  });
};

export const getChatHistory = async (survey_id: string) => {
  return new Promise<Array<ISurveyChat>>((resolve, reject) => {
    try {
      getCall(FETCH_CHAT_HISTORY(survey_id))
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          if (err && err.data && err.data.error) {
            reject(err.data.error);
          } else {
            reject('Error try again, later! ');
          }
        });
    } catch (error) {
      reject('SYSTEM_ERROR');
    }
  });
};

export const addChatData = async (survey_id: string, payload: any) => {
  return new Promise<any>((resolve, reject) => {
    try {
      postCall(ADD_NEW_CHAT_ITEM(survey_id), payload)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          if (err && err.data && err.data.error) {
            reject(err.data.error);
          } else {
            reject('Error, try again later! ');
          }
        });
    } catch (error) {
      reject('SYSTEM_ERROR');
    }
  });
};
