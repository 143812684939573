import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Box, Typography } from '@mui/material';
import defaultStyles from './loginStyles';
import { ReactComponent as CompanyLogoImg } from '../../images/login/company-logo.svg';
import CompanyGroupImg from '../../images/login/company-group.jpg';

const useStyles = makeStyles(theme => ({
  opinionText: {
    fontSize: '2rem',
    lineHeight: '40px',
  },
}));

export default function LoginTestimonial() {
  const classes = useStyles();
  const common = defaultStyles();
  return (
    <div className={common.testimonialContainer}>
      <a href="/">
        <CompanyLogoImg />
      </a>
      <div>
        <Typography className={classes.opinionText} mt={'13%'} maxWidth={'60%'}>
          Powering product research in the fastest-growing companies
        </Typography>
        <Box mt={4}>
          <img src={CompanyGroupImg} alt="Company Group" />
        </Box>
      </div>

      <Typography variant="subtitle1" color="text.secondary" mt={'auto'}>
        ©{new Date().getFullYear()} Blitzllama Labs Private Limited, Delaware, USA
      </Typography>
    </div>
  );
}
