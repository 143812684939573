import React, { useState, FunctionComponent, useRef } from 'react';
import { Button, Typography, Box, Menu, MenuItem } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useStyles } from '../EditorStyle';

import DeleteQuestionModal from '../DeleteQuestionModal';
import { questionTypes } from '../../../static/questionRule';
import { ISurvey } from '../../../types/survey';
import { addBulkSurveyQuestions, deleteSurveyQuestion } from '../../../services/surveyQuestionService';
import { questionDefault } from '../../../utils/questionUtil';
import AlertUtil from '../../../utils/AlertUtil';

//will have to be added with question logic

import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd';
import QuestionCard from './QuestionCard';

type QuestionProps = {
  survey: ISurvey;
  survey_id: string;
  updateChanges: (survey: ISurvey) => void;
  fetchSurvey: () => void;
  editQuestionHandler: (fieldName: string, fieldValue: string, q_id: string) => void;
  editMandatoryQuestionHandler: (fieldName: string, fieldValue: boolean, q_id: string) => void;
  editQuestionChoice: (q_id: string, action: string, idx: number, option?: string) => void;
  can_edit: boolean;
  can_save_changes: boolean;
  activeLanguage: string;
  updateDraftChanges: (show_alert: boolean, check_publish: boolean) => void;
};

const QuestionComponent: FunctionComponent<QuestionProps> = ({
  survey,
  updateChanges,
  fetchSurvey,
  editQuestionHandler,
  editMandatoryQuestionHandler,
  editQuestionChoice,
  can_edit,
  can_save_changes,
  activeLanguage,
  updateDraftChanges,
}) => {
  const classes = useStyles();

  const [questionTypeAnchor, setQuestionTypeAnchor] = React.useState<null | HTMLElement>(null);
  const [questionEditAnchor, setQuestionEditAnchor] = React.useState<null | HTMLElement>(null);

  //maintain refernce of all input File field
  const inputFileRefArray = useRef<Array<HTMLInputElement>>([]);
  const [activeId, setActiveId] = useState('');
  const [questImgId, setQuestImgId] = useState('');

  const [openDeleteWarningModal, toggleDeleteWarningModal] = useState(false);

  const handleQuestionTypeAnchor = (event: React.MouseEvent<HTMLElement>) => {
    setQuestionTypeAnchor(event.currentTarget);
  };

  const handleCloseQuestionTypeMenu = () => {
    setQuestionTypeAnchor(null);
  };

  const handleDragEnd = async (result: DropResult) => {
    if (!result.destination || !can_edit || activeLanguage !== 'en') return;

    const { questions } = survey;
    const reorderedQuestions = Array.from(questions);
    const [movedQuestion] = reorderedQuestions.splice(result.source.index, 1);
    reorderedQuestions.splice(result.destination.index, 0, movedQuestion);
    reorderedQuestions.forEach((question, index) => {
      question.question_order = index + 1;
      question.rule_sets.forEach(rule => {
        if (rule.skip_to > reorderedQuestions.length) {
          rule.skip_to = -1;
        }
      });
    });

    await updateDraftChanges(false, false);
    updateChanges({ ...survey, questions: reorderedQuestions });
    setTimeout(() => {
      fetchSurvey();
    }, 500);
  };

  const addQuestionItem = async (question_type: string, question_order: number) => {
    if (can_edit) {
      const quest = questionDefault(question_type, question_order);
      const { questions } = survey;
      if (questions.length >= 0 && questions.length < 50) {
        await updateDraftChanges(false, false);
        questions.push(quest);
        updateChanges({ ...survey, questions });
        await addBulkSurveyQuestions({ questions: [quest] }, survey.survey_id);
        setTimeout(() => {
          fetchSurvey();
        }, 500);
      } else {
        AlertUtil.fire({
          icon: 'error',
          title: 'More than 50 questions cannot be added',
        });
      }
    }
    handleCloseQuestionTypeMenu();
  };

  const deleteQuestionItem = async () => {
    if (can_edit) {
      await updateDraftChanges(false, false);
      let { questions } = survey;

      if (activeId.length > 5) {
        const deleteQuestion = await deleteSurveyQuestion({}, activeId);

        if (!deleteQuestion || !deleteQuestion.message) {
          return;
        }

        if (deleteQuestion && deleteQuestion.message) {
          AlertUtil.fire({
            icon: 'success',
            title: 'Deleted successfully',
          });
        }
      }

      questions = questions.filter(q => q.question_id !== activeId);
      for (let i = 0; i < questions.length; i++) {
        questions[i].question_order = i + 1;
        const { rule_sets } = questions[i];
        for (let j = 0; j < rule_sets.length; j++) {
          const { skip_to } = rule_sets[j];
          if (skip_to > questions.length) {
            rule_sets[j].skip_to = -1;
          }
        }
      }
      updateChanges({ ...survey, questions });
      setTimeout(() => {
        fetchSurvey();
      }, 500);
    }
    setQuestionEditAnchor(null);
  };

  const moveUpQuestionItem = async () => {
    const { questions } = survey;
    if (activeId && can_edit) {
      for (let i = 0; i < questions.length; i++) {
        const { question_id, question_order } = questions[i];
        if (activeId === question_id && question_order !== 1) {
          questions[i].question_order = question_order - 1;
          questions[+i - 1].question_order = question_order;

          questions.sort((a, b) => a.question_order - b.question_order);
          updateChanges({ ...survey, questions });
        }
      }
    }

    setQuestionEditAnchor(null);
  };

  const moveDownQuestionItem = async () => {
    const { questions } = survey;
    if (activeId && can_edit) {
      for (let i = 0; i < questions.length; i++) {
        const { question_id, question_order } = questions[i];
        if (activeId === question_id && question_order !== questions.length) {
          if (questions[+i + 1]) {
            questions[i].question_order = question_order + 1;
            questions[+i + 1].question_order = question_order;

            questions.sort((a, b) => a.question_order - b.question_order);
            updateChanges({ ...survey, questions });
          }
          break;
        }
      }
    }

    setQuestionEditAnchor(null);
  };

  const handleDeleteQuestion = () => {
    toggleDeleteWarningModal(true);
  };

  const { questions, research_type, platform, status } = survey;

  const setDefaultRuleSkipTo = (question_id: string) => {
    const question_item = questions.find(q => q.question_id === question_id);
    if (question_item && question_item.default_rule_skip_to) {
      return question_item.default_rule_skip_to;
    }

    if (question_item) {
      const { question_order } = question_item;
      if (question_order === questions.length) {
        return -1;
      } else if (question_order + 1 >= questions.length) {
        return question_order + 1;
      }
    }

    return '';
  };

  return (
    <>
      {openDeleteWarningModal && (
        <DeleteQuestionModal
          open={openDeleteWarningModal}
          toggleModal={toggleDeleteWarningModal}
          deleteQuestionItem={deleteQuestionItem}
          setActiveId={setActiveId}
          setQuestionEditAnchor={setQuestionEditAnchor}
          surveyStatus={status}
        />
      )}
      {questions && questions.length > 0 ? (
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="questions">
            {provided => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {questions.map(
                  (
                    {
                      question_type,
                      question_desc,
                      question_order,
                      question_img,
                      question_variant,
                      question_text,
                      cta_link,
                      cta_text,
                      question_id,
                      properties,
                      editable,
                      rule_sets,
                      is_mandatory,
                      prototype_link,
                      placeholder,
                      validations,
                      max_selections,
                      show_as_dropdown,
                    },
                    index,
                  ) => (
                    <Draggable
                      key={question_id}
                      draggableId={question_id}
                      index={index}
                      isDragDisabled={!can_edit || activeLanguage !== 'en'}
                    >
                      {(provided, snapshot) => (
                        <div ref={provided.innerRef} {...provided.draggableProps}>
                          <QuestionCard
                            provided={provided}
                            snapshot={snapshot}
                            can_edit={can_edit}
                            activeLanguage={activeLanguage}
                            question_order={question_order}
                            question_type={question_type}
                            question_desc={question_desc}
                            question_variant={question_variant}
                            question_text={question_text}
                            cta_link={cta_link}
                            cta_text={cta_text}
                            editable={editable}
                            properties={properties}
                            rule_sets={rule_sets}
                            default_rule_skip_to={setDefaultRuleSkipTo(question_id)}
                            is_mandatory={is_mandatory}
                            prototype_link={prototype_link}
                            placeholder={placeholder}
                            validations={validations}
                            max_selections={max_selections}
                            show_as_dropdown={show_as_dropdown}
                            updateDraftChanges={updateDraftChanges}
                            fetchSurvey={fetchSurvey}
                            updateChanges={updateChanges}
                            survey={survey}
                            question_id={question_id}
                            questionEditAnchor={questionEditAnchor}
                            setQuestionEditAnchor={setQuestionEditAnchor}
                            setActiveId={setActiveId}
                            activeId={activeId}
                            moveUpQuestionItem={moveUpQuestionItem}
                            moveDownQuestionItem={moveDownQuestionItem}
                            handleDeleteQuestion={handleDeleteQuestion}
                            editQuestionHandler={editQuestionHandler}
                            editQuestionChoice={editQuestionChoice}
                            question_img={question_img}
                            index={index}
                            editMandatoryQuestionHandler={editMandatoryQuestionHandler}
                          />
                        </div>
                      )}
                    </Draggable>
                  ),
                )}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      ) : (
        <Box className={classes.questionPaper} display="flex" p={7} justifyContent="center" alignItems="center">
          <Typography variant="h4" color={'text.secondary'} style={{ marginLeft: 10 }}>
            No Questions Yet!
          </Typography>
        </Box>
      )}
      <Box my={5}>
        {activeLanguage === 'en' && can_save_changes && (
          <Button
            aria-controls="customized-menu"
            aria-haspopup="true"
            variant="contained"
            endIcon={<ExpandMoreIcon />}
            onClick={handleQuestionTypeAnchor}
          >
            + Add a new question
          </Button>
        )}
        <Menu
          className={classes.questionTypeMenu}
          anchorEl={questionTypeAnchor}
          keepMounted
          open={Boolean(questionTypeAnchor)}
          onClose={handleCloseQuestionTypeMenu}
        >
          {questionTypes.map((q, key) => (
            <MenuItem key={key} onClick={() => addQuestionItem(q.value, questions.length + 1)}>
              {q.label}
            </MenuItem>
          ))}
        </Menu>
      </Box>
    </>
  );
};

export default QuestionComponent;
