import React from 'react';

import { ReactComponent as NpsIcon } from '../images/editor/nps-icon.svg';
import { ReactComponent as SingleSelectIcon } from '../images/editor/single-select-icon.svg';
import { ReactComponent as MultiSelectIcon } from '../images/editor/multi-select-icon.svg';
import { ReactComponent as IntroPromptIcon } from '../images/editor/intro-prompt-icon.svg';
import { ReactComponent as ScaleIcon } from '../images/editor/scale-icon.svg';
import { ReactComponent as InputIcon } from '../images/editor/input-icon.svg';

export const singleSelectQuestionRules = [
  {
    label: 'Is exactly',
    value: 'in_list_exact',
  },
  {
    label: 'On Submit',
    value: 'submit',
  },
];

export const multiSelectQuestionRules = [
  {
    label: 'Is exactly',
    value: 'in_list_exact',
  },
  {
    label: 'Is one of',
    value: 'in_list_once',
  },
  {
    label: 'On Submit',
    value: 'submit',
  },
];

export const collectionQuestionRules = [
  {
    label: 'Is one of',
    value: 'in_list_once',
  },
  {
    label: 'On Submit',
    value: 'submit',
  },
];

export const scaleRules = [
  {
    label: '=',
    value: 'eq',
  },
  {
    label: '!=',
    value: 'neq',
  },
  {
    label: '<',
    value: 'lt',
  },
  {
    label: '<=',
    value: 'lte',
  },
  {
    label: '>',
    value: 'gt',
  },
  {
    label: '>=',
    value: 'gte',
  },
];

export const scaleQuestionsRules = [
  ...scaleRules,
  {
    label: 'On Submit',
    value: 'submit',
  },
];

export const inputQuestionRules = [
  {
    label: 'On Submit',
    value: 'submit',
  },
];

//scale rules (nps, scale)
//input feeback(themes) => multi select
export const cohortRules = {
  scaleRules,
  singleSelectRules: [
    {
      label: 'Is one of',
      value: 'in_list_once',
    },
  ],
  multiSelectRules: [
    {
      label: 'Is exactly',
      value: 'in_list_exact',
    },
    {
      label: 'Is one of',
      value: 'in_list_once',
    },
  ],
  introPromptRules: [
    {
      label: 'Clicked',
      value: 'clicked',
    },
  ],
  dataCollectionRules: [
    {
      label: 'Responded',
      value: 'responded',
    },
  ],
};

export const questionTypes = [
  {
    value: 'single_select_feedback',
    label: 'MCQ: Single Select',
    icon: <SingleSelectIcon width={25} />,
    color: '#5D76CC',
    bg_color: '#EFF0F9',
  },
  {
    value: 'multi_select_feedback',
    label: 'MCQ: Multi Select',
    icon: <MultiSelectIcon width={25} />,
    color: '#C97960',
    bg_color: '#F9EDE9',
  },
  {
    value: 'scale_feedback',
    label: 'Opinion Scale (1 to 5)',
    icon: <ScaleIcon width={25} />,
    color: '#67A89C',
    bg_color: '#E7F7EF',
  },
  {
    value: 'nps_feedback',
    label: 'NPS (0 to 10)',
    icon: <NpsIcon width={25} />,
    color: '#9B60C9',
    bg_color: '#F6EAFF',
  },
  {
    value: 'input_feedback',
    label: 'Text Input',
    icon: <InputIcon width={25} />,
    color: '#7067A8',
    bg_color: '#EBE8FD',
  },
  {
    value: 'data_collection',
    label: 'Data Collection',
    icon: <InputIcon width={25} />,
    color: '#6fb3a9',
    bg_color: '#e8fdfa',
  },
  {
    value: 'intro_prompt',
    label: 'Intro Prompt',
    icon: <IntroPromptIcon width={25} />,
    color: '#79A5C5',
    bg_color: '#ECF7FF',
  },
  {
    value: 'star_feedback',
    label: 'Star Rating',
    icon: <IntroPromptIcon width={25} />,
    color: '#EC9E28',
    bg_color: '#FFF4D9',
  },
  {
    value: 'emoji_feedback',
    label: 'Emoji Rating',
    icon: <IntroPromptIcon width={25} />,
    color: '#EC4E28',
    bg_color: '#FFD9D9',
  },
  {
    value: 'matrix_feedback',
    label: 'Matrix',
    icon: <IntroPromptIcon width={25} />,
    color: '#559EA4',
    bg_color: '#EFFCEA',
  },
];

export const quizQuestionTypes = [
  {
    value: 'single_select_feedback',
    label: 'MCQ: Single Select',
    icon: <SingleSelectIcon width={25} />,
    color: '#5D76CC',
    bg_color: '#EFF0F9',
  },
  {
    value: 'intro_prompt',
    label: 'Intro Prompt',
    icon: <IntroPromptIcon width={25} />,
    color: '#79A5C5',
    bg_color: '#ECF7FF',
  },
];

export const questionVariants = [
  {
    label: 'Default',
    value: '',
  },
  {
    label: 'Colourised',
    value: 'colourised',
  },
];

export const questionSelectVariants = [
  {
    label: 'Default',
    value: '',
  },
  {
    label: 'Dropdown',
    value: 'dropdown',
  },
];

export const validationDataFieldTypes = [
  {
    label: 'String',
    value: 'string',
  },
  {
    label: 'Number',
    value: 'number',
  },
  {
    label: 'Email',
    value: 'email',
  },
];
