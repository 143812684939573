import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  Grid,
  Button,
  Typography,
  Box,
  Menu,
  MenuItem,
  CircularProgress,
  Select,
  Popover,
  TextField,
  InputAdornment,
  Checkbox,
  Snackbar,
  SnackbarCloseReason,
  SnackbarContent,
} from '@mui/material';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CloseIcon from '@mui/icons-material/Close';
import FolderOpenRoundedIcon from '@mui/icons-material/FolderOpenRounded';

import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import folderCloseImg from '../../../images/survey/folder-close.svg';

import { homeStyles } from './HomeStyle';
import { statusData, surveyStatusFeature, platformData, launchResearchTypes } from '../../../static/staticData';
import { IWorkspace } from '../../../types/workspace';
import { IFolder, ISurveyItem } from '../../../types/survey';

import AlertUtil from '../../../utils/AlertUtil';
import { amplitudeEvent } from '../../../utils/amplitudeUtil';
import { getResponsePercent, modifiedDate } from '../../../utils/DateUtil';

import { fetchWorkspaceList } from '../../../services/workspaceService';
import {
  fetchAllSurveys,
  editSurveyStatus,
  migrateSurvey,
  createSurvey,
  createNewFolder,
  deleteFolder,
  getAllFolders,
  updateFolderName,
  addSurveyToFolder,
} from '../../../services/surveyService';
import { RootState, useAppDispatch } from '../../../store';
import { changeFilter, resetFilter } from '../../../slices/surveySlice';

import { ReactComponent as SearchIcon } from '../../../images/audience/search-icon.svg';
import integrateImg from '../../../images/survey/integrate-img.svg';
import buttonArrowImg from '../../../images/survey/button-arrow.svg';
import blog1Img from '../../../images/survey/blog1-img.svg';
import blog2Img from '../../../images/survey/blog2-img.svg';
import { getPlatformText, numberWithCommas } from '../../../utils/stringUtil';

const StartScreenComponent = () => {
  const classes = homeStyles();
  return (
    <Box mb={3.25}>
      <Box mt={2.2} mb={5} className={classes.documentBox}>
        <img src={integrateImg} alt="" />
        <div style={{ marginLeft: '1.5rem', width: '65%' }}>
          <Typography fontWeight={700}>Integrate Blitzllama into your apps or website</Typography>
          <Typography variant="subtitle1">
            You can choose to begin with an SDK integration by viewing our tech documentation here or launch a link
            survey or prototype test to start with
          </Typography>
        </div>

        <Button className={classes.viewDocumentButton}>
          <a href="https://documentation.blitzllama.com/" target="_blank" rel="noopener noreferrer">
            <Box display={'flex'} alignItems="center">
              <Typography variant="subtitle1" mr={0.5} color="primary.contrastText">
                View documentation
              </Typography>
              <img src={buttonArrowImg} alt="" style={{ width: '8px', height: '8px' }} />
            </Box>
          </a>
        </Button>
      </Box>
      <div>
        <Box display={'flex'} alignItems="center" justifyContent={'space-between'}>
          <Typography>
            <span role="img" aria-label="">
              ⚡️
            </span>
            A quick overview{' '}
          </Typography>
          {/* <a
            href="https://22998e.journey.io/p/fa643a2dda2048bb9553252868514e3e"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Typography variant="subtitle1" color="primary.contrastText">
              Watch more
            </Typography>
          </a> */}
        </Box>
        <Grid container mt={1.8}>
          <Grid item lg={6}>
            <div style={{ position: 'relative', paddingBottom: '62.5%', height: 0, marginRight: 10 }}>
              <iframe
                title="Book live demo"
                src="https://www.loom.com/embed/fec2945e79064f34895037e626d1aefb"
                frameBorder={0}
                style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
              ></iframe>
            </div>
          </Grid>
          <Grid item lg={6}>
            <div style={{ position: 'relative', paddingBottom: '62.5%', height: 0, marginLeft: 10 }}>
              <iframe
                style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
                src="https://www.youtube.com/embed/AGBikz5VzXk"
                frameBorder={0}
                title="Introducing Blitzllama - Product Research 2.0"
              ></iframe>
            </div>
          </Grid>
        </Grid>
      </div>

      <Box display={'flex'} alignItems="center" justifyContent={'space-between'} mt={8}>
        <Typography>
          <span role="img" aria-label="">
            💡
          </span>
          Some ideas for your first steps
        </Typography>
        <a href="https://blitzllama.com/blog" target="_blank" rel="noopener noreferrer">
          <Typography variant="subtitle1" color="primary.contrastText">
            Read more
          </Typography>
        </a>
      </Box>
      <Grid container mt={2} spacing={1.75}>
        <Grid item lg={6}>
          <a
            href="https://blitzllama.com/blog/supercharging-user-research-activities-using-blitzllama"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Box display={'flex'} alignItems="center">
              <img src={blog1Img} alt="" />
              <Box display={'flex'} flexDirection="column" ml={3}>
                <Typography fontWeight={700}>Supercharging user research using Blitzllama</Typography>
                <Typography variant="subtitle1" lineHeight={'18.28px'}>
                  How multiple teams are using Blitzllama to infuse customer inputs into their product decisions to hit
                  customer and business expectations more often.
                </Typography>
                <Box ml={'auto'}>
                  <Box display={'flex'} alignItems="center" ml={'auto'} style={{ cursor: 'pointer' }}>
                    <Typography variant="subtitle1" color="primary.contrastText" mr={0.5}>
                      Read blog
                    </Typography>
                    <img src={buttonArrowImg} alt="" style={{ width: '6px', height: '6px' }} />
                  </Box>
                </Box>
              </Box>
            </Box>
          </a>
        </Grid>
        <Grid item lg={6}>
          <a
            href="https://blitzllama.com/blog/how-do-high-growth-companies-like-figma-and-brex-use-in-product-feedback-loops-to-innovate-fast"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Box display={'flex'} alignItems="center">
              <img src={blog2Img} alt="" />
              <Box display={'flex'} flexDirection="column" ml={1.5} mr={1.5}>
                <Typography fontWeight={700}>How companies like Figma & Brex use feedback loops</Typography>
                <Typography variant="subtitle1" lineHeight={'18.28px'}>
                  Product teams in high-growth companies place feedback touchpoints inside product journeys to rapidly
                  identify product gaps and inform their product roadmaps.
                </Typography>
                <Box ml={'auto'}>
                  <Box display={'flex'} alignItems="center" style={{ cursor: 'pointer' }}>
                    <Typography variant="subtitle1" color="primary.contrastText" mr={0.5}>
                      Read blog
                    </Typography>
                    <img src={buttonArrowImg} alt="" style={{ width: '6px', height: '6px' }} />
                  </Box>
                </Box>
              </Box>
            </Box>
          </a>
        </Grid>
      </Grid>
    </Box>
  );
};

let timer: any = null;
const SurveyHomeComponent = () => {
  const classes = homeStyles();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const [surveys, setSurveyList] = useState<Array<ISurveyItem>>([]);
  const [activeId, setActiveId] = useState('');
  const [searchStr, setSearchStr] = useState('');
  const [isLoading, setLoading] = useState(true);
  const [projectList, setProjectList] = useState<Array<IWorkspace>>([]);
  const [folderList, setFolderList] = useState<Array<IFolder>>([]);

  const [pageCount, setPageCount] = useState(0);
  const [activeShowMore, setActiveShowMore] = useState(false);
  const [surveyCount, setSurveyCount] = useState<number>(0);
  const [hoverSurveyIds, setHoverSurveyId] = useState<Array<string>>([]);
  const [folderSurveyIds, setFolderSurveyId] = useState<Array<string>>([]);

  const [researchAnchor, setResearchAnchor] = React.useState<null | HTMLElement>(null);
  const [surveyAnchor, setSurveyAnchor] = React.useState<null | HTMLElement>(null);
  const [anchorPopover, setAnchorPopover] = React.useState<HTMLElement | null>(null);
  const [folderPopover, setFolderPopover] = React.useState<HTMLElement | null>(null);

  const [popoverId, setPopoverId] = useState('');
  const [activeFolderId, setActiveFolderId] = useState('');
  const [activeFolderName, setActiveFolderName] = useState('');
  const [anchorType, setAnchorType] = useState('');

  const [showSnackBar, setShowSnackBar] = useState(false);

  const user = useSelector((state: RootState) => state.login);
  const surveyFilter = useSelector((state: RootState) => state.surveyFilter);

  const [anchorSnackBarEl, setAnchorSnackBarEl] = React.useState<HTMLElement | null>(null);

  const handleSnackBarFolder = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorSnackBarEl(event.currentTarget);
  };

  const handleSnackBarClose = () => {
    setAnchorSnackBarEl(null);
    setFolderSurveyId([]);
  };

  const open = Boolean(anchorSnackBarEl);

  //tracking context
  const tracking_info = useSelector((state: RootState) => state.tracking);

  useEffect(() => {
    if (tracking_info) {
      amplitudeEvent(tracking_info, 'microsurvey tab viewed', {});
    }
  }, [tracking_info]);

  const handleResearchAnchor = (event: React.MouseEvent<HTMLElement>) => {
    setResearchAnchor(event.currentTarget);
  };

  const handleCloseResearchMenu = (event: React.MouseEvent<HTMLElement>) => {
    setResearchAnchor(null);
  };

  const fetchAllFolders = async () => {
    const folders = await getAllFolders();
    if (folders) {
      setFolderList(folders);
    }
  };

  const updateName = async () => {
    const results = await updateFolderName(activeFolderId, { folder_name: activeFolderName });
    if (results) {
      await fetchAllFolders();
      AlertUtil.fire({
        icon: 'success',
        title: 'Updated successfully',
      });
    }

    setActiveFolderId('');
    setActiveFolderName('');
  };

  const fetchSurveys = async (is_showmore = false) => {
    const { status, platform, search_str } = surveyFilter;
    let currPageCount = pageCount;

    if (is_showmore) {
      currPageCount = currPageCount + 1;
    } else {
      setLoading(true);
    }

    const surveyResult = await fetchAllSurveys(currPageCount, status, platform, search_str);
    if (surveyResult) {
      setSurveyList(surveyResult);

      if (surveyCount >= 0) {
        setSurveyCount(surveyResult.length);
      }

      if (surveyResult.length === 30) {
        setActiveShowMore(true);
      } else {
        setActiveShowMore(false);
      }

      if (!is_showmore) {
        setSurveyList(surveyResult);
      } else {
        if (surveys && surveyResult) {
          setSurveyList([...surveys, ...surveyResult]);
        } else {
          setSurveyList([]);
        }
      }
      setPageCount(currPageCount);
    }
    setLoading(false);
  };

  const addSurveyToFolderList = async (folder_id: string, survey_id: string) => {
    const results = await addSurveyToFolder(folder_id, { survey_id });
    if (results) {
      AlertUtil.fire({
        icon: 'success',
        title: 'Added successfully',
      });
      await fetchAllFolders();
      await fetchSurveys();
    }
  };

  const addBulkSurveyToFolderList = async (folder_id: string) => {
    for (const i in folderSurveyIds) {
      await addSurveyToFolder(folder_id, { survey_id: folderSurveyIds[i] });
    }

    setResearchAnchor(null);
    setAnchorSnackBarEl(null);
    setFolderSurveyId([]);

    AlertUtil.fire({
      icon: 'success',
      title: 'Added successfully',
    });
    await fetchAllFolders();
    await fetchSurveys();
  };

  useEffect(() => {
    fetchSurveys();
  }, [surveyFilter]);

  useEffect(() => {
    // if page navigation happens without navigation stack pop state action
    if (history.action === 'PUSH') {
      dispatch(resetFilter());
    }
    fetchAllFolders();
  }, []);

  // handle status and platform filter value
  const handleSurveyFilter = (filter_field: string, filter_value: string) => {
    setPageCount(0);
    dispatch(changeFilter({ ...surveyFilter, [filter_field]: filter_value }));
  };

  const handleSearchText = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setSearchStr(e.target.value);
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(() => handleSurveyFilter('search_str', e.target.value), 700);
  };

  const archiveSurveyItem = async () => {
    if (activeId) {
      const survey = await editSurveyStatus(activeId, 'archive');
      if (survey) {
        AlertUtil.fire({
          icon: 'success',
          title: 'Archived successfully',
        });
        fetchSurveys();
      }
    }
    setSurveyAnchor(null);
  };

  const duplicateSurvey = async () => {
    if (activeId) {
      const survey = await editSurveyStatus(activeId, 'duplicate');
      if (survey) {
        AlertUtil.fire({
          icon: 'success',
          title: 'Duplicated successfully',
        });
        fetchSurveys();
        dispatch(resetFilter());
      }
    }
    setSurveyAnchor(null);
  };

  const createSurveyDraft = async (research_type: string) => {
    const newDraft = await createSurvey({ research_type });
    if (newDraft) {
      if (newDraft && newDraft.survey_id) history.push(`/${research_type}/${newDraft.survey_id}/editor`);
    }
  };

  const handleProjectList = async () => {
    let projects = await fetchWorkspaceList();
    const active_workspace = localStorage.getItem('active_workspace');
    if (active_workspace) {
      projects = projects.filter(p => p.workspace_id !== active_workspace);
    }
    if (projects) setProjectList(projects);
  };

  const handleSurveyMigration = async (survey_id: string, workspace_id: string) => {
    const surveyItem = await migrateSurvey(survey_id, workspace_id);
    if (surveyItem) {
      AlertUtil.fire({
        icon: 'success',
        title: 'Copied survey successfully',
      });
    }
  };

  const handleResearchLaunch = (type_value: string) => {
    if (type_value === 'templates') {
      history.push(`/${type_value}`);
    } else {
      createSurveyDraft(type_value);
    }
  };

  const createFolder = async () => {
    const results = await createNewFolder({ folder_name: 'New Folder' });
    if (results && results.data) {
      setActiveFolderId(results.data);
      setActiveFolderName('New Folder');
    }

    await fetchAllFolders();
  };

  const deleteExistingFolder = async (folder_id: string) => {
    const results = await deleteFolder(folder_id, {});

    if (results) {
      await setTimeout(async () => {
        AlertUtil.fire({
          icon: 'success',
          title: 'Deleted successfully',
        });

        await fetchAllFolders();
        await fetchSurveys();
      }, 1000);
    }
  };

  const handleSnackBar = (event: React.SyntheticEvent | Event, reason?: SnackbarCloseReason) => {
    if (reason === 'clickaway') {
      return;
    }
    setShowSnackBar(false);
  };

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" mt="25%">
        <CircularProgress className={classes.loading} />
      </Box>
    );
  } else {
    const live_surveys = surveys.filter(s => s.status === 'live');
    const live_survey_count = live_surveys.length === 0 ? 1 : 0;
    let totalSurveyCount = folderList.reduce((acc, folder) => acc + folder.total_count, 0);
    totalSurveyCount += surveys.length;
    return (
      <div>
        <Snackbar
          open={showSnackBar || folderSurveyIds.length > 0}
          autoHideDuration={600000}
          onClose={handleSnackBar}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          style={{ width: '50%' }}
          sx={{ marginBottom: '50px', zIndex: 9999 }}
        >
          <SnackbarContent
            style={{
              backgroundColor: 'black',
              color: 'white',
              border: '1px solid #696969',
            }}
            message={
              <div>
                <Box style={{ cursor: 'pointer' }} display={'flex'} justifyContent={'space-between'}>
                  <Box
                    display={'flex'}
                    onClick={e => {
                      handleSnackBarFolder(e);
                    }}
                    pl={1}
                  >
                    <FolderOpenRoundedIcon style={{ marginTop: 2 }} />
                    <Typography style={{ color: 'white' }} ml={0.5}>
                      Move To Folder
                    </Typography>
                  </Box>
                  <Box alignSelf={'end'} ml={4}>
                    <CloseIcon
                      fontSize="small"
                      onClick={() => {
                        handleSnackBarClose();
                      }}
                      style={{ cursor: 'pointer' }}
                    />
                  </Box>
                </Box>
                <Popover
                  open={open}
                  anchorEl={anchorSnackBarEl}
                  onClose={handleSnackBarClose}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                  transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                  style={{ zIndex: 9999 }}
                >
                  <Box>
                    {folderList.map(({ folder_id, folder_name }, idx) => (
                      <MenuItem
                        key={idx}
                        onClick={() => {
                          addBulkSurveyToFolderList(folder_id);
                        }}
                      >
                        <Typography width={'18ch'} noWrap>
                          {folder_name}
                        </Typography>
                      </MenuItem>
                    ))}
                  </Box>
                </Popover>
              </div>
            }
          />
        </Snackbar>
        <Grid container lg={12} alignItems={'center'} justifyContent={'space-between'}>
          <Typography variant="h2">Your surveys & concept tests</Typography>
          {user?.w_role === 'editor' && (
            <div style={{ display: 'flex' }}>
              {user?.show_upgrade && (
                <Typography style={{ color: '#C82455' }} marginTop={1} marginRight={1} variant={'subtitle1'}>
                  {live_survey_count} free research left
                </Typography>
              )}

              <Button
                aria-controls="customized-menu"
                aria-haspopup="true"
                className={classes.newFolderButton}
                onClick={() => {
                  createFolder();
                }}
              >
                + New Folder
              </Button>

              <Button
                aria-controls="customized-menu"
                aria-haspopup="true"
                variant="contained"
                endIcon={<ExpandMoreIcon />}
                onClick={handleResearchAnchor}
              >
                + Launch research
              </Button>

              <Menu
                className={classes.researchMenu}
                anchorEl={researchAnchor}
                keepMounted
                open={Boolean(researchAnchor)}
                onClose={handleCloseResearchMenu}
              >
                {launchResearchTypes.map(({ title, type_value, icon }, idx) => (
                  <MenuItem key={idx} onClick={() => handleResearchLaunch(type_value)}>
                    <Box display={'flex'} alignItems={'center'}>
                      {icon}
                      <Typography variant="subtitle1" pl={1.5}>
                        {title}
                      </Typography>
                    </Box>
                  </MenuItem>
                ))}
              </Menu>
            </div>
          )}
        </Grid>

        <div>
          {totalSurveyCount > 0 && (
            <>
              <Box display="flex" alignItems="center" mt={5.25} mb={3.25}>
                <div className={classes.searchBox}>
                  <TextField
                    placeholder="Search survey name or creator"
                    className={classes.searchTextField}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                    value={searchStr}
                    onChange={e => {
                      handleSearchText(e);
                    }}
                  />
                </div>
                <Box ml={2}>
                  <Typography variant={'subtitle2'}>Status</Typography>
                  <Select
                    variant="outlined"
                    fullWidth
                    value={surveyFilter.status}
                    onChange={e => handleSurveyFilter('status', e.target.value)}
                    className={classes.filterSelect}
                  >
                    {statusData.map((option, key) => (
                      <MenuItem key={key} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </Box>

                <Box ml={2}>
                  <Typography variant={'subtitle2'}>Platform</Typography>
                  <Select
                    variant="outlined"
                    fullWidth
                    value={surveyFilter.platform}
                    onChange={e => handleSurveyFilter('platform', e.target.value)}
                    className={classes.filterSelect}
                  >
                    {platformData.map((option, key) => (
                      <MenuItem key={key} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </Box>
              </Box>

              <div>
                {totalSurveyCount === 0 ? (
                  <Box display="flex" alignItems={'center'} justifyContent="center" mt={2}>
                    <Typography variant={'body2'} color={'text.secondary'}>
                      No {surveyFilter.status === 'all' ? '' : surveyFilter.status}{' '}
                      {getPlatformText(surveyFilter.platform)} surveys to display
                    </Typography>
                  </Box>
                ) : (
                  <>
                    {folderList.length > 0 && <Typography mb={2}>Folders</Typography>}
                    <Grid container spacing={1.75}>
                      {folderList.map(({ folder_id, folder_name, total_count }, idx) => (
                        <Grid item lg={4} key={idx}>
                          <div className={classes.templatePaper} onClick={() => history.push(`/folder/${folder_id}/`)}>
                            <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                              <Box display={'flex'}>
                                <img src={folderCloseImg} style={{ marginRight: 8 }} />
                                <div>
                                  {folder_id === activeFolderId ? (
                                    <Box>
                                      <TextField
                                        placeholder="Enter Folder name"
                                        value={activeFolderName}
                                        className={classes.renameTextField}
                                        onClick={e => {
                                          e.stopPropagation();
                                        }}
                                        onChange={e => {
                                          setActiveFolderName(e.target.value);
                                        }}
                                        inputRef={input => input?.focus()}
                                        autoFocus
                                        focused
                                      />
                                      <Button
                                        aria-controls="customized-menu"
                                        aria-haspopup="true"
                                        variant="contained"
                                        onClick={e => {
                                          updateName();
                                          e.stopPropagation();
                                        }}
                                        style={{ height: 34, marginLeft: 5 }}
                                      >
                                        Save
                                      </Button>
                                    </Box>
                                  ) : (
                                    <Typography
                                      variant={'h4'}
                                      className={classes.folderNameText}
                                      style={{
                                        wordWrap: 'break-word',
                                        maxWidth: '30ch',
                                      }}
                                    >
                                      {folder_name}
                                    </Typography>
                                  )}

                                  <Typography variant="subtitle1" style={{ color: '#5e5e5e' }}>
                                    {total_count} Studies
                                  </Typography>
                                </div>
                              </Box>
                              {user?.w_role === 'editor' && (
                                <div style={{ marginLeft: 'auto' }}>
                                  <Box
                                    p={0.5}
                                    mr={-1.6}
                                    onClick={e => {
                                      e.stopPropagation();
                                      setSurveyAnchor(e.currentTarget);
                                      setActiveId(folder_id);
                                    }}
                                  >
                                    <MoreVertIcon />
                                  </Box>

                                  <Menu
                                    id="long-menu"
                                    anchorEl={surveyAnchor}
                                    open={folder_id === activeId}
                                    onClose={e => {
                                      setActiveId('');
                                      setActiveFolderId('');
                                      setActiveFolderName('');
                                      setSurveyAnchor(null);
                                    }}
                                    onClick={e => {
                                      e.stopPropagation();
                                      // setActiveFolderId(folder_id);
                                    }}
                                  >
                                    <MenuItem
                                      onClick={e => {
                                        e.stopPropagation();
                                        setActiveFolderId(folder_id);
                                        setActiveFolderName(folder_name);
                                        setActiveId('');
                                        setSurveyAnchor(null);
                                      }}
                                    >
                                      <Typography>Rename</Typography>
                                    </MenuItem>

                                    <MenuItem
                                      onClick={e => {
                                        e.stopPropagation();
                                        deleteExistingFolder(folder_id);
                                        setActiveId('');
                                        setSurveyAnchor(null);
                                      }}
                                    >
                                      <Typography>Delete</Typography>
                                    </MenuItem>

                                    <MenuItem
                                      onClick={e => {
                                        e.stopPropagation();
                                        history.push(`/folder/${folder_id}/`);
                                        setActiveId('');
                                      }}
                                    >
                                      <Typography>View Details</Typography>
                                    </MenuItem>
                                  </Menu>
                                </div>
                              )}
                            </Box>
                          </div>
                        </Grid>
                      ))}
                    </Grid>
                    <Typography my={2}>All Surveys</Typography>
                    <Grid container spacing={1.75}>
                      {surveys.map(
                        (
                          {
                            survey_name,
                            survey_id,
                            status,
                            created_at,
                            responses,
                            survey_response_limit,
                            platform,
                            created_by,
                            research_type,
                          },
                          idx,
                        ) => (
                          <Grid item lg={4} key={idx}>
                            <div
                              className={
                                hoverSurveyIds.includes(survey_id) || folderSurveyIds.includes(survey_id)
                                  ? classes.surveyPaper
                                  : classes.templatePaper
                              }
                              onClick={() => history.push(`/${research_type}/${survey_id}/`)}
                              onMouseOver={e => {
                                if (!hoverSurveyIds.includes(survey_id)) {
                                  const newHoverSurveyIds = [...hoverSurveyIds, survey_id];
                                  setHoverSurveyId(newHoverSurveyIds);
                                }
                              }}
                              onMouseOut={e => {
                                setHoverSurveyId([]);
                              }}
                            >
                              <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} mb={2}>
                                <Box display="flex" alignItems="center">
                                  {surveyStatusFeature[status]?.icon && (
                                    <Box pr={1}>{surveyStatusFeature[status]?.icon}</Box>
                                  )}
                                  <Typography variant={'subtitle1'} color={surveyStatusFeature[status]?.color}>
                                    {surveyStatusFeature[status]?.title}
                                  </Typography>
                                </Box>

                                {user?.w_role === 'editor' && (
                                  <div style={{ marginLeft: 'auto' }}>
                                    <Box
                                      p={0.5}
                                      mr={-1.6}
                                      onClick={e => {
                                        e.stopPropagation();
                                        setSurveyAnchor(e.currentTarget);
                                        setActiveId(survey_id);
                                      }}
                                    >
                                      <MoreVertIcon />
                                    </Box>

                                    <Menu
                                      id="long-menu"
                                      anchorEl={surveyAnchor}
                                      open={survey_id === activeId}
                                      onClose={e => {
                                        setSurveyAnchor(null);
                                        setActiveId('');
                                      }}
                                      onClick={e => {
                                        e.stopPropagation();
                                      }}
                                    >
                                      {status !== 'archived' && status !== 'completed' && (
                                        <MenuItem
                                          onClick={e => {
                                            e.stopPropagation();
                                            history.push(`/survey/${activeId}/editor`);
                                            setActiveId('');
                                          }}
                                        >
                                          <Typography>Edit</Typography>
                                        </MenuItem>
                                      )}

                                      <MenuItem
                                        onClick={e => {
                                          e.stopPropagation();
                                          history.push(`/survey/${activeId}/`);
                                          setActiveId('');
                                        }}
                                      >
                                        <Typography>View Details</Typography>
                                      </MenuItem>

                                      <MenuItem
                                        onClick={e => {
                                          e.stopPropagation();
                                          duplicateSurvey();
                                          setActiveId('');
                                        }}
                                      >
                                        <Typography>Duplicate</Typography>
                                      </MenuItem>

                                      <MenuItem
                                        onClick={e => {
                                          e.stopPropagation();
                                          setAnchorPopover(e.currentTarget);
                                          setPopoverId(survey_id);
                                          handleProjectList();
                                          setAnchorType('copy');
                                        }}
                                      >
                                        <Box display={'flex'} alignItems={'center'}>
                                          <Typography>Copy to project</Typography>
                                          <ArrowDropDownIcon style={{ paddingLeft: '0.5rem', marginTop: '3px' }} />
                                        </Box>

                                        <Popover
                                          open={anchorType === 'copy' && survey_id === popoverId}
                                          anchorEl={anchorPopover}
                                          anchorOrigin={{
                                            vertical: 'center',
                                            horizontal: 'right',
                                          }}
                                          transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'left',
                                          }}
                                          onClick={e => {
                                            e.stopPropagation();
                                            setAnchorPopover(null);
                                            setPopoverId('');
                                            setActiveId('');
                                          }}
                                        >
                                          <Box>
                                            {projectList.map(({ workspace_id, workspace_name }, idx) => (
                                              <MenuItem
                                                key={idx}
                                                onClick={() => handleSurveyMigration(survey_id, workspace_id)}
                                              >
                                                <Typography width={'18ch'} noWrap>
                                                  {workspace_name}
                                                </Typography>
                                              </MenuItem>
                                            ))}
                                          </Box>
                                        </Popover>
                                      </MenuItem>

                                      {status !== 'archived' && (
                                        <MenuItem
                                          onClick={e => {
                                            e.stopPropagation();
                                            archiveSurveyItem();
                                            setActiveId('');
                                          }}
                                        >
                                          <Typography>Archive</Typography>
                                        </MenuItem>
                                      )}

                                      <MenuItem
                                        onClick={e => {
                                          e.stopPropagation();
                                          setFolderPopover(e.currentTarget);
                                          setPopoverId(survey_id);
                                          setAnchorType('move');
                                        }}
                                      >
                                        <Box display={'flex'} alignItems={'center'}>
                                          <Typography>Move to</Typography>
                                          <ArrowDropDownIcon style={{ paddingLeft: '0.5rem', marginTop: '3px' }} />
                                        </Box>

                                        <Popover
                                          open={anchorType === 'move' && survey_id === popoverId}
                                          anchorEl={folderPopover}
                                          anchorOrigin={{
                                            vertical: 'center',
                                            horizontal: 'right',
                                          }}
                                          transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'left',
                                          }}
                                          onClick={e => {
                                            e.stopPropagation();
                                            setFolderPopover(null);
                                            setPopoverId('');
                                            setActiveId('');
                                          }}
                                        >
                                          <Box>
                                            {folderList.map(({ folder_id, folder_name }, idx) => (
                                              <MenuItem
                                                key={idx}
                                                onClick={() => addSurveyToFolderList(folder_id, survey_id)}
                                              >
                                                <Typography width={'18ch'} noWrap>
                                                  {folder_name}
                                                </Typography>
                                              </MenuItem>
                                            ))}
                                          </Box>
                                        </Popover>
                                      </MenuItem>
                                    </Menu>
                                  </div>
                                )}
                              </Box>
                              <div style={{ height: '70px' }}>
                                <Typography
                                  variant={'h4'}
                                  className={classes.surveyNameText}
                                  style={{ wordWrap: 'break-word' }}
                                >
                                  {survey_name}
                                </Typography>

                                <Typography variant="subtitle1" style={{ color: '#5e5e5e' }}>
                                  {`Created by ${created_by} | ${modifiedDate(created_at, 'DD MMM YY')}`}
                                </Typography>
                              </div>

                              <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                                {status !== 'draft' && (
                                  <Box display="flex" alignItems="center" className={classes.responseBox}>
                                    <Typography variant={'subtitle1'} display="inline" style={{ paddingRight: 4 }}>
                                      {numberWithCommas(responses)} responses .
                                    </Typography>

                                    <Box display={'flex'} alignItems={'center'}>
                                      <div style={{ width: 14 }}>
                                        <CircularProgressbar
                                          value={getResponsePercent(status, responses, survey_response_limit) as number}
                                          styles={buildStyles({
                                            pathColor: '#DC763C',
                                            trailColor: 'rgba(255, 164, 135, 0.4)',
                                          })}
                                        />
                                      </div>
                                      <Typography variant={'subtitle1'} display="inline" pl={1} color={'warning.dark'}>
                                        {status === 'completed'
                                          ? '100% Complete'
                                          : `${((responses / survey_response_limit) * 100).toFixed(1)}% Complete`}
                                      </Typography>
                                    </Box>
                                  </Box>
                                )}
                                {hoverSurveyIds.includes(survey_id) || folderSurveyIds.includes(survey_id) ? (
                                  <Box
                                    width={status === 'draft' ? '100%' : 'fit-content'}
                                    height={'44px'}
                                    onClick={e => e.stopPropagation()}
                                    display={'flex'}
                                    flexDirection={status === 'draft' ? 'row-reverse' : 'row'}
                                    style={{ marginRight: '-10px' }}
                                  >
                                    <Checkbox
                                      checked={folderSurveyIds.includes(survey_id)}
                                      onChange={e => {
                                        setFolderSurveyId(
                                          e.target.checked
                                            ? [...folderSurveyIds, survey_id]
                                            : folderSurveyIds.filter(s => s !== survey_id),
                                        );
                                      }}
                                      style={{ color: 'black' }}
                                    />
                                  </Box>
                                ) : (
                                  <img
                                    src={
                                      research_type === 'prototype' || research_type === 'quiz'
                                        ? `https://cdn.blitzllama.com/survey/platform/${research_type}.svg`
                                        : platform
                                        ? `https://cdn.blitzllama.com/survey/platform/${platform}-sq.svg`
                                        : ''
                                    }
                                    alt=""
                                    style={{ marginLeft: 'auto', height: '44px' }}
                                  />
                                )}
                              </Box>
                            </div>
                          </Grid>
                        ),
                      )}
                    </Grid>
                  </>
                )}
              </div>

              {activeShowMore && (
                <Typography variant={'subtitle1'} className={classes.showMoreText} onClick={() => fetchSurveys(true)}>
                  Show more results
                </Typography>
              )}
            </>
          )}
        </div>

        {totalSurveyCount === 0 && (
          <>
            <Box
              display={'flex'}
              alignItems={'center'}
              justifyContent={'space-between'}
              mt={4}
              style={surveys && surveys.length > 0 ? { borderBlock: `1px solid #F0F0F8`, paddingBlock: '0.75rem' } : {}}
            >
              <Typography fontWeight={700}>
                <span role="img" aria-label="">
                  🚀
                </span>{' '}
                Getting started with Blitzllama
              </Typography>
            </Box>
            <StartScreenComponent />
          </>
        )}
      </div>
    );
  }
};

export default SurveyHomeComponent;
