import androidImg from '../images/connection/conn_android.svg';
import iosImg from '../images/connection/conn_ios.png';
import csvImg from '../images/connection/conn_csv.svg';
import csvFullImg from '../images/connection/conn_csv_full.svg';
import webImg from '../images/connection/conn_web.svg';
import gtmImg from '../images/connection/conn_gtm.svg';
import segmentImg from '../images/connection/conn_segment.svg';
import webhookImg from '../images/connection/conn_webhook.svg';
import zapierImg from '../images/connection/conn_zapier.svg';
import mixpanelImg from '../images/connection/conn_mixpanel.svg';
import reactNativeImg from '../images/connection/conn_react_native.svg';
import flutterImg from '../images/connection/conn_flutter.svg';
import googlePlayImg from '../images/connection/conn_playstore.png';
import appStoreImg from '../images/connection/conn_appstore.svg';
import apiImg from '../images/connection/conn_api.svg';
import clevertapImg from '../images/connection/conn_clevertap.svg';
import amplitudeImg from '../images/connection/conn_amplitude.svg';
import slackImg from '../images/connection/conn_slack.svg';
import backendTriggerImg from '../images/connection/conn_backend.svg';
import rudderstackImg from '../images/connection/conn_rudderstack.svg';
import freshdeskImg from '../images/connection/conn_freshdesk.png';
import zendeskImg from '../images/connection/conn_zendesk.svg';
import intercomImg from '../images/connection/conn_intercom.svg';
import quickTextImg from '../images/connection/conn_quicktext.svg';
import surveyIcon from '../images/survey/survey.svg';

export const connectionNavBar = [
  {
    value: 'all',
    label: 'All',
  },
  {
    value: 'cdp',
    label: 'CDP',
  },
  {
    value: 'source',
    label: 'Source',
  },
];
export const connectedMainPlatforms = ['android', 'ios', 'web', 'gtm', 'hybrid', 'flutter'];

export const connectedOtherPlatforms = [
  'mixpanel',
  'clevertap',
  'segment',
  'api',
  'segment_stream',
  'mixpanel_destination',
  'slack',
  'backend_trigger',
  'rudderstack',
  'amplitude',
  'amplitude_destination',
  'clevertap_destination',
  'rudderstack_destination',
  'zapier',
  'freshdesk',
  'zendesk',
  'intercom',
  'amplitude_stream',
];

export const connectedSlidePlatforms = [
  'android',
  'ios',
  'csv',
  'web',
  'gtm',
  'hybrid',
  'flutter',
  'mixpanel',
  'amplitude',
  'clevertap',
  'segment',
  'segment_stream',
  'mixpanel_destination',
  'amplitude_destination',
  'clevertap_destination',
  'rudderstack_destination',
  'api',
  'slack',
  'webhook',
  'backend_trigger',
  'rudderstack',
  'zapier',
  'freshdesk',
  'zendesk',
  'intercom',
  'amplitude_stream',
];

export const insightsSlidePlatforms = ['playstore', 'appstore'];

export const teamPlanConnections = [
  'segment_stream',
  'mixpanel_destination',
  'amplitude_destination',
  'clevertap_destination',
  'rudderstack_destination',
  'api',
  'webhook',
  'zapier',
];

export const connectionData = {
  platforms: [
    {
      connection_img: androidImg,
      connection_value: 'android', //backend verify
      connection_title: 'Android',
      connection_tile: 'Launch surveys in real-time and send user attribute data to Blitzllama.',
      connection_intro: 'Launch surveys in real-time and send user attribute data to Blitzllama.',
      key: 'API key',
      documentation: 'https://documentation.blitzllama.com/sdk/android',
      chips: ['Surveys', 'Attributes'],
      developer_effort: 'Required',
      best_use: [
        'Instantly launching targeted surveys anywhere inside the app.',
        'Seamlessly collecting and sending user attribute data from the Android app.',
      ],
      need: [
        'Integration of the Blitzllama SDK into your Android app.',
        'Adding the triggers and updating attributes from relevant app components.',
      ],
    },
    {
      connection_img: iosImg,
      connection_value: 'ios', //backend verify
      connection_title: 'iOS',
      connection_tile: 'Launch surveys in real-time and send user attribute data to Blitzllama.',
      connection_intro: 'Launch surveys in real-time and send user attribute data to Blitzllama.',
      key: 'API key',
      documentation: 'https://documentation.blitzllama.com/sdk/ios',
      chips: ['Surveys', 'Attributes'],
      developer_effort: 'Required',
      best_use: [
        'Instantly launching targeted surveys anywhere inside the app.',
        'Seamlessly collecting and sending user attribute data from the iOS app.',
      ],
      need: [
        'Integration of the Blitzllama SDK into your iOS app.',
        'Adding the triggers and updating attributes from relevant app components.',
      ],
    },
    {
      connection_img: webImg,
      connection_value: 'web', //backend verify
      connection_title: 'Web',
      connection_tile: 'Launch surveys in real-time and send user attribute data to Blitzllama.',
      connection_intro: 'Launch surveys in real-time and send user attribute data to Blitzllama.',
      key: 'API key',
      documentation: 'https://documentation.blitzllama.com/sdk/web',
      chips: ['Surveys', 'Attributes'],
      developer_effort: 'Required',
      best_use: [
        'Instantly launching targeted surveys anywhere in the website.',
        'Seamlessly collecting and sending user attributes and actions from website.',
      ],
      need: [
        'Integration of the Blitzllama JS code in your website.',
        'Adding the triggers and updating attributes from relevant web components.',
      ],
    },
    {
      connection_img: gtmImg,
      connection_value: 'gtm', //backend verify
      connection_title: 'GTM',
      connection_tile: 'Launch surveys in real-time and send user attribute data to Blitzllama.',
      connection_intro: 'Launch surveys in real-time and send user attribute data to Blitzllama.',
      key: 'API key',
      documentation: 'https://documentation.blitzllama.com/sdk/google-tag-manager',
      chips: ['No code'],
      developer_effort: 'None',
      best_use: [
        'Instantly launching targeted surveys anywhere in the website.',
        'Seamlessly collecting and sending user attributes and actions from website.',
      ],
      need: [
        'Integration of the Blitzllama gtm code in your website.',
        'Adding the triggers and updating attributes from relevant gtm components.',
      ],
    },
    {
      connection_img: reactNativeImg,
      connection_value: 'hybrid', //backend verify
      connection_title: 'React Native',
      connection_tile: 'Launch surveys in real-time and send user attribute data to Blitzllama.',
      connection_intro: 'Launch surveys in real-time and send user attribute data to Blitzllama.',
      key: 'API key',
      documentation: 'https://documentation.blitzllama.com/sdk/react-native',
      chips: ['Surveys', 'Attributes'],
      developer_effort: 'Required',
      best_use: [
        'Instantly launching targeted surveys anywhere inside the app.',
        'Seamlessly collecting and sending user attribute data from the Android app.',
      ],
      need: [
        'Integration of the Blitzllama SDK into your Android app.',
        'Adding the triggers and updating attributes from relevant app components.',
      ],
    },
    {
      connection_img: flutterImg,
      connection_value: 'flutter', //backend verify
      connection_title: 'Flutter',
      connection_tile: 'Launch surveys in real-time and send user attribute data to Blitzllama.',
      connection_intro: 'Launch surveys in real-time and send user attribute data to Blitzllama.',
      key: 'API key',
      documentation: 'https://documentation.blitzllama.com/sdk/flutter',
      chips: ['Surveys', 'Attributes'],
      developer_effort: 'Required',
      best_use: [
        'Instantly launching targeted surveys anywhere inside the app.',
        'Seamlessly collecting and sending user attribute data from the flutter app.',
      ],
      need: [
        'Integration of the Blitzllama SDK into your Flutter app.',
        'Adding the triggers and updating attributes from relevant app components.',
      ],
    },
    {
      connection_img: apiImg,
      connection_value: 'api', //backend verify
      connection_title: 'API',
      connection_tile: 'Launch surveys in real-time and send user attribute data to Blitzllama.',
      connection_intro: 'Launch surveys in real-time and send user attribute data to Blitzllama.',
      key: 'API key',
      documentation: 'https://documentation.blitzllama.com/sdk/api',
      chips: ['Surveys'],
      developer_effort: 'Required',
      best_use: ['Instantly launching targeted surveys anywhere inside the app.'],
      need: ['Integration of the Blitzllama SDK into your working environment.'],
    },
  ],
  feedback_sources: [
    {
      connection_img: googlePlayImg,
      connection_value: 'playstore', //backend verify
      connection_title: 'Play Store',
      connection_tile: 'Sync Play Store reviews and generate insights from what your users are saying.',
      connection_intro: 'Sync Play Store reviews and generate insights from what your users are saying.',
      key: 'Play store URL',
      documentation: '',
      chips: ['Feedback'],
      developer_effort: 'None',
      best_use: [],
      need: [],
    },
    {
      connection_img: appStoreImg,
      connection_value: 'appstore', //backend verify
      connection_title: 'App Store',
      connection_tile: 'Sync App Store reviews and generate insights from what your users are saying.',
      connection_intro: 'Sync App Store reviews and generate insights from what your users are saying.',
      key: 'Play store URL',
      documentation: '',
      chips: ['Feedback'],
      developer_effort: 'None',
      best_use: [],
      need: [],
    },
    {
      connection_img: freshdeskImg,
      connection_value: 'freshdesk', //backend verify
      connection_title: 'Freshdesk',
      connection_tile: 'Sync freshdesk tickets and generate insights from what your users are saying.',
      connection_intro: 'Sync freshdesk tickets and generate insights from what your users are saying.',
      key: 'Connection key',
      documentation: 'https://documentation.blitzllama.com/',
      chips: ['Feedback'],
      developer_effort: 'None',
      best_use: ['Connect your Freshdesk account'],
      need: [],
    },
    {
      connection_img: zendeskImg,
      connection_value: 'zendesk', //backend verify
      connection_title: 'Zendesk',
      connection_tile: 'Sync zendesk tickets and generate insights from what your users are saying.',
      connection_intro: 'Sync zendesk tickets and generate insights from what your users are saying.',
      key: 'Connection key',
      documentation: 'https://documentation.blitzllama.com/',
      chips: ['Feedback'],
      developer_effort: 'None',
      best_use: ['Connect your Zendesk account'],
      need: [],
    },
    {
      connection_img: intercomImg,
      connection_value: 'intercom', //backend verify
      connection_title: 'Intercom',
      connection_tile: 'Sync Intercom tickets and generate insights from what your users are saying.',
      connection_intro: 'Sync Intercom tickets and generate insights from what your users are saying.',
      key: 'Connection key',
      documentation: 'https://documentation.blitzllama.com/',
      chips: ['Feedback'],
      developer_effort: 'None',
      best_use: ['Connect your Intercom account'],
      need: [],
    },
    {
      connection_img: quickTextImg,
      connection_value: 'quick_text', //backend verify
      connection_title: 'Paste Text',
      connection_tile: 'Copy and paste long transcripts or conversations for quick and detailed analysis.',
      connection_intro: 'Copy and paste long transcripts or conversations for quick and detailed analysis.',
      key: '',
      documentation: '',
      chips: ['Feedback'],
      developer_effort: 'None',
      best_use: ['Instantly launching targeted surveys anywhere inside the app.'],
      need: [],
    },
  ],
  collaboration: [
    {
      connection_img: slackImg,
      connection_value: 'slack', //backend verify
      connection_title: 'Slack',
      connection_tile: 'Get notified directly in Slack about the daily survey reports',
      connection_intro: 'Get notified directly in Slack about the daily survey reports',
      key: 'Connection key',
      documentation: 'https://documentation.blitzllama.com/connections-destinations/slack',
      chips: ['Surveys'],
      developer_effort: 'None',
      best_use: ['Connect your Slack account to receive updates on live surveys'],
      need: [
        'Allow slack to add Blitzllama to send updates to your workspace',
        'Select which slack #channel to get updates',
      ],
    },
  ],
  data_import: [
    {
      connection_img: backendTriggerImg,
      connection_value: 'backend_trigger', //backend verify
      connection_title: 'Backend Trigger',
      connection_tile: 'Use backend events to trigger surveys inside your mobile app and website.',
      connection_intro: 'Use backend events to trigger surveys inside your mobile app and website.',
      key: 'Connection key',
      documentation: 'https://documentation.blitzllama.com/connections-sources/backend-triggers',
      chips: ['Surveys', 'Triggers'],
      developer_effort: 'Required',
      best_use: [
        'Trigger in-app surveys after a user completes backend events or milestone such as “user is verified”, “nth payment”, or “a successful referral”.',
        'Review experience after a user completes activity on third-party tools such as “ends the conversation on customer support tool”',
      ],
      need: [
        'Send trigger data from your backend or third-party tools to Blitzllama’s API endpoint',
        'Ensure the data format adheres to the format mentioned in the documentation',
      ],
    },
    {
      connection_img: csvImg,
      connection_value: 'csv', //backend verify
      connection_title: 'CSV',
      connection_tile: 'Upload user attributes and cohorts through CSV files.',
      connection_intro: 'Upload user attributes and cohorts through CSV files.',
      key: '',
      documentation: 'https://documentation.blitzllama.com/',
      chips: ['Attributes', 'Cohorts'],
      developer_effort: 'None',
      best_use: [
        'Uploading existing user cohorts from other tools to Blitzllama.',
        'Backfilling user attributes data.',
      ],
      need: ['A CSV file in the desired format, check out the sample file for reference.'],
    },
    {
      connection_img: clevertapImg,
      connection_value: 'clevertap', //backend verify
      connection_title: 'Clevertap',
      connection_tile: 'Sync CleverTap campaign with Blitzllama to launch targeted surveys.',
      connection_intro: 'Sync CleverTap campaign with Blitzllama to launch targeted surveys.',
      key: 'Connection key',
      documentation: 'https://documentation.blitzllama.com/connections-sources/clevertap',
      chips: ['Cohorts'],
      developer_effort: 'None',
      best_use: ['Syncing users from CleverTap Webhook campaigns periodically or one time.'],
      need: ['Create CleverTap webhook campaigns (server channel) to sync segments with Blitzllama cohorts.'],
    },
    {
      connection_img: mixpanelImg,
      connection_value: 'mixpanel', //backend verify
      connection_title: 'Mixpanel',
      connection_tile: 'Export Mixpanel Cohorts to create custom Cohorts on Blitzllama',
      connection_intro:
        'Sync Mixpanel Cohorts in real time and analyze product analytics along with user insights to deeper understand user behaviours.',
      key: 'Connection key',
      documentation: 'https://documentation.blitzllama.com/connections-sources/mixpanel',
      chips: ['Cohorts'],
      developer_effort: 'None',
      best_use: [
        'Analyse feedback data for specific cohorts and users in Mixpanel',
        'Track NPS, CSAT and quantitative feedback metrics in Mixpanel',
      ],
      need: ['Access to Mixpanel account'],
    },
    {
      connection_img: segmentImg,
      connection_value: 'segment', //backend verify
      connection_title: 'Segment',
      connection_tile: 'Sync feedback with Segment to export to your Segment Destination platforms',
      connection_intro: `Automatically send feedback data to Segment to forward it to any Segment destinations you've configured for analysis, collaboration, or storage.`,
      key: 'Connection Key',
      documentation: 'https://documentation.blitzllama.com/connections-sources/segment',
      chips: ['Attributes', 'Cohorts', 'No code'],
      developer_effort: 'None',
      best_use: [
        'Keep platforms synced with feedback data.',
        `Make decisions on other platforms based on users' feedback.`,
      ],
      need: ['Access to Segment account'],
    },
    {
      connection_img: amplitudeImg,
      connection_value: 'amplitude', //backend verify
      connection_title: 'Amplitude',
      connection_tile: 'Sync behavioural cohorts in Amplitude.',
      connection_intro: 'Sync behavioural cohorts in Amplitude.',
      connection_sublabel: 'Sync behavioural cohorts in Amplitude.',
      key: 'Connection key',
      documentation: 'https://documentation.blitzllama.com/connections-sources/amplitude',
      chips: ['Cohorts', 'No code'],
      developer_effort: 'None',
      best_use: ['Syncing users cohorts from Amplitude periodically or one time.'],
      need: [
        'Access to Amplitude’s identity and groups tracking API',
        'Blitzllama will provide the authentication credentials for the webhook.',
      ],
    },
    {
      connection_img: rudderstackImg,
      connection_value: 'rudderstack', //backend verify
      connection_title: 'Rudderstack',
      connection_tile: 'Sync Rudderstack user groups with Blitzllama.',
      connection_intro: 'Sync Rudderstack user groups with Blitzllama.',
      key: 'Connection key',
      documentation: 'https://documentation.blitzllama.com/connections-sources/rudderstack',
      chips: ['Cohorts', 'No code'],
      developer_effort: 'None',
      best_use: ['Forward data to any Rudderstack as destination for analysis, collaboration, or storage'],
      need: ['Access to Rudderstack account and add API Key'],
    },
  ],

  data_export: [
    {
      connection_img: segmentImg,
      connection_value: 'segment_stream', //backend verify
      connection_title: 'Segment Destination',
      connection_tile: 'Real-time survey event sync for further analysis',
      connection_intro:
        'It allows businesses to capture and process customer data in real-time as it happens, enabling them to make timely decisions and take immediate actions.',
      key: 'Connection Key',
      documentation: 'https://documentation.blitzllama.com/connections-destinations/segment-streams',
      chips: ['Destination', 'No code'],
      developer_effort: 'None',
      best_use: ['Forward data to any Segment as destination for analysis, collaboration, or storage'],
      need: ['Access to Segment’s account and add HTTP api from destination'],
    },
    {
      connection_img: mixpanelImg,
      connection_value: 'mixpanel_destination', //backend verify
      connection_title: 'Mixpanel Destination',
      connection_tile: 'Real-time event data collection for further analysis',
      connection_intro:
        'It allows businesses to capture and process customer data in real-time as it happens, enabling them to make timely decisions and take immediate actions.',
      key: 'Connection Key',
      documentation: 'https://documentation.blitzllama.com/connections-destinations/mixpanel-destination',
      chips: ['Destination', 'No code'],
      developer_effort: 'None',
      best_use: ['Forward data to any Mixpanel as destination for analysis, collaboration, or storage'],
      need: ['Access to Mixpanel’s account and add Project Token from destination'],
    },
    {
      connection_img: amplitudeImg,
      connection_value: 'amplitude_destination', //backend verify
      connection_title: 'Amplitude',
      connection_tile: 'Real-time event data collection for further analysis',
      connection_intro:
        'It allows businesses to capture and process customer data in real-time as it happens, enabling them to make timely decisions and take immediate actions.',
      key: 'Connection Key',
      documentation: 'https://documentation.blitzllama.com/connections-destinations/amplitude',
      chips: ['Destination', 'No code'],
      developer_effort: 'None',
      best_use: ['Forward data to any Amplitude as destination for analysis, collaboration, or storage'],
      need: ['Access to Amplitude account and add API Key'],
    },
    {
      connection_img: clevertapImg,
      connection_value: 'clevertap_destination', //backend verify
      connection_title: 'CleverTap',
      connection_tile: 'Real-time event data collection for further analysis',
      connection_intro:
        'It allows businesses to capture and process customer data in real-time as it happens, enabling them to make timely decisions and take immediate actions.',
      key: 'Connection Key',
      documentation: 'https://documentation.blitzllama.com/connections-destinations/clevertap',
      chips: ['Destination', 'No code'],
      developer_effort: 'None',
      best_use: ['Forward data to any CleverTap as destination for analysis, collaboration, or storage'],
      need: ['Access to CleverTap account and add API Key'],
    },
    {
      connection_img: apiImg,
      connection_value: 'api', //backend verify
      connection_title: 'Export API',
      connection_tile: 'Export survey list and survey responses directly',
      connection_intro: 'Export survey list and survey responses directly',
      key: 'Connection key',
      documentation: 'https://documentation.blitzllama.com/connections-destinations/export-apis',
      developer_effort: 'Needed',
      chips: ['Cohorts', 'Export Data'],
      best_use: ['Creating & updating cohorts, export survey list with responses'],
      need: ['Blitzllama will provide the authentication credentials for the api.'],
    },
    {
      connection_img: webhookImg,
      connection_value: 'webhook', //backend verify
      connection_title: 'Webhook',
      connection_tile: 'Webhooks submit real-time user data and events to our HTTP endpoints.',
      connection_intro: 'Webhooks submit real-time user data and events to our HTTP endpoints.',
      key: 'Connection key',
      developer_effort: 'Needed',
      documentation: 'https://documentation.blitzllama.com/connections-destinations/webhook',
      chips: ['Cohorts', 'No code'],
      best_use: ['Receive events for responses and response group submissions'],
      need: ['Have a Webhook URL ready to process webhook events.'],
    },
    {
      connection_img: zapierImg,
      connection_value: 'zapier', //backend verify
      connection_title: 'Zapier',
      connection_tile: 'Send survey data to Zapier when a response is received',
      connection_intro: 'Send survey data to Zapier when a response is received',
      key: 'Connection key',
      developer_effort: 'Needed',
      documentation: 'https://documentation.blitzllama.com/connections-destinations/zapier',
      chips: ['Export Data', 'No code'],
      best_use: ['Receive events for responses based on zap update time'],
      need: ['Access to Zapier account and add Blitzllama Zapier API Key'],
    },
    {
      connection_img: rudderstackImg,
      connection_value: 'rudderstack_destination', //backend verify
      connection_title: 'Rudderstack Destination',
      connection_tile: 'Send Blitzllama survey event data from source to destination using our HTTP API.',
      connection_intro: 'Send Blitzllama survey event data from source to destination using our HTTP API.',
      key: 'Connection key',
      documentation: 'https://documentation.blitzllama.com/connections-destinations/rudderstack',
      chips: ['Export Data', 'No code'],
      developer_effort: 'None',
      best_use: ['Receive events for responses instantly as Rudderstack track event'],
      need: ['Access to Rudderstack account, Data Plane URL and Write Key'],
    },
  ],
};

export const cohortChannels = {
  csv: {
    img: csvImg,
    name: 'CSV',
  },
  mixpanel: {
    img: mixpanelImg,
    name: 'Mixpanel',
  },
  clevertap: {
    img: clevertapImg,
    name: 'Clevertap',
  },
  segment: {
    img: segmentImg,
    name: 'Segment',
  },
  amplitude: {
    img: amplitudeImg,
    name: 'Amplitude',
  },
  conditions: {
    img: '',
    name: 'Conditions',
  },
  survey: {
    img: '',
    name: 'Survey Followup',
  },
  'cohort api': {
    img: '',
    name: 'Cohort API',
  },
  rudderstack: {
    img: rudderstackImg,
    name: 'Rudderstack',
  },
};

export const sourceInsightImgs = {
  playstore: googlePlayImg,
  appstore: appStoreImg,
  freshdesk: freshdeskImg,
  zendesk: zendeskImg,
  intercom: intercomImg,
  quick_text: quickTextImg,
  csv: csvFullImg,
  survey: surveyIcon,
};

export const sourceRepoConfigs = {
  Integrations: [
    {
      source: 'playstore',
      title: 'Play Store',
      img: googlePlayImg,
      is_live: true,
      url: '/connections',
    },
    {
      source: 'appstore',
      title: 'App Store',
      img: appStoreImg,
      is_live: true,
      url: '/connections',
    },
    {
      source: 'freshdesk',
      title: 'Freshdesk',
      img: freshdeskImg,
      is_live: true,
      url: '/connections',
    },
    {
      source: 'zendesk',
      title: 'Zendesk',
      img: zendeskImg,
      is_live: true,
      url: '/connections',
    },
    {
      source: 'intercom',
      title: 'Intercom',
      img: intercomImg,
      is_live: true,
      url: '/connections',
    },
  ],
  Documents: [
    {
      source: 'csv',
      title: 'CSV',
      img: csvImg,
      is_live: true,
      url: '/respository/csv',
    },
    {
      source: 'text',
      title: 'Paste Text',
      img: quickTextImg,
      is_live: true,
      url: '',
    },
  ],
};

export const HideViewCohort = ['mixpanel', 'clevertap', 'survey', 'segment', 'cohort api'];
