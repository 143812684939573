import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Grid,
  Menu,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
  Switch,
  Checkbox,
} from '@mui/material';
import { useStyles } from '../EditorStyle';
import { DraggableProvided, DraggableStateSnapshot } from 'react-beautiful-dnd';
import {
  collectionQuestionRules,
  inputQuestionRules,
  multiSelectQuestionRules,
  questionSelectVariants,
  questionTypes,
  questionVariants,
  scaleQuestionsRules,
  singleSelectQuestionRules,
  validationDataFieldTypes,
} from '../../../static/questionRule';
import { editSurveyQuestionType } from '../../../services/surveyQuestionService';
import { getQuestionChangeData, questionRule } from '../../../utils/questionUtil';
import { ISurvey } from '../../../types/survey';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import intentTooltipIcon from '../../../images/surveyPerformance/intent-tooltip-icon.svg';
import AlertUtil from '../../../utils/AlertUtil';
import conditionImg from '../../../images/editor/condition-icon.svg';
import deleteImg from '../../../images/editor/delete-icon.svg';
import exitImg from '../../../images/demo/mobile-exit.svg';
import urlImg from '../../../images/editor/url-icon.svg';
import prototypeLinkImg from '../../../images/editor/prototype-link.svg';
import emptyPrototypeImg from '../../../images/editor/empty-prototype.svg';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';

import { getPrototypeLink, isValidImageURL, isValidUrl } from '../../../utils/stringUtil';
import { getRandomizeType, maxOptionsList } from '../../../static/staticData';
import { imageUpload } from '../../../services/imageService';
import ENV from '../../../config';
import { ImageInterface } from '../../../types/ImageInterface';
import { useRef, useState } from 'react';

interface Props {
  provided: DraggableProvided;
  snapshot: DraggableStateSnapshot;
  can_edit: boolean;
  activeLanguage: string;
  question_order: number;
  question_type: string;
  question_desc: string;
  question_variant: string;
  question_text: string;
  cta_link: string;
  cta_text: string;
  editable?: boolean;
  properties: any;
  rule_sets: any;
  default_rule_skip_to: number | '';
  is_mandatory: boolean;
  prototype_link: string;
  placeholder: any;
  validations: any;
  max_selections: number;
  show_as_dropdown: boolean;
  fetchSurvey: () => void;
  updateDraftChanges: (show_alert: boolean, check_publish: boolean) => void;
  updateChanges: (survey: ISurvey) => void;
  survey: ISurvey;
  question_id: string;
  questionEditAnchor: any;
  setQuestionEditAnchor: (anchor: any) => void;
  setActiveId: (id: string) => void;
  activeId: string;
  moveUpQuestionItem: () => void;
  moveDownQuestionItem: () => void;
  handleDeleteQuestion: () => void;
  editQuestionHandler: (fieldName: string, fieldValue: string, q_id: string) => void;
  editQuestionChoice: (q_id: string, action: string, idx: number, option?: string) => void;
  question_img: string;
  index: number;
  editMandatoryQuestionHandler: (fieldName: string, fieldValue: boolean, q_id: string) => void;
}

export default function QuestionCard({
  snapshot,
  provided,
  can_edit,
  activeLanguage,
  question_order,
  question_type,
  question_desc,
  question_variant,
  question_text,
  cta_link,
  cta_text,
  editable,
  properties,
  rule_sets,
  default_rule_skip_to,
  is_mandatory,
  prototype_link,
  placeholder,
  validations,
  max_selections,
  show_as_dropdown,
  fetchSurvey,
  updateDraftChanges,
  updateChanges,
  survey,
  question_id,
  questionEditAnchor,
  setQuestionEditAnchor,
  setActiveId,
  activeId,
  handleDeleteQuestion,
  editQuestionHandler,
  editQuestionChoice,
  question_img,
  index,
  editMandatoryQuestionHandler,
}: Props) {
  const classes = useStyles();

  const { questions, research_type, platform, status } = survey;
  const inputFileRefArray = useRef<Array<HTMLInputElement>>([]);
  const [questImgId, setQuestImgId] = useState('');
  const [isAccordionOpen, setIsAccordionOpen] = useState(true);

  const activeQuestion = (question_type: string) => {
    const result = questionTypes.find(q => q.value === question_type);
    if (result) return result;
  };

  const editQuestionTypeItem = async (question_id: string, question_type: string, question_order: number) => {
    if (question_id.length > 5) {
      await updateDraftChanges(false, false);
      const editQuestion = await editSurveyQuestionType({ question_order }, question_id, question_type);
      if (editQuestion && editQuestion.message) {
        await fetchSurvey();
      }
    } else {
      const { questions } = survey;
      const quest = getQuestionChangeData(questions[+question_id - 1], question_type, +question_id);
      questions[+question_id - 1] = quest;
      updateChanges({ ...survey, questions });
    }
  };

  //will have to be added with question logic
  const addQuestionCondition = async (question_type: string, question_order: number) => {
    if (can_edit) {
      const { questions } = survey;
      const conditionDefault = questionRule(question_type, question_order, questions.length);
      const ruleSetLength = questions[+question_order - 1].rule_sets?.length;
      if (ruleSetLength >= 0 && ruleSetLength < 12) {
        questions[+question_order - 1].rule_sets?.push(conditionDefault);
        updateChanges({ ...survey, questions });
      } else {
        AlertUtil.fire({
          icon: 'error',
          title: 'More than 12 conditions cannot be added',
        });
        return;
      }
    }
  };

  const getBase64 = (e: any) => {
    return new Promise(resolve => {
      const reader: ImageInterface = new FileReader();
      reader.onload = () => {};
      reader.readAsDataURL(e.target.files[0]);
      reader.onload = () => {
        const base64data = reader.result;
        resolve(base64data);
      };
    });
  };

  const imageHandler = async (e: any) => {
    if (e.target.files && e.target.files.length > 0 && e.target.files[0].name && e.target.files[0].size) {
      const fileSizeInMB = e.target.files[0].size / 1048576;
      if (fileSizeInMB > 10) {
        AlertUtil.fire({
          icon: 'error',
          title: 'Image size cannot exceed 10 MB',
        });
        return;
      }

      const fileExt = e.target.files[0].name.split('.').pop();
      const imgBase64 = await getBase64(e);

      const imgResult = await imageUpload({
        image_type: fileExt,
        name: '',
        base64: imgBase64,
        folder: 'question',
      });

      if (imgResult) {
        const { questions } = survey;

        for (const i in questions) {
          const { question_id } = questions[i];

          if (question_id === questImgId) {
            questions[i].question_img = `${ENV.cdn_url}${imgResult.img_url}`;
          }
        }

        updateChanges({ ...survey, questions });

        AlertUtil.fire({
          icon: 'success',
          title: 'Uploaded successfully',
        });
      }
    }
  };

  const editQuestionCondition = async (fieldName: string, fieldValue: string, q_id: string, index: number) => {
    if (!survey) {
      return;
    }
    const { questions } = survey;

    for (const i in questions) {
      const { question_id, rule_sets, question_type } = questions[i];

      if (question_id === q_id) {
        if (fieldName === 'rule_type') {
          rule_sets[index].rule_type = fieldValue;
        } else if (fieldName === 'skip_to') {
          rule_sets[index].skip_to = +fieldValue;
        } else if (fieldName === 'options') {
          //multi select has uniq multi choice array
          if (question_type === 'multi_select_feedback') {
            if (rule_sets[index].options?.includes(fieldValue)) {
              rule_sets[index].options = rule_sets[index].options?.filter(o => o !== fieldValue);
            } else {
              rule_sets[index].options?.push(fieldValue);
            }
          } else if (question_type === 'single_select_feedback') {
            if (rule_sets[index].options && rule_sets[index].options?.includes(fieldValue)) {
              rule_sets[index].options = [];
            } else {
              rule_sets[index].options = [fieldValue];
            }
          }
        } else if (fieldName === 'option') {
          fieldValue = fieldValue.trim();
          if (fieldValue === '') {
            rule_sets[index].option = undefined;
          } else {
            let scale_value = +fieldValue;

            if (question_type === 'nps_feedback' && scale_value < 0) {
              scale_value = 0;
            }
            if (question_type === 'nps_feedback' && scale_value > 10) {
              scale_value = 10;
            }
            if (question_type === 'scale_feedback' && scale_value <= 0) {
              scale_value = 1;
            }
            if (question_type === 'scale_feedback' && scale_value > 5) {
              scale_value = 5;
            }

            //may be fractional in future
            if ((question_type === 'star_feedback' || question_type === 'emoji_feedback') && scale_value <= 0) {
              scale_value = 1;
            }
            if ((question_type === 'star_feedback' || question_type === 'emoji_feedback') && scale_value > 5) {
              scale_value = 5;
            }

            rule_sets[index].option = scale_value;
          }
        } else if (fieldName === 'data_array') {
          rule_sets[index].data_array = fieldValue;
          fieldValue = fieldValue.trim();
          const fieldArray = fieldValue.split(/[\s,\n]+/);
          rule_sets[index].options = fieldArray;
        }

        questions[i].rule_sets = rule_sets;
        updateChanges({ ...survey, questions });
      }
    }
  };

  const deleteQuestionImg = (q_id: string) => {
    const { questions } = survey;

    for (const i in questions) {
      const { question_id } = questions[i];

      if (question_id === q_id) {
        questions[i].question_img = '';
      }
    }

    updateChanges({ ...survey, questions });

    AlertUtil.fire({
      icon: 'success',
      title: 'Updated successfully',
    });
  };

  const deleteQuestionCondition = async (q_id: string, index: number) => {
    if (can_edit) {
      const { questions } = survey;

      for (const i in questions) {
        const { question_id, rule_sets } = questions[i];

        if (question_id === q_id) {
          rule_sets.splice(index, 1);
        }
      }
      updateChanges({ ...survey, questions });
    }
  };

  // image upload
  const onButtonClick = (question_id: string, idx: number) => {
    //update question_id due to index issue
    setQuestImgId(question_id);
    inputFileRefArray.current[idx]?.click();
  };

  const getLabel = (questionType: string) => {
    const question = questionTypes.find(q => q.value === questionType);
    return question?.label;
  };

  return (
    <Box className={`${classes.questionPaper} ${snapshot.isDragging ? classes.draggingQuestion : ''}`}>
      <Tooltip
        classes={{
          tooltip: classes.customTooltip,
        }}
        title={
          <Box maxWidth={'200px'}>
            <Typography variant="subtitle2">{'Click and drag to change the order of the questions.'}</Typography>
          </Box>
        }
        placement="top"
      >
        <div
          className={classes.questionOrderBox}
          {...(can_edit && activeLanguage === 'en' ? provided.dragHandleProps : {})}
        >
          <Typography variant={'subtitle1'} style={{ color: 'black' }}>
            #{question_order}
          </Typography>
          {/* <DragIndicatorIcon style={{ color: 'black' }} /> */}
        </div>
      </Tooltip>
      <Box px={3} py={1}>
        <Accordion
          defaultExpanded
          disableGutters
          sx={{
            bgcolor: 'transparent',
            border: 0,
            boxShadow: 'none',
          }}
        >
          <Box display="flex" flexDirection="column" width="100%" gap={2}>
            <AccordionSummary onClick={() => setIsAccordionOpen(prev => !prev)}>
              <Box display="flex" alignItems="center" width="100%">
                <Box display="flex" alignItems="center" gap={2}>
                  {can_edit || editable ? (
                    <Select
                      variant="outlined"
                      disabled={!(can_edit || editable)}
                      displayEmpty
                      className={classes.questionSelectField}
                      onClick={e => e.stopPropagation()}
                      sx={{
                        '& .MuiOutlinedInput-input': {
                          backgroundColor: `${activeQuestion(question_type)?.bg_color}`,
                          color: `${activeQuestion(question_type)?.color}`,
                        },
                        '& .MuiSvgIcon-root': {
                          color: `${activeQuestion(question_type)?.color}`,
                        },
                      }}
                      renderValue={() =>
                        activeQuestion && (
                          <Box display={'flex'} alignItems="center">
                            <Typography variant={'subtitle1'}>{activeQuestion(question_type)?.label}</Typography>
                          </Box>
                        )
                      }
                    >
                      <Box width={'240px'}>
                        <MenuItem value="" disabled>
                          <Typography variant={'subtitle2'}>Select question type</Typography>
                        </MenuItem>
                        {questionTypes.map((option, key) => (
                          <MenuItem
                            key={key}
                            disabled={question_type === option.value}
                            value={option.value}
                            onClick={e => editQuestionTypeItem(question_id, option.value, question_order)}
                          >
                            <Box display="flex" alignItems="center">
                              <Typography
                                variant="body1"
                                pl={1}
                                color={option.value === question_type ? 'primary.contrastText' : ''}
                              >
                                {option.label}
                              </Typography>
                            </Box>
                          </MenuItem>
                        ))}
                      </Box>
                    </Select>
                  ) : (
                    <Typography variant={'subtitle1'} fontWeight={700} className={classes.nonEditableQuestion}>
                      {getLabel(question_type)}
                    </Typography>
                  )}
                  {question_type === 'data_collection' && (
                    <Tooltip
                      classes={{
                        tooltip: classes.customTooltip,
                      }}
                      title={
                        <Box maxWidth={'200px'}>
                          <Typography variant="subtitle2" fontWeight={700}>
                            Data Collection
                          </Typography>
                          <Typography variant="subtitle2">
                            {'Only available in Survey Links, Web, Android > v1.7.3 & React native > v1.3.3.'}
                          </Typography>
                        </Box>
                      }
                      placement="top"
                    >
                      <img src={intentTooltipIcon} alt="" style={{ cursor: 'pointer', marginLeft: 10 }} />
                    </Tooltip>
                  )}

                  {question_type === 'matrix_feedback' && isAccordionOpen && (
                    <Typography variant="subtitle1" color="gray" ml={1} fontSize={12}>
                      Available only in Link Surveys
                    </Typography>
                  )}
                </Box>

                {!isAccordionOpen && (
                  <Typography
                    variant="subtitle1"
                    color="text.primary"
                    ml={2}
                    sx={{
                      fontWeight: 700,
                    }}
                  >
                    {question_text.length > 100 ? `${question_text.slice(0, 100)}...` : question_text}
                  </Typography>
                )}

                <Box
                  sx={{
                    ml: 'auto',
                    display: 'flex',
                    alignItems: 'center',
                    gap: 2,
                  }}
                >
                  {(can_edit || editable) && (
                    <Box
                      onClick={e => {
                        e.stopPropagation();
                        setQuestionEditAnchor(e.currentTarget);
                        setActiveId(question_id);
                      }}
                      sx={{
                        cursor: 'pointer',
                        display: 'flex',
                      }}
                    >
                      <MoreVertIcon className={classes.moreIcon} />
                    </Box>
                  )}

                  <Menu
                    anchorEl={questionEditAnchor}
                    open={question_id === activeId}
                    onClose={e => {
                      setQuestionEditAnchor(null);
                      setActiveId('');
                    }}
                    onClick={e => {
                      e.stopPropagation();
                    }}
                  >
                    <MenuItem onClick={() => handleDeleteQuestion()}>
                      <Typography variant={'subtitle1'} color={'error.main'}>
                        Delete
                      </Typography>
                    </MenuItem>
                  </Menu>
                </Box>
              </Box>
            </AccordionSummary>
          </Box>

          <AccordionDetails className={classes.questionAccordionDetails}>
            <Box width="100%">
              <Typography variant="subtitle1" className={classes.inputLabel}>
                Question
              </Typography>
              <TextField
                fullWidth
                variant="outlined"
                value={question_text}
                placeholder="Enter question text"
                name="question_text"
                className={classes.textField}
                autoComplete="off"
                sx={{
                  '& .MuiOutlinedInput-input': {
                    fontWeight: 700,
                  },
                }}
                onChange={e => editQuestionHandler(e.target.name, e.target.value, question_id)}
              />
            </Box>
            {research_type === 'prototype' && (
              <Grid container className={classes.prototypeBox}>
                <Grid item lg={9} className={classes.prototypeTextField}>
                  <Box display={'flex'} alignItems={'center'}>
                    <img src={prototypeLinkImg} alt="" />
                    <Typography variant="subtitle1" color={'primary.contrastText'} ml={0.5}>
                      Insert link
                    </Typography>
                  </Box>

                  <Box display={'flex'} alignItems={'center'}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      disabled={activeLanguage !== 'en'}
                      className={classes.textField}
                      value={prototype_link}
                      placeholder="Please insert prototype or media link here..."
                      name="prototype_link"
                      autoComplete="off"
                      onChange={e => editQuestionHandler(e.target.name, e.target.value, question_id)}
                    />
                  </Box>
                </Grid>
                <Grid
                  item
                  lg={3}
                  className={classes.linkView}
                  style={{
                    backgroundColor: prototype_link && isValidUrl(prototype_link) ? '' : '#FAFAFA',
                  }}
                >
                  {prototype_link && isValidUrl(prototype_link) ? (
                    <>
                      {isValidImageURL(prototype_link) ? (
                        <img src={getPrototypeLink(prototype_link)} alt="" height="120px" />
                      ) : (
                        <iframe
                          src={getPrototypeLink(prototype_link)}
                          width="100%"
                          height="120px"
                          frameBorder="0"
                        ></iframe>
                      )}
                    </>
                  ) : (
                    <img src={emptyPrototypeImg} alt="" />
                  )}
                </Grid>
              </Grid>
            )}

            {(question_type === 'multi_select_feedback' || question_type === 'single_select_feedback') && (
              <>
                <Typography variant="subtitle1" className={classes.inputLabel}>
                  Answer options
                </Typography>
                {properties.options.map(({ option_text, _id }, i: any) => (
                  <Box display="flex" alignItems="center" key={i}>
                    <TextField
                      key={i}
                      fullWidth
                      className={classes.textField}
                      value={option_text}
                      onChange={e => editQuestionChoice(question_id, 'edit', i, e.target.value)}
                      style={{ marginBottom: 5 }}
                      autoComplete="off"
                      variant="outlined"
                    />
                    {(can_edit || !_id) && i > 0 && (
                      <img
                        src={deleteImg}
                        alt=""
                        style={{ marginLeft: '1rem', height: '40px', width: '40px' }}
                        onClick={e => editQuestionChoice(question_id, 'delete', +i)}
                      />
                    )}
                  </Box>
                ))}

                {can_edit && (
                  <Box display="flex" alignItems={'center'}>
                    <Typography
                      variant={'body1'}
                      className={classes.addChoiceBox}
                      onClick={e => editQuestionChoice(question_id, 'add', 0)}
                    >
                      + Add answer option
                    </Typography>
                    {properties.options &&
                      properties.options.length > 0 &&
                      properties.options.some(o => o.has_text_input) == false && (
                        <>
                          <Typography variant={'body1'} ml={1.5} color={'gray'}>
                            or
                          </Typography>
                          <Typography
                            variant={'body1'}
                            ml={1}
                            onClick={e => editQuestionChoice(question_id, 'add', -1)}
                          >
                            Add "Others"
                          </Typography>
                        </>
                      )}
                  </Box>
                )}
              </>
            )}

            {question_type === 'matrix_feedback' && (
              <>
                <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} mt={3}>
                  <Box flex={1} mr={{ xs: 0, md: 2 }} mb={{ xs: 2, md: 0 }}>
                    <Typography variant="subtitle1" className={classes.inputLabel}>
                      Row
                    </Typography>
                    {properties.options.map(({ option_text, _id }, i: any) => (
                      <Box display="flex" alignItems="center" key={i} mb={1}>
                        <TextField
                          fullWidth
                          className={classes.textField}
                          value={option_text}
                          onChange={e => editQuestionChoice(question_id, 'edit', i, e.target.value)}
                          autoComplete="off"
                          variant="outlined"
                        />
                        {(can_edit || !_id) && properties.options.length > 2 && i >= 2 && (
                          <img
                            src={deleteImg}
                            alt=""
                            style={{ marginLeft: '1rem', height: '24px', width: '24px', cursor: 'pointer' }}
                            onClick={() => editQuestionChoice(question_id, 'delete', i)}
                          />
                        )}
                      </Box>
                    ))}
                    {can_edit && (
                      <Box display="flex" alignItems="center" mt={1}>
                        <Typography
                          variant="body1"
                          className={classes.addChoiceBox}
                          onClick={() => editQuestionChoice(question_id, 'add', 0)}
                        >
                          +Add Row
                        </Typography>
                      </Box>
                    )}
                  </Box>

                  <Box flex={1} ml={{ xs: 0, md: 2 }}>
                    <Typography variant="subtitle1" className={classes.inputLabel}>
                      Columns
                    </Typography>
                    {properties.matrix_columns &&
                      properties.matrix_columns.map((column, i) => (
                        <Box display="flex" alignItems="center" key={i} mb={1}>
                          <TextField
                            fullWidth
                            className={classes.textField}
                            value={column.value}
                            onChange={e => editQuestionChoice(question_id, 'matrix_edit', i, e.target.value)}
                            autoComplete="off"
                            variant="outlined"
                          />
                          {can_edit && properties.matrix_columns && properties.matrix_columns.length > 2 && i >= 2 && (
                            <img
                              src={deleteImg}
                              alt=""
                              style={{ marginLeft: '1rem', height: '24px', width: '24px', cursor: 'pointer' }}
                              onClick={() => editQuestionChoice(question_id, 'matrix_delete', i)}
                            />
                          )}
                        </Box>
                      ))}
                    {can_edit && (
                      <Box display="flex" alignItems="center" mt={1}>
                        <Typography
                          variant="body1"
                          className={classes.addChoiceBox}
                          onClick={() => editQuestionChoice(question_id, 'matrix_add', 0)}
                        >
                          +Add Column
                        </Typography>
                      </Box>
                    )}
                  </Box>
                </Box>
              </>
            )}

            {(question_type === 'scale_feedback' || question_type === 'nps_feedback') && (
              <Box display="flex" width={'100%'}>
                <Box width={1 / 2} mr={1}>
                  <Typography variant="subtitle1" className={classes.inputLabel}>
                    Low range hint text
                  </Typography>
                  <TextField
                    fullWidth
                    variant="outlined"
                    className={classes.textField}
                    value={properties?.labels?.left}
                    placeholder="Enter low range text"
                    name="left"
                    autoComplete="off"
                    onChange={e => editQuestionHandler(e.target.name, e.target.value, question_id)}
                  />
                </Box>
                <Box width={1 / 2} ml={1}>
                  <Typography variant="subtitle1" textAlign={'right'} className={classes.inputLabel}>
                    High range hint text
                  </Typography>
                  <TextField
                    fullWidth
                    variant="outlined"
                    className={classes.textField}
                    value={properties?.labels?.right}
                    sx={{
                      '& .MuiOutlinedInput-input': {
                        textAlign: 'right',
                      },
                    }}
                    placeholder="Enter high range text"
                    name="right"
                    autoComplete="off"
                    onChange={e => editQuestionHandler(e.target.name, e.target.value, question_id)}
                  />
                </Box>
              </Box>
            )}

            {question_type === 'data_collection' && validations && validations.field_type && (
              <>
                <Typography variant="subtitle1" className={classes.inputLabel}>
                  Validation
                </Typography>

                <Box display="flex" alignItems="center">
                  <div style={{ width: '30%' }}>
                    <Typography variant="subtitle1" className={classes.validationLabel}>
                      Data Field
                    </Typography>
                    <TextField
                      fullWidth
                      variant="outlined"
                      select
                      className={classes.dataTypeField}
                      value={validations.field_type}
                      name="field_type"
                      onChange={e => editQuestionHandler(e.target.name, e.target.value, question_id)}
                    >
                      {validationDataFieldTypes.map(({ label, value }, idx) => (
                        <MenuItem key={idx} value={value}>
                          <Typography>{label}</Typography>
                        </MenuItem>
                      ))}
                    </TextField>
                  </div>

                  <div style={{ width: '12ch', marginInline: '1rem' }}>
                    <Typography variant="subtitle1" className={classes.validationLabel}>
                      Min chars
                    </Typography>
                    <TextField
                      type="number"
                      fullWidth
                      variant="outlined"
                      className={classes.textField}
                      value={validations.min_length}
                      InputProps={{ inputProps: { min: 1, max: 255 } }}
                      placeholder="Min length"
                      name="min_length"
                      autoComplete="off"
                      onChange={e => editQuestionHandler(e.target.name, e.target.value, question_id)}
                    />
                  </div>
                  <div style={{ width: '12ch' }}>
                    <Typography variant="subtitle1" className={classes.validationLabel}>
                      Max chars
                    </Typography>
                    <TextField
                      type="number"
                      fullWidth
                      variant="outlined"
                      className={classes.textField}
                      value={validations.max_length}
                      InputProps={{ inputProps: { min: 1, max: 255 } }}
                      placeholder="Max length"
                      name="max_length"
                      autoComplete="off"
                      onChange={e => editQuestionHandler(e.target.name, e.target.value, question_id)}
                    />
                  </div>
                </Box>
              </>
            )}

            <Accordion className={classes.questionAccordion}>
              <AccordionSummary className={classes.questionAccordionSummary} expandIcon={<ExpandMoreIcon />}>
                <Typography fontWeight={700}>More options</Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.questionAccordionDetails}>
                <Typography variant="subtitle1" className={classes.inputLabel}>
                  Description
                </Typography>

                <TextField
                  fullWidth
                  variant="outlined"
                  className={classes.textField}
                  value={question_desc}
                  placeholder="Enter description"
                  name="question_desc"
                  autoComplete="off"
                  onChange={e => editQuestionHandler(e.target.name, e.target.value, question_id)}
                />

                {(question_type === 'input_feedback' || question_type === 'data_collection') && (
                  <>
                    <Typography variant="subtitle1" className={classes.inputLabel}>
                      Placeholder
                    </Typography>
                    <TextField
                      fullWidth
                      variant="outlined"
                      className={classes.textField}
                      value={placeholder}
                      placeholder="Enter placeholder text"
                      name="placeholder"
                      autoComplete="off"
                      onChange={e => editQuestionHandler(e.target.name, e.target.value, question_id)}
                    />
                  </>
                )}

                {(question_type === 'multi_select_feedback' ||
                  question_type === 'single_select_feedback' ||
                  question_type === 'input_feedback' ||
                  question_type === 'data_collection') && (
                  <Box>
                    <Typography variant="subtitle1" className={classes.inputLabel}>
                      CTA text
                    </Typography>

                    <TextField
                      fullWidth
                      variant="outlined"
                      className={classes.textField}
                      value={cta_text}
                      placeholder="Enter button text"
                      name="cta_text"
                      autoComplete="off"
                      onChange={e => editQuestionHandler(e.target.name, e.target.value, question_id)}
                    />
                  </Box>
                )}

                {question_type === 'intro_prompt' && (
                  <Box display="flex" alignItems={'center'} width={'100%'}>
                    <Box width={1 / 2} mr={1}>
                      <Typography variant="subtitle1" className={classes.inputLabel}>
                        CTA text
                      </Typography>

                      <TextField
                        fullWidth
                        variant="outlined"
                        className={classes.textField}
                        value={cta_text}
                        placeholder="Enter button text"
                        name="cta_text"
                        autoComplete="off"
                        onChange={e => editQuestionHandler(e.target.name, e.target.value, question_id)}
                      />
                    </Box>
                    <img src={urlImg} alt="" style={{ marginTop: '3rem' }} />
                    <Box width={1 / 2} ml={1}>
                      <Typography variant="subtitle1" className={classes.inputLabel}>
                        URL
                      </Typography>

                      <TextField
                        fullWidth
                        variant="outlined"
                        className={classes.textField}
                        value={cta_link}
                        placeholder="Enter button link"
                        name="cta_link"
                        autoComplete="off"
                        onChange={e => editQuestionHandler(e.target.name, e.target.value, question_id)}
                      />
                    </Box>
                  </Box>
                )}

                {(question_type === 'multi_select_feedback' || question_type === 'single_select_feedback') && (
                  <>
                    <Typography variant="subtitle1" className={classes.inputLabel}>
                      Randomize
                    </Typography>

                    <Select
                      fullWidth
                      variant="outlined"
                      disabled={!(can_edit || editable)}
                      className={classes.selectField}
                      name="randomize"
                      value={properties?.randomize}
                      onChange={e => editQuestionHandler(e.target.name, e.target.value, question_id)}
                    >
                      {Object.keys(getRandomizeType(properties.options)).map((item, key) => (
                        <MenuItem key={key} value={item}>
                          <Typography noWrap>{getRandomizeType(properties.options)[item]}</Typography>
                        </MenuItem>
                      ))}
                    </Select>

                    <div>
                      <Typography variant="subtitle1" className={classes.inputLabel}>
                        Question variant
                      </Typography>
                      <Select
                        displayEmpty
                        variant="outlined"
                        fullWidth
                        className={classes.questionVariantSelectField}
                        name="show_as_dropdown"
                        renderValue={() => (
                          <Typography>{show_as_dropdown === false ? 'Default' : 'Dropdown'}</Typography>
                        )}
                        value={show_as_dropdown === false ? '' : 'dropdown'}
                        onChange={e => editQuestionHandler(e.target.name, e.target.value, question_id)}
                      >
                        {questionSelectVariants.map(({ label, value }, key) => (
                          <MenuItem key={key} value={value}>
                            <Typography>{label}</Typography>
                          </MenuItem>
                        ))}
                      </Select>
                    </div>
                  </>
                )}

                {platform === 'link' && question_type === 'multi_select_feedback' && (
                  <>
                    <Typography variant="subtitle1" className={classes.inputLabel}>
                      Maximum options to choose
                    </Typography>

                    <Select
                      fullWidth
                      variant="outlined"
                      disabled={!(can_edit || editable)}
                      className={classes.selectField}
                      name="max_selections"
                      value={max_selections.toString()}
                      onChange={e => editQuestionHandler(e.target.name, e.target.value, question_id)}
                    >
                      {Object.keys(maxOptionsList).map((item, key) => (
                        <MenuItem key={key} value={item}>
                          <Typography noWrap>{maxOptionsList[item]}</Typography>
                        </MenuItem>
                      ))}
                    </Select>
                  </>
                )}

                {(platform === 'link' || platform === 'hybrid') && question_type === 'nps_feedback' && (
                  <div>
                    <Typography variant="subtitle1" className={classes.inputLabel}>
                      Question variant
                    </Typography>
                    <Select
                      displayEmpty
                      variant="outlined"
                      fullWidth
                      className={classes.questionVariantSelectField}
                      name="question_variant"
                      renderValue={() => <Typography>{question_variant === '' ? 'Default' : 'Colourised'}</Typography>}
                      value={question_variant}
                      onChange={e => editQuestionHandler(e.target.name, e.target.value, question_id)}
                    >
                      {questionVariants.map(({ label, value }, key) => (
                        <MenuItem key={key} value={value}>
                          <Typography>{label}</Typography>
                        </MenuItem>
                      ))}
                    </Select>
                  </div>
                )}

                {research_type !== 'prototype' && (
                  <>
                    <Typography variant="subtitle1" className={classes.inputLabel}>
                      Question image
                    </Typography>
                    <input
                      type="file"
                      accept=".png, .jpg, .jpeg, .gif"
                      name="image-upload"
                      ref={inputField => {
                        if (inputField) {
                          return (inputFileRefArray.current[index] = inputField);
                        } else {
                          return undefined;
                        }
                      }}
                      key={index}
                      onChange={e => imageHandler(e)}
                    ></input>

                    {question_img && (
                      <Box style={{ position: 'relative', width: 'fit-content' }}>
                        <img src={question_img} alt="" className={classes.questionImg} />
                        {activeLanguage === 'en' && (
                          <img
                            src={exitImg}
                            alt=""
                            className={classes.exitBtn}
                            onClick={() => deleteQuestionImg(question_id)}
                          />
                        )}
                      </Box>
                    )}

                    {/* show image upload only in Eng survey */}
                    {!question_img && activeLanguage === 'en' && (
                      <>
                        <Button className={classes.uploadImgButton} onClick={() => onButtonClick(question_id, index)}>
                          + Upload image
                        </Button>
                        <Typography variant="subtitle2" fontSize={'10px'} mt={0.5} color="text.secondary">
                          Web Image Dimensions: 750 × 300 px, App Image Dimensions: 300 x 240 px
                        </Typography>
                        <Typography variant="subtitle2" fontSize={'10px'} mt={-0.25} color="text.secondary">
                          [Aspect Ratio will be maintained]
                        </Typography>
                      </>
                    )}
                  </>
                )}
              </AccordionDetails>
            </Accordion>

            {rule_sets && rule_sets.length > 0 && (
              <>
                <Box pt={1.5}>
                  {rule_sets &&
                    rule_sets.map(({ skip_to, options, option, rule_type, data_array = '' }, idx) => (
                      <>
                        <Box display={'flex'} alignItems={'center'} mt={1.25}>
                          <Typography variant="subtitle1" minWidth={'10ch'} pr={1}>
                            When answer
                          </Typography>

                          {(question_type === 'nps_feedback' ||
                            question_type === 'scale_feedback' ||
                            question_type === 'star_feedback' ||
                            question_type === 'emoji_feedback') && (
                            <Select
                              variant="outlined"
                              className={rule_type === 'submit' ? classes.onSubmitSelect : classes.ruleTypeSelect}
                              style={{ marginRight: 10 }}
                              name="rule_type"
                              value={rule_type}
                              onChange={e => editQuestionCondition(e.target.name, e.target.value, question_id, idx)}
                            >
                              {scaleQuestionsRules.map((option, key) => (
                                <MenuItem key={key} value={option.value}>
                                  <Typography variant="subtitle1">{option.label}</Typography>
                                </MenuItem>
                              ))}
                            </Select>
                          )}
                          {question_type === 'single_select_feedback' && (
                            <Select
                              variant="outlined"
                              className={rule_type === 'submit' ? classes.onSubmitSelect : classes.ruleTypeSelect}
                              style={{ marginRight: 10 }}
                              inputProps={{ classes: { root: classes.selectField } }}
                              name="rule_type"
                              value={rule_type}
                              onChange={e => editQuestionCondition(e.target.name, e.target.value, question_id, idx)}
                            >
                              {singleSelectQuestionRules.map((option, key) => (
                                <MenuItem key={key} value={option.value}>
                                  <Typography variant="subtitle1" noWrap>
                                    {option.label}
                                  </Typography>
                                </MenuItem>
                              ))}
                            </Select>
                          )}
                          {question_type === 'multi_select_feedback' && (
                            <Select
                              variant="outlined"
                              className={rule_type === 'submit' ? classes.onSubmitSelect : classes.ruleTypeSelect}
                              inputProps={{ classes: { root: classes.selectField } }}
                              style={{ marginRight: 10 }}
                              name="rule_type"
                              value={rule_type}
                              onChange={e => editQuestionCondition(e.target.name, e.target.value, question_id, idx)}
                            >
                              {multiSelectQuestionRules.map((option, key) => (
                                <MenuItem key={key} value={option.value}>
                                  <Typography variant="subtitle1" noWrap>
                                    {option.label}
                                  </Typography>
                                </MenuItem>
                              ))}
                            </Select>
                          )}

                          {question_type === 'data_collection' && (
                            <Select
                              variant="outlined"
                              className={rule_type === 'submit' ? classes.onSubmitSelect : classes.ruleTypeSelect}
                              inputProps={{ classes: { root: classes.selectField } }}
                              style={{ marginRight: 10 }}
                              name="rule_type"
                              value={rule_type}
                              onChange={e => editQuestionCondition(e.target.name, e.target.value, question_id, idx)}
                            >
                              {collectionQuestionRules.map((option, key) => (
                                <MenuItem key={key} value={option.value}>
                                  <Typography variant="subtitle1" noWrap>
                                    {option.label}
                                  </Typography>
                                </MenuItem>
                              ))}
                            </Select>
                          )}

                          {(question_type === 'intro_prompt' || question_type === 'input_feedback') &&
                            rule_type === 'submit' && (
                              <Select
                                variant="outlined"
                                style={{ flexGrow: 1, marginRight: 10 }}
                                className={classes.selectField}
                                name="rule_type"
                                value={rule_type}
                                onChange={e => editQuestionCondition(e.target.name, e.target.value, question_id, idx)}
                              >
                                {inputQuestionRules.map((option, key) => (
                                  <MenuItem key={key} value={option.value}>
                                    <Typography variant="subtitle1">{option.label}</Typography>
                                  </MenuItem>
                                ))}
                              </Select>
                            )}

                          {(question_type === 'multi_select_feedback' || question_type === 'single_select_feedback') &&
                            rule_type !== 'submit' && (
                              <Select
                                variant="outlined"
                                multiple
                                displayEmpty
                                style={{ marginRight: 8, flexGrow: 1 }}
                                value={options}
                                className={classes.selectField}
                                renderValue={() =>
                                  options && options.length !== 0 ? (
                                    question_type === 'single_select_feedback' ? (
                                      <Typography variant="subtitle1">{options[0]}</Typography>
                                    ) : (
                                      <Typography variant="subtitle1">{options.length} selected</Typography>
                                    )
                                  ) : (
                                    <Typography variant="subtitle1">Select option</Typography>
                                  )
                                }
                              >
                                {properties.options &&
                                  properties.options.map(({ option_text }, i: any) => (
                                    <MenuItem
                                      key={i}
                                      value={option_text}
                                      onClick={e => editQuestionCondition('options', option_text, question_id, idx)}
                                    >
                                      <Checkbox
                                        icon={<CheckBoxOutlineBlankIcon style={{ fontSize: '1.1rem' }} />}
                                        checkedIcon={<CheckBoxIcon style={{ fontSize: '1.1rem' }} />}
                                        classes={{
                                          root: classes.check,
                                          checked: classes.checked,
                                        }}
                                        checked={options && options.includes(option_text)}
                                      />
                                      <Typography variant="subtitle1">{option_text}</Typography>
                                    </MenuItem>
                                  ))}
                              </Select>
                            )}

                          {(question_type === 'nps_feedback' ||
                            question_type === 'scale_feedback' ||
                            question_type === 'star_feedback' ||
                            question_type === 'emoji_feedback') &&
                            rule_type !== 'submit' && (
                              <TextField
                                variant="outlined"
                                className={classes.textField}
                                sx={{
                                  '& .MuiOutlinedInput-root': {
                                    fontSize: '0.87rem',
                                  },
                                }}
                                type="number"
                                style={{ marginRight: 5, flexGrow: 1 }}
                                name="option"
                                value={option}
                                onChange={e => editQuestionCondition(e.target.name, e.target.value, question_id, idx)}
                              />
                            )}

                          {question_type === 'data_collection' && rule_type !== 'submit' && (
                            <TextField
                              variant="outlined"
                              multiline
                              rows={'4'}
                              className={classes.dataField}
                              style={{ flexGrow: 1, marginLeft: 5, marginRight: 5 }}
                              value={data_array}
                              onChange={e => editQuestionCondition('data_array', e.target.value, question_id, idx)}
                            />
                          )}

                          <Box ml={'auto'}>
                            <img
                              src={deleteImg}
                              style={{ height: '40px', width: '40px' }}
                              alt=""
                              onClick={() => deleteQuestionCondition(question_id, idx)}
                            />
                          </Box>
                        </Box>

                        <Box display="flex" alignItems="flex-start" mt={1}>
                          <Typography variant="subtitle1" width="10ch" pr={1} mt={0.75}>
                            Then skip to
                          </Typography>
                          <Select
                            variant="outlined"
                            style={{ width: '80%', marginRight: 8 }}
                            className={classes.selectField}
                            value={+skip_to}
                            onChange={e =>
                              editQuestionCondition('skip_to', e.target.value.toString(), question_id, idx)
                            }
                          >
                            {questions &&
                              questions.length > 0 &&
                              questions.map((question, i) => {
                                return question.question_order > question_order ? (
                                  <MenuItem key={i} value={question.question_order}>
                                    <Box display={'flex'} alignItems="center">
                                      <Typography className={classes.selectQuestionOrder}>
                                        #{question.question_order}
                                      </Typography>
                                      <Typography variant="subtitle1" pl={0.7} maxWidth={'90%'} noWrap>
                                        {question.question_text}
                                      </Typography>
                                    </Box>
                                  </MenuItem>
                                ) : (
                                  <></>
                                );
                              })}
                            <MenuItem key={idx} value={-1}>
                              <Typography variant="subtitle1" noWrap>
                                End Survey
                              </Typography>
                            </MenuItem>
                          </Select>
                        </Box>
                      </>
                    ))}
                </Box>

                <hr style={{ borderTop: '0.1px solid #f0f0f8', marginTop: 15, marginBottom: 8 }} />
                <Box display={'flex'} alignItems={'center'}>
                  <Typography variant={'subtitle1'}>All other answers continue to</Typography>
                  <Select
                    variant="outlined"
                    style={{ width: '40%', marginRight: 8, marginLeft: 8 }}
                    className={classes.selectField}
                    value={+default_rule_skip_to}
                    onChange={e => editQuestionHandler('default_rule_skip_to', e.target.value.toString(), question_id)}
                  >
                    {questions &&
                      questions.length > 0 &&
                      questions.map((question, i) => {
                        return question.question_order > question_order ? (
                          <MenuItem key={i} value={question.question_order}>
                            <Box display={'flex'} alignItems="center">
                              <Typography className={classes.selectQuestionOrder}>
                                #{question.question_order}
                              </Typography>
                              <Typography variant="subtitle1" pl={0.7} maxWidth={'90%'} noWrap>
                                {question.question_text}
                              </Typography>
                            </Box>
                          </MenuItem>
                        ) : (
                          <></>
                        );
                      })}
                    <MenuItem value={-1}>
                      <Typography variant="subtitle1" noWrap>
                        End Survey
                      </Typography>
                    </MenuItem>
                  </Select>
                </Box>
              </>
            )}

            {activeLanguage === 'en' && (
              <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} mb={1} mt={1}>
                <Box
                  display={'flex'}
                  onClick={() => addQuestionCondition(question_type, question_order)}
                  width={'max-content'}
                >
                  <img src={conditionImg} alt="" width={15} />
                  <Typography variant={'body1'} color={'primary.contrastText'}>
                    Add conditions
                  </Typography>
                </Box>

                {(question_type === 'multi_select_feedback' ||
                  question_type === 'input_feedback' ||
                  question_type === 'data_collection') && (
                  <Box
                    display={'flex'}
                    alignItems={'center'}
                    onClick={e => {
                      editMandatoryQuestionHandler('is_mandatory', !is_mandatory, question_id);
                    }}
                  >
                    <Typography mr={0.6} color={is_mandatory ? 'success.main' : 'text.secondary'}>
                      Mandatory
                    </Typography>

                    <Switch
                      checked={is_mandatory}
                      classes={{
                        track: classes.switchTrack,
                        switchBase: classes.switchBase,
                      }}
                    />
                  </Box>
                )}
              </Box>
            )}
          </AccordionDetails>
        </Accordion>
      </Box>
    </Box>
  );
}
