import React, { FunctionComponent, useEffect, useState } from 'react';
import { Animate } from 'react-simple-animate';
import makeStyles from '@mui/styles/makeStyles';
import {
  Grid,
  Typography,
  Box,
  TextField,
  Button,
  Checkbox,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  OutlinedInput,
} from '@mui/material';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import exitImg from '../../images/demo/mobile-exit.svg';
import { ReactComponent as EmptyStarImg } from '../../images/demo/empty-star.svg';
import { ReactComponent as FillStarImg } from '../../images/demo/fill-star.svg';

import { emojisForFeedback } from '../../static/staticData';
import { optionItem, questionType, RuleItem } from '../../types/survey';
import { ISurveyTheme } from '../../types/workspace';
import { isRtl, isValidCtaURL } from '../../utils/stringUtil';
import { handleSurveyColor } from '../../utils/workspaceUtil';
import { validateDataField } from '../../utils/questionUtil';
import CheckIcon from '@mui/icons-material/Check';

const useStyles = makeStyles(theme => ({
  borderBox: {
    borderRadius: theme.spacing(1.5),
    backgroundColor: 'white',
    color: 'black',
    position: 'relative',
  },
  innerContent: {
    padding: theme.spacing(3),
    paddingTop: theme.spacing(2),
  },
  progressBarPath: {
    height: '5px',
    borderBottomLeftRadius: theme.spacing(2),
    borderBottomRightRadius: theme.spacing(2),
    position: 'absolute',
    width: '96%',
    bottom: 0,
  },
  innerProgressBar: {
    width: '15px',
    height: '5px',
    borderBottomLeftRadius: theme.spacing(2),
    borderTopRightRadius: theme.spacing(2),
    borderBottomRightRadius: theme.spacing(2),
  },
  questionImgContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingBottom: theme.spacing(1),
    marginTop: theme.spacing(1.5),
  },
  questionImg: {
    maxHeight: '200px',
    maxWidth: '100%',
    marginTop: theme.spacing(3.5),
  },
  questionText: {
    fontWeight: 700,
    fontSize: '0.9rem',
    fontFamily: 'Inter',
    lineHeight: 1.25,
    marginBottom: 15,
  },
  subTitle: {
    fontSize: '0.65rem',
    color: '#989898',
    lineHeight: 1.4,
    fontFamily: 'Inter',
    marginBottom: 15,
    textAlign: 'left',
  },
  optionText: { paddingLeft: 6, textAlign: 'left', fontSize: '0.8rem', fontFamily: 'Inter', fontWeight: 700 },
  toggleButtonGroup: { '&.MuiToggleButtonGroup-root': { display: 'flex', color: 'black' } },
  textBox: {
    borderRadius: theme.spacing(1),
  },
  inactiveSelectBox: {
    border: '1px solid #E8E8E8',
    borderRadius: 5,
    marginBottom: 5,
    cursor: 'pointer',
    padding: 8,
  },
  activeSelectBox: {
    border: '1px solid #4C69FE',
    borderRadius: 5,
    marginBottom: 5,
    cursor: 'pointer',
    padding: 8,
  },
  selectCheckbox: {
    margin: 0,
    padding: 0,
  },
  exitBtn: { position: 'absolute', top: '10px', right: '10px', cursor: 'pointer' },
  demoButton: {
    backgroundColor: '#4C69FE',
    borderRadius: 5,
    fontWeight: 600,
    fontSize: 14,
    marginTop: theme.spacing(1),
    '&:hover': {
      backgroundColor: '#4C69FE',
    },
  },
  brandText: {
    fontSize: '0.6rem',
    fontFamily: 'Inter',
    opacity: 0.5,
  },
  brandNameText: {
    fontSize: '0.6rem',
    fontWeight: 600,
    fontFamily: 'Inter',
    opacity: 0.5,
    marginLeft: 3,
  },
  introBox: {
    width: '296px',
    marginLeft: 'auto',
    marginRight: 'auto',
    bottom: '31px',
    maxHeight: '400px',
  },
  introPrompt: {
    borderBottomRightRadius: '1rem',
    borderBottomLeftRadius: '1rem',
    padding: '0.9rem 0.5rem 0.8rem 0.5rem',
    minHeight: '57px',
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
  },
  introText: {
    fontSize: '0.8rem',
    textAlign: 'left',
    lineHeight: '1.05rem',
    fontFamily: 'Inter',
    flexGrow: 1,
  },
  introCTA: {
    fontSize: '0.65rem',
    fontWeight: 600,
    padding: '6px 8px',
    marginLeft: 6,
    background: '#3F59DF',
    border: '1px solid rgba(255, 255, 255, 0.5)',
    fontFamily: 'Inter',
    display: 'inline-block',
    whiteSpace: 'nowrap',
    borderRadius: 3,
    marginTop: 5,
    marginRight: '0.6rem',
  },
  ratingFooterText: {
    opacity: 0.5,
    width: '23%',
    wordBreak: 'break-word',
    lineHeight: '13.06px',
  },
}));

type thanksCard = {
  show_thanks_card: boolean;
  thanks_card_desc: string;
  thanks_card_title: string;
};

interface IQuestion {
  nextScreen: (
    question_order: number,
    question_type: string,
    rule_sets: Array<RuleItem>,
    scale_value?: number,
    responses?: Array<optionItem>,
    survey_input?: string,
  ) => void;
  question: questionType;
  thanksCard: thanksCard;
  showCard?: boolean;
  restartDemo: () => void;
  surveyTheme: ISurveyTheme;
  platform?: string;
  showLogo: boolean;
  activeLanguage: string;
  progressBarWidth: number;
  custom_properties: {
    is_dismissible: boolean;
    show_progress_bar: boolean;
    theme_variant: string;
    cta_full_width: boolean;
  };
}

const SurveySelectComponent: FunctionComponent<IQuestion> = ({
  question,
  nextScreen,
  restartDemo,
  surveyTheme,
  showLogo,
  progressBarWidth,
  custom_properties,
  activeLanguage,
}) => {
  const classes = useStyles();
  const { is_dismissible, theme_variant, show_progress_bar, cta_full_width } = custom_properties;

  const {
    question_img,
    question_text,
    question_desc,
    properties,
    question_type,
    question_order,
    rule_sets,
    cta_text,
    is_mandatory,
    show_as_dropdown,
  } = question;
  const [selectedOption, setSelectedOption] = useState(-1);
  const theme = handleSurveyColor(theme_variant, surveyTheme);

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const [options, setOptions] = useState<Array<optionItem>>([]);
  const [survey_input, setSurveyInput] = useState('');

  const checkIfExists = (option: string, idx: number) => {
    return options.some(o => o.order === idx);
  };

  const toggleOptions = (option_text: string, _id: string | undefined, order: number, has_text_input: boolean) => {
    if (checkIfExists(option_text, order)) {
      const activeOptions = options.filter(o => o.order !== order);
      setOptions([...activeOptions]);
    } else {
      if (question_type === 'single_select_feedback') {
        setOptions([{ option_text, _id, order, has_text_input }]);
      } else {
        if (order) {
          options.push({ option_text, _id, order, has_text_input });
        }
        options.sort((a, b) => a.order - b.order);
        setOptions([...options]);
      }
    }
  };

  return (
    <Grid
      item
      lg={12}
      className={classes.borderBox}
      alignItems="center"
      style={{ backgroundColor: `${theme?.bg_color}` }}
    >
      <div>
        {is_dismissible && (
          <img
            src={exitImg}
            alt=""
            className={classes.exitBtn}
            style={{ position: 'absolute', width: '18px', height: '18px', right: 10, top: 6 }}
            onClick={() => restartDemo()}
          />
        )}

        <div className={classes.questionImgContainer}>
          {question_img && <img src={question_img} alt="" className={classes.questionImg} />}
        </div>

        <div className={classes.innerContent}>
          <Typography
            className={classes.questionText}
            style={{
              color: `${theme?.text_color}`,
              textAlign: isRtl(activeLanguage) ? 'right' : 'left',
            }}
          >
            {is_mandatory && question_type === 'multi_select_feedback' && <span style={{ color: '#FF1B1B' }}>*</span>}
            {question_text}
          </Typography>
          <Typography
            className={classes.subTitle}
            style={{
              color: `${theme?.secondary_text_color}80`,
              textAlign: isRtl(activeLanguage) ? 'right' : 'left',
            }}
          >
            {question_desc}
          </Typography>

          {!show_as_dropdown &&
            properties &&
            properties?.options &&
            properties?.options.map(({ option_text, order, has_text_input, _id }, key) => (
              <Box
                key={key}
                className={
                  order && checkIfExists(option_text, order) ? classes.activeSelectBox : classes.inactiveSelectBox
                }
                onClick={() => {
                  order && toggleOptions(option_text, '', order, has_text_input);
                }}
                style={
                  (order && checkIfExists(option_text, order)) || selectedOption === key
                    ? {
                        backgroundColor: `${theme?.selected_option_bg_color}1A`,
                        border: `1px solid ${theme?.selected_border_color}`,
                      }
                    : { backgroundColor: `${theme?.option_bg_color}CC`, border: `1px solid ${theme?.border_color}` }
                }
              >
                <Box display={'flex'}>
                  {order && (
                    <Checkbox
                      size="small"
                      className={classes.selectCheckbox}
                      sx={{
                        '& .MuiSvgIcon-root': { fontSize: 12 },
                        color: `${theme?.radio_button_color}`,
                        '&.Mui-checked': {
                          color: `${theme?.selected_radio_button_color}`,
                        },
                      }}
                      // show checkbox for single and multi select
                      checked={checkIfExists(option_text, order) || selectedOption === key}
                    />
                  )}
                  <Typography
                    className={classes.optionText}
                    style={{
                      color:
                        (order && checkIfExists(option_text, order)) || selectedOption === key
                          ? `${theme?.selected_text_color}`
                          : `${theme?.text_color}`,
                      paddingLeft: 10,
                      marginLeft: isRtl(activeLanguage) ? 'auto' : '',
                    }}
                  >
                    {option_text}
                  </Typography>
                </Box>
                {checkIfExists(option_text, order) && has_text_input && (
                  <TextField
                    fullWidth
                    multiline
                    rows={2}
                    placeholder={'Please Specify'}
                    style={{
                      backgroundColor: `${theme?.input_background_color}CC`,
                      color: `${theme?.text_color}`,
                      border: 0,
                      marginTop: 10,
                    }}
                    sx={{
                      '& .MuiInputBase-input': {
                        color: `${theme?.text_color}`,
                        textAlign: isRtl(activeLanguage) ? 'right' : 'left',
                        padding: 1,
                      },
                      '& .MuiOutlinedInput-root': {
                        padding: 0,
                        fontSize: 14,
                      },
                    }}
                    value={survey_input}
                    onChange={e => setSurveyInput(e.target.value)}
                    onClick={e => e.stopPropagation()}
                    variant="standard"
                  />
                )}
              </Box>
            ))}

          {show_as_dropdown && (
            <>
              <FormControl fullWidth>
                <Select
                  multiple
                  displayEmpty
                  open={false}
                  onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                  value={options.map(o => o.option_text)}
                  input={<OutlinedInput />}
                  renderValue={selected => {
                    if (selected.length === 0) {
                      return <Typography>Choose an option</Typography>;
                    }
                    if (question_type === 'multi_select_feedback') {
                      return `${selected.length} options selected`;
                    }
                    return selected;
                  }}
                  inputProps={{ 'aria-label': 'Without label' }}
                  style={{ textAlign: 'left' }}
                  sx={{
                    '.MuiSelect-outlined': {
                      border: `1px solid ${theme?.input_border_color} !important`,
                    },
                    '.MuiSelect-outlined::hover': {
                      border: `1px solid ${theme?.input_border_color} !important`,
                    },
                  }}
                  variant="outlined"
                />
              </FormControl>

              {isDropdownOpen && (
                <Box
                  sx={{
                    marginTop: '8px',
                    backgroundColor: `${theme?.input_background_color}`,
                    border: `1px solid ${theme?.input_border_color}40`,
                    borderRadius: '4px',
                    maxHeight: '200px',
                    overflow: 'auto',
                  }}
                >
                  <MenuItem disabled value="">
                    <Typography style={{ fontFamily: 'rustica, sans-serif', color: theme?.text_color }}>
                      {question_type === 'multi_select_feedback' ? 'Choose options' : 'Choose an option'}
                    </Typography>
                  </MenuItem>
                  {properties?.options.map(item => (
                    <MenuItem
                      key={item._id}
                      value={item.option_text}
                      onClick={() => {
                        toggleOptions(item.option_text, item._id, item.order, item.has_text_input);
                        if (!question_type.includes('multi')) {
                          setIsDropdownOpen(false);
                        }
                      }}
                      style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}
                      sx={{
                        '&.Mui-selected': {
                          backgroundColor: `${theme?.selected_option_bg_color}1A !important`,
                          '&:hover': {
                            backgroundColor: `${theme?.selected_option_bg_color}3A !important`,
                          },
                        },
                      }}
                      selected={options.map(o => o.option_text).includes(item.option_text)}
                    >
                      <Box display="flex">
                        {question_type === 'multi_select_feedback' && (
                          <Checkbox checked={options.map(o => o.option_text).includes(item.option_text)} />
                        )}
                        <Typography
                          style={{ fontFamily: 'rustica, sans-serif', marginTop: 6, color: theme?.text_color }}
                        >
                          {item.option_text}
                        </Typography>
                      </Box>
                      {checkIfExists(item.option_text, item.order) && item.has_text_input && (
                        <TextField
                          fullWidth
                          multiline
                          rows={2}
                          placeholder={'Please Specify'}
                          style={{
                            backgroundColor: `${theme?.input_background_color}CC`,
                            color: `${theme?.text_color}`,
                            border: 0,
                            marginTop: 10,
                          }}
                          sx={{
                            '& .MuiInputBase-input': {
                              color: `${theme?.text_color}`,
                              textAlign: isRtl(activeLanguage) ? 'right' : 'left',
                              padding: 1,
                            },
                            '& .MuiOutlinedInput-root': {
                              padding: 0,
                              fontSize: 14,
                            },
                          }}
                          value={survey_input}
                          onChange={e => setSurveyInput(e.target.value)}
                          onClick={e => e.stopPropagation()}
                          variant="standard"
                        />
                      )}
                    </MenuItem>
                  ))}
                </Box>
              )}
            </>
          )}

          <Box textAlign="center" mt={1}>
            {(question_type === 'multi_select_feedback' ||
              (question_type === 'single_select_feedback' && options.length > 0)) && (
              <Button
                variant="contained"
                className={classes.demoButton}
                disabled={
                  (is_mandatory && options.length === 0) ||
                  (options.some(o => o.has_text_input) &&
                    (survey_input.length === 0 || survey_input.trim().length === 0))
                }
                onClick={() => {
                  nextScreen(question_order, question_type, rule_sets, undefined, options);
                  setOptions([]);
                  setSelectedOption(-1);
                }}
                style={{
                  backgroundColor:
                    is_mandatory && options.length === 0 ? `${theme?.highlight_color}80` : `${theme?.highlight_color}`,
                  width: cta_full_width ? '100%' : '',
                }}
              >
                <Typography
                  style={{
                    fontSize: '0.85rem',
                    fontWeight: 600,
                    color:
                      is_mandatory && options.length === 0 ? `${theme?.cta_text_color}80` : `${theme?.cta_text_color}`,
                  }}
                >
                  {cta_text}
                </Typography>
              </Button>
            )}
          </Box>

          <Box display="flex" mt={2} justifyContent="center" alignItems="center">
            {showLogo && (
              <>
                <Typography className={classes.brandText} style={{ color: `${theme?.text_color}` }}>
                  powered by{' '}
                </Typography>{' '}
                <Typography className={classes.brandNameText} style={{ color: `${theme?.text_color}` }}>
                  Blitzllama
                </Typography>
              </>
            )}
          </Box>
        </div>
      </div>
      {show_progress_bar && (
        <Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
          <div className={classes.progressBarPath} style={{ backgroundColor: `${theme?.highlight_color}26` }}>
            <div
              className={classes.innerProgressBar}
              style={{ width: `${progressBarWidth}%`, backgroundColor: `${theme?.highlight_color}` }}
            ></div>
          </div>
        </Box>
      )}
    </Grid>
  );
};

const SurveyRatingComponent: FunctionComponent<IQuestion> = ({
  question,
  nextScreen,
  restartDemo,
  surveyTheme,
  platform,
  showLogo,
  progressBarWidth,
  custom_properties,
  activeLanguage,
}) => {
  const classes = useStyles();

  const {
    question_img,
    question_text,
    question_desc,
    properties,
    question_type,
    question_order,
    rule_sets,
    question_variant,
  } = question;
  const [selectedValue, setSelectedValue] = useState(-1);

  let toggleData = [1, 2, 3, 4, 5];
  let width = '20.25%';

  if (question_type === 'nps_feedback') {
    toggleData = [0, ...toggleData, 6, 7, 8, 9, 10];
    width = '10%';
  }

  const { is_dismissible, theme_variant, show_progress_bar } = custom_properties;
  const theme = handleSurveyColor(theme_variant, surveyTheme);

  // get rating box background color
  const getBoxColor = (rating_value: number) => {
    if (!platform) return;

    // for nps in link surveys, if question variant is colourised
    if (
      (platform === 'link' || platform === 'hybrid') &&
      question_type === 'nps_feedback' &&
      question_variant === 'colourised'
    ) {
      if (rating_value >= 0 && rating_value < 7) {
        return '#FF8E8E';
      } else if (rating_value === 7 || rating_value === 8) {
        return '#FFE177';
      } else if (rating_value === 9 || rating_value === 10) {
        return '#6DFFB0';
      }
    }
    // for default nps and opinion scale
    else if (selectedValue === rating_value) {
      return `${theme?.selected_range_higlight_color}0D`;
    }

    return `${theme?.range_background_color}`;
  };

  return (
    <Grid item lg={12} className={classes.borderBox} style={{ backgroundColor: `${theme?.bg_color}` }}>
      <div>
        {is_dismissible && (
          <img
            src={exitImg}
            alt=""
            className={classes.exitBtn}
            style={{ position: 'absolute', width: '18px', height: '18px', right: 10, top: 6 }}
            onClick={() => restartDemo()}
          />
        )}
        <div className={classes.questionImgContainer}>
          {question_img && <img src={question_img} alt="" className={classes.questionImg} />}
        </div>

        <div className={classes.innerContent}>
          <Typography
            variant="h2"
            className={classes.questionText}
            style={{
              color: `${theme?.text_color}`,
              textAlign: isRtl(activeLanguage) ? 'right' : 'left',
            }}
          >
            {question_text}
          </Typography>
          <Typography
            className={classes.subTitle}
            style={{
              color: `${theme?.secondary_text_color}80`,
              textAlign: isRtl(activeLanguage) ? 'right' : 'left',
            }}
          >
            {question_desc}
          </Typography>
          <ToggleButtonGroup size="large" style={{ marginTop: 20 }} className={classes.toggleButtonGroup} exclusive>
            {toggleData.map((option, key) => (
              <ToggleButton
                key={key}
                value={option}
                style={{
                  width: width,
                  borderColor: `${theme?.range_border_color}`,
                  color: 'black',
                  padding: 5,
                  paddingTop: 10,
                  paddingBottom: 10,
                  backgroundColor: getBoxColor(option),
                }}
                onClick={() => {
                  setSelectedValue(option);
                  setTimeout(() => {
                    nextScreen(question_order, question_type, rule_sets, option);
                    setSelectedValue(-1);
                  }, 500);
                }}
              >
                <Typography
                  variant="h4"
                  style={{
                    fontSize: '0.8rem',
                    color: selectedValue === option ? `${theme.selected_range_higlight_color}` : `${theme?.text_color}`,
                  }}
                >
                  {option}
                </Typography>
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
          <Box display="flex" mt={1} justifyContent="space-between">
            <Typography
              variant="subtitle2"
              className={classes.ratingFooterText}
              style={{ color: `${theme?.text_color}`, textAlign: 'left' }}
            >
              {properties?.labels?.left}
            </Typography>
            <Typography
              variant="subtitle2"
              className={classes.ratingFooterText}
              style={{
                color: `${theme?.text_color}`,
                textAlign: 'right',
              }}
            >
              {properties?.labels?.right}
            </Typography>
          </Box>
          <Box display="flex" mt={2} justifyContent="center" alignItems="center">
            {showLogo && (
              <>
                <Typography className={classes.brandText} style={{ color: `${theme?.text_color}` }}>
                  powered by{' '}
                </Typography>{' '}
                <Typography className={classes.brandNameText} style={{ color: `${theme?.text_color}` }}>
                  Blitzllama
                </Typography>
              </>
            )}
          </Box>
        </div>
      </div>
      {show_progress_bar && (
        <Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
          <div className={classes.progressBarPath} style={{ backgroundColor: `${theme?.highlight_color}26` }}>
            <div
              className={classes.innerProgressBar}
              style={{ width: `${progressBarWidth}%`, backgroundColor: `${theme?.highlight_color}` }}
            ></div>
          </div>
        </Box>
      )}
    </Grid>
  );
};

const SurveyInputComponent: FunctionComponent<IQuestion> = ({
  question,
  nextScreen,
  restartDemo,
  surveyTheme,
  showLogo,
  activeLanguage,
  progressBarWidth,
  custom_properties,
}) => {
  const classes = useStyles();
  const [survey_input, setSurveyInput] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const {
    question_img,
    question_text,
    question_desc,
    question_type,
    cta_text,
    cta_link,
    question_order,
    rule_sets,
    is_mandatory,
    placeholder,
    validations,
  } = question;

  const { is_dismissible, theme_variant, show_progress_bar, cta_full_width } = custom_properties;
  const theme = handleSurveyColor(theme_variant, surveyTheme);

  const handleInputSubmit = () => {
    if (question_type === 'data_collection') {
      const { goto_next, message } = validateDataField(survey_input, validations, is_mandatory);

      if (!goto_next) {
        setErrorMessage(message);
        setTimeout(() => {
          setErrorMessage('');
        }, 3000);

        return;
      }
    }
    nextScreen(question_order, question_type, rule_sets, undefined, undefined, survey_input);
    setSurveyInput('');
  };

  const handleCtaLink = () => {
    if (!cta_link || cta_link.trim().length === 0) {
      return;
    }

    if (!isValidCtaURL(cta_link.trim())) {
      return;
    }

    let primary_link = '';
    if (cta_link.includes('http://') || cta_link.includes('https://')) {
      primary_link = cta_link;
    } else {
      primary_link = 'https://' + cta_link;
    }

    window.open(primary_link, '_blank');
  };

  return (
    <Grid
      item
      lg={12}
      className={classes.borderBox}
      alignItems="center"
      style={{ backgroundColor: `${theme?.bg_color}` }}
    >
      <div>
        {is_dismissible && (
          <img
            src={exitImg}
            alt=""
            className={classes.exitBtn}
            style={{ position: 'absolute', width: '18px', height: '18px', right: 10, top: 6 }}
            onClick={() => restartDemo()}
          />
        )}
        <div className={classes.questionImgContainer}>
          {question_img && <img src={question_img} alt="" className={classes.questionImg} />}
        </div>

        <div className={classes.innerContent}>
          <Typography
            className={classes.questionText}
            style={{
              color: `${theme?.text_color}`,
              textAlign: isRtl(activeLanguage) ? 'right' : 'left',
            }}
          >
            {is_mandatory && <span style={{ color: '#FF1B1B' }}>*</span>}
            {question_text}
          </Typography>
          <Typography
            className={classes.subTitle}
            style={{
              color: `${theme?.secondary_text_color}80`,
              textAlign: isRtl(activeLanguage) ? 'right' : 'left',
            }}
          >
            {question_desc}
          </Typography>
          <Box mt={2} textAlign="center">
            {(question_type === 'input_feedback' || question_type === 'data_collection') && (
              <TextField
                fullWidth
                multiline
                rows={question_type === 'data_collection' ? 2 : 3}
                placeholder={placeholder}
                className={classes.textBox}
                style={{
                  backgroundColor: `${theme?.input_background_color}CC`,
                  color: `${theme?.text_color}`,
                }}
                sx={{
                  '& .MuiInputBase-input': {
                    color: `${theme?.text_color}`,
                    textAlign: isRtl(activeLanguage) ? 'right' : 'left',
                  },
                  '& .MuiOutlinedInput-root': {
                    border: `1px solid ${theme?.input_border_color}80`,
                  },
                }}
                value={survey_input}
                onChange={e => setSurveyInput(e.target.value)}
              />
            )}
            <Button
              variant="contained"
              disabled={is_mandatory && survey_input.length === 0}
              style={{
                marginTop: 15,
                backgroundColor:
                  is_mandatory && survey_input.length === 0
                    ? `${theme?.highlight_color}80`
                    : `${theme?.highlight_color}`,
                width: cta_full_width ? '100%' : '',
              }}
              className={classes.demoButton}
              onClick={
                question_type === 'intro_prompt'
                  ? () => {
                      handleCtaLink();
                      handleInputSubmit();
                    }
                  : () => handleInputSubmit()
              }
            >
              <Typography
                style={{
                  fontSize: '0.85rem',
                  fontWeight: 600,
                  color:
                    is_mandatory && survey_input.length === 0
                      ? `${theme?.cta_text_color}80`
                      : `${theme?.cta_text_color}`,
                }}
              >
                {cta_text}
              </Typography>
            </Button>
          </Box>
          {errorMessage && (
            <Typography textAlign={'left'} variant="subtitle2" color="error.main">
              {errorMessage}
            </Typography>
          )}
          <Box display="flex" mt={2} justifyContent="center" alignItems="center">
            {showLogo && (
              <>
                <Typography className={classes.brandText} style={{ color: `${theme?.text_color}` }}>
                  powered by{' '}
                </Typography>{' '}
                <Typography className={classes.brandNameText} style={{ color: `${theme?.text_color}` }}>
                  Blitzllama
                </Typography>
              </>
            )}
          </Box>
        </div>
      </div>
      {show_progress_bar && (
        <Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
          <div className={classes.progressBarPath} style={{ backgroundColor: `${theme?.highlight_color}26` }}>
            <div
              className={classes.innerProgressBar}
              style={{ width: `${progressBarWidth}%`, backgroundColor: `${theme?.highlight_color}` }}
            ></div>
          </div>
        </Box>
      )}
    </Grid>
  );
};

const SurveyStarComponent: FunctionComponent<IQuestion> = ({
  question,
  nextScreen,
  restartDemo,
  surveyTheme,
  showLogo,
  progressBarWidth,
  custom_properties,
  activeLanguage,
}) => {
  const classes = useStyles();
  const {
    question_img,
    question_text,
    question_desc,
    question_type,
    question_order,
    rule_sets,
    question_id,
  } = question;

  const width = '20%';
  const stars = [1, 2, 3, 4, 5];

  const [currentStarValue, setCurrentStarValue] = useState(-1);
  const [activeEmoji, setActiveEmoji] = useState(false);
  const { is_dismissible, theme_variant, show_progress_bar } = custom_properties;
  const theme = handleSurveyColor(theme_variant, surveyTheme);

  useEffect(() => {
    setCurrentStarValue(-1);
    setActiveEmoji(false);
  }, [question_id]);

  return (
    <Grid item lg={12} className={classes.borderBox} style={{ backgroundColor: `${theme?.bg_color}` }}>
      <div>
        {is_dismissible && (
          <img
            src={exitImg}
            alt=""
            className={classes.exitBtn}
            style={{ position: 'absolute', width: '18px', height: '18px', right: 10, top: 6 }}
            onClick={() => restartDemo()}
          />
        )}
        <div className={classes.questionImgContainer}>
          {question_img && <img src={question_img} alt="" className={classes.questionImg} />}
        </div>

        <div className={classes.innerContent}>
          <Typography
            variant="h2"
            className={classes.questionText}
            style={{
              color: `${theme?.text_color}`,
              textAlign: isRtl(activeLanguage) ? 'right' : 'left',
            }}
          >
            {question_text}
          </Typography>
          <Typography
            className={classes.subTitle}
            style={{
              color: `${theme?.secondary_text_color}80`,
              textAlign: isRtl(activeLanguage) ? 'right' : 'left',
            }}
          >
            {question_desc}
          </Typography>

          {question_type === 'star_feedback' ? (
            <Box display="flex" alignItems={'center'} justifyContent="center" mt={2.5}>
              {stars.map((option, idx) => (
                <div
                  key={idx}
                  style={{
                    width: width,
                    padding: 5,
                    paddingBlock: 10,
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    setCurrentStarValue(idx + 1);
                    setTimeout(() => {
                      nextScreen(question_order, question_type, rule_sets, option);
                    }, 500);
                  }}
                >
                  {currentStarValue > idx ? <FillStarImg /> : <EmptyStarImg />}
                </div>
              ))}
            </Box>
          ) : (
            <Box display="flex" alignItems={'center'} justifyContent="center" mt={2.5}>
              {emojisForFeedback.map(({ value, icon }, idx) => (
                <div
                  key={idx}
                  style={{
                    width: width,
                    padding: 5,
                    paddingBlock: 10,
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    setActiveEmoji(true);
                    setCurrentStarValue(value);
                    setTimeout(() => {
                      nextScreen(question_order, question_type, rule_sets, value);
                    }, 500);
                  }}
                >
                  <Animate
                    play={activeEmoji && currentStarValue === value}
                    duration={0.5}
                    start={{ transform: 'scale(1)' }}
                    end={{ transform: 'scale(1.3)' }}
                  >
                    <img src={icon} alt="" style={activeEmoji && currentStarValue !== value ? { opacity: 0.3 } : {}} />
                  </Animate>
                </div>
              ))}
            </Box>
          )}

          <Box display="flex" mt={2} justifyContent="center" alignItems="center">
            {showLogo && (
              <>
                <Typography className={classes.brandText} style={{ color: `${theme?.text_color}` }}>
                  powered by{' '}
                </Typography>{' '}
                <Typography className={classes.brandNameText} style={{ color: `${theme?.text_color}` }}>
                  Blitzllama
                </Typography>
              </>
            )}
          </Box>
        </div>
      </div>
      {show_progress_bar && (
        <Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
          <div className={classes.progressBarPath} style={{ backgroundColor: `${theme?.highlight_color}26` }}>
            <div
              className={classes.innerProgressBar}
              style={{ width: `${progressBarWidth}%`, backgroundColor: `${theme?.highlight_color}` }}
            ></div>
          </div>
        </Box>
      )}
    </Grid>
  );
};

const ThanksCardComponent: FunctionComponent<{
  restartDemo;
  thanksCard;
  surveyTheme: ISurveyTheme;
  showLogo: boolean;
  custom_properties: any;
}> = ({ thanksCard, surveyTheme, restartDemo, showLogo, custom_properties }) => {
  const classes = useStyles();

  const { thanks_card_desc, thanks_card_title, show_thanks_card } = thanksCard;
  const { is_dismissible, theme_variant } = custom_properties;
  const theme = handleSurveyColor(theme_variant, surveyTheme);

  return (
    show_thanks_card && (
      <Grid
        item
        lg={12}
        className={classes.borderBox}
        alignItems="center"
        justifyContent="center"
        style={{
          textAlign: 'center',
          backgroundColor: `${theme?.bg_color}`,
          padding: '24px',
          paddingTop: '50px',
        }}
      >
        {is_dismissible && (
          <img
            src={exitImg}
            alt=""
            className={classes.exitBtn}
            style={{ position: 'absolute', width: '18px', height: '18px', right: 10, top: 6 }}
            onClick={() => restartDemo()}
          />
        )}
        <div>
          <div style={{ height: '37px' }}>
            <img src={'https://cdn.blitzllama.com/survey/assets/green-tick.svg'} alt="" />
          </div>

          <Typography
            variant="h2"
            style={{ wordWrap: 'break-word', fontSize: '0.9rem', color: `${theme?.text_color}`, marginTop: '10px' }}
          >
            {thanks_card_title}
          </Typography>
          <Typography
            variant="body2"
            style={{ textAlign: 'center', marginTop: 5, fontSize: '0.8rem', color: `${theme?.secondary_text_color}80` }}
          >
            {thanks_card_desc}
          </Typography>

          <Box display="flex" mt={2} justifyContent="center" alignItems="center">
            {showLogo && (
              <>
                <Typography className={classes.brandText} style={{ color: `${theme?.text_color}` }}>
                  powered by{' '}
                </Typography>{' '}
                <Typography className={classes.brandNameText} style={{ color: `${theme?.text_color}` }}>
                  Blitzllama
                </Typography>
              </>
            )}
          </Box>
        </div>
      </Grid>
    )
  );
};

const SurveyMatrixComponent: FunctionComponent<IQuestion> = ({
  question,
  nextScreen,
  restartDemo,
  surveyTheme,
  showLogo,
  progressBarWidth,
  custom_properties,
  activeLanguage,
}) => {
  const classes = useStyles();
  const { is_dismissible, theme_variant, show_progress_bar, cta_full_width } = custom_properties;

  const {
    question_img,
    question_text,
    question_desc,
    properties,
    question_type,
    question_order,
    rule_sets,
    cta_text,
    is_mandatory,
  } = question;

  const [selectedRow, setSelectedRow] = useState<number | null>(null);
  const [selectedColumn, setSelectedColumn] = useState<number | null>(null);
  const theme = handleSurveyColor(theme_variant, surveyTheme);

  const handleMatrixSelection = (rowIndex: number, colIndex: number) => {
    setSelectedRow(rowIndex);
    setSelectedColumn(colIndex);
  };

  const handleSubmit = () => {
    if (selectedRow !== null && selectedColumn !== null) {
      const selectedOption = properties.options[selectedRow];
      const selectedColumnData = properties?.matrix_columns?.[selectedColumn];

      const responses = [
        {
          option_text: selectedOption.option_text,
          option_id: selectedOption._id || `option-${selectedRow}`,
          order: selectedOption.order || selectedRow,
          has_text_input: selectedOption.has_text_input || false,
          matrix_column: selectedColumn,
          matrix_column_text: selectedColumnData?.value,
        },
      ];

      nextScreen(question_order, question_type, rule_sets || [], undefined, responses);
      setSelectedRow(null);
      setSelectedColumn(null);
    }
  };

  return (
    <Grid
      item
      lg={12}
      className={classes.borderBox}
      alignItems="center"
      style={{ backgroundColor: `${theme?.bg_color}` }}
    >
      <div>
        {is_dismissible && (
          <img
            src={exitImg}
            alt=""
            className={classes.exitBtn}
            style={{ position: 'absolute', width: '18px', height: '18px', right: 10, top: 6 }}
            onClick={() => restartDemo()}
          />
        )}

        <div className={classes.questionImgContainer}>
          {question_img && <img src={question_img} alt="" className={classes.questionImg} />}
        </div>

        <div className={classes.innerContent}>
          <Typography
            className={classes.questionText}
            style={{
              color: `${theme?.text_color}`,
              textAlign: isRtl(activeLanguage) ? 'right' : 'left',
            }}
          >
            {is_mandatory && <span style={{ color: '#FF1B1B' }}>*</span>}
            {question_text}
          </Typography>

          <Typography
            className={classes.subTitle}
            style={{
              color: `${theme?.secondary_text_color}80`,
              textAlign: isRtl(activeLanguage) ? 'right' : 'left',
            }}
          >
            {question_desc}
          </Typography>

          <Box mt={2} overflow={'scroll'}>
            <table style={{ width: '100%', overflow: 'hidden', borderSpacing: '0 4px' }}>
              <thead>
                <tr>
                  <th style={{ width: '30%', padding: '8px', textAlign: 'left' }}></th>
                  {properties.matrix_columns &&
                    properties.matrix_columns.map((column, i) => (
                      <th
                        key={i}
                        style={{
                          padding: '8px',
                          textAlign: 'center',
                          fontSize: '14px',
                          color: `${theme?.text_color}`,
                        }}
                      >
                        <Typography fontSize={12}>{column.value || `Column ${i + 1}`}</Typography>
                      </th>
                    ))}
                </tr>
              </thead>
              <tbody>
                {properties.options.map((option, rowIndex) => (
                  <tr key={rowIndex} style={{ backgroundColor: `${theme.option_bg_color}80` }}>
                    <td
                      style={{
                        padding: '8px',
                        fontWeight: 500,
                        fontSize: '12px',
                        color: `${theme?.text_color}`,
                      }}
                    >
                      <Typography fontSize={14}>{option.option_text || `Row ${rowIndex + 1}`}</Typography>
                    </td>
                    {properties.matrix_columns &&
                      properties.matrix_columns.map((_, colIndex) => (
                        <td key={colIndex} style={{ padding: '8px', textAlign: 'center' }}>
                          <div
                            style={{
                              width: '16px',
                              height: '16px',
                              borderRadius: '50%',
                              border: `2px solid ${
                                selectedRow === rowIndex && selectedColumn === colIndex
                                  ? theme?.selected_border_color
                                  : theme?.border_color
                              }`,
                              backgroundColor:
                                selectedRow === rowIndex && selectedColumn === colIndex
                                  ? `${theme?.highlight_color}`
                                  : theme?.bg_color,
                              margin: '0 auto',
                              cursor: 'pointer',
                            }}
                            onClick={() => handleMatrixSelection(rowIndex, colIndex)}
                          />
                        </td>
                      ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </Box>

          <Box textAlign="center" mt={2}>
            <Button
              variant="contained"
              className={classes.demoButton}
              disabled={selectedRow === null || selectedColumn === null}
              onClick={handleSubmit}
              style={{
                backgroundColor:
                  selectedRow === null || selectedColumn === null
                    ? `${theme?.highlight_color}80`
                    : `${theme?.highlight_color}`,
                width: cta_full_width ? '100%' : '',
              }}
            >
              <Typography
                style={{
                  fontSize: '0.85rem',
                  fontWeight: 600,
                  color:
                    selectedRow === null || selectedColumn === null
                      ? `${theme?.cta_text_color}80`
                      : `${theme?.cta_text_color}`,
                }}
              >
                {cta_text}
              </Typography>
            </Button>
          </Box>

          <Box display="flex" mt={2} justifyContent="center" alignItems="center">
            {showLogo && (
              <>
                <Typography className={classes.brandText} style={{ color: `${theme?.text_color}` }}>
                  powered by{' '}
                </Typography>{' '}
                <Typography className={classes.brandNameText} style={{ color: `${theme?.text_color}` }}>
                  Blitzllama
                </Typography>
              </>
            )}
          </Box>
        </div>
      </div>
      {show_progress_bar && (
        <Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
          <div className={classes.progressBarPath} style={{ backgroundColor: `${theme?.highlight_color}26` }}>
            <div
              className={classes.innerProgressBar}
              style={{ width: `${progressBarWidth}%`, backgroundColor: `${theme?.highlight_color}` }}
            ></div>
          </div>
        </Box>
      )}
    </Grid>
  );
};

export const SurveyQuestionHandler = ({
  thanksCard,
  question,
  nextScreen,
  showCard,
  restartDemo,
  surveyTheme,
  platform,
  activeLanguage,
  showLogo,
  progressBarWidth,
  custom_properties,
}: IQuestion) => {
  if (showCard)
    return (
      <ThanksCardComponent
        restartDemo={restartDemo}
        thanksCard={thanksCard}
        surveyTheme={surveyTheme}
        showLogo={showLogo}
        custom_properties={custom_properties}
      />
    );
  else {
    const { question_type } = question;
    switch (question_type) {
      case 'intro_prompt':
      case 'input_feedback':
      case 'data_collection':
        return (
          <SurveyInputComponent
            question={question}
            nextScreen={nextScreen}
            thanksCard={thanksCard}
            restartDemo={restartDemo}
            surveyTheme={surveyTheme}
            showLogo={showLogo}
            activeLanguage={activeLanguage}
            progressBarWidth={progressBarWidth}
            custom_properties={custom_properties}
          />
        );
      case 'nps_feedback':
      case 'scale_feedback':
        return (
          <SurveyRatingComponent
            question={question}
            nextScreen={nextScreen}
            thanksCard={thanksCard}
            restartDemo={restartDemo}
            surveyTheme={surveyTheme}
            platform={platform}
            showLogo={showLogo}
            activeLanguage={activeLanguage}
            progressBarWidth={progressBarWidth}
            custom_properties={custom_properties}
          />
        );
      case 'multi_select_feedback':
      case 'single_select_feedback':
        return (
          <SurveySelectComponent
            question={question}
            nextScreen={nextScreen}
            thanksCard={thanksCard}
            restartDemo={restartDemo}
            surveyTheme={surveyTheme}
            showLogo={showLogo}
            activeLanguage={activeLanguage}
            progressBarWidth={progressBarWidth}
            custom_properties={custom_properties}
          />
        );
      case 'star_feedback':
      case 'emoji_feedback':
        return (
          <SurveyStarComponent
            question={question}
            nextScreen={nextScreen}
            thanksCard={thanksCard}
            restartDemo={restartDemo}
            surveyTheme={surveyTheme}
            showLogo={showLogo}
            activeLanguage={activeLanguage}
            progressBarWidth={progressBarWidth}
            custom_properties={custom_properties}
          />
        );
      case 'matrix_feedback':
        return (
          <SurveyMatrixComponent
            question={question}
            nextScreen={nextScreen}
            thanksCard={thanksCard}
            restartDemo={restartDemo}
            surveyTheme={surveyTheme}
            showLogo={showLogo}
            activeLanguage={activeLanguage}
            progressBarWidth={progressBarWidth}
            custom_properties={custom_properties}
          />
        );
      default:
        return <></>;
    }
  }
};
