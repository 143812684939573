import { createTheme, adaptV4Theme } from '@mui/material';
import { lightTheme } from './colors';

export const dark = () =>
  createTheme(
    adaptV4Theme({
      palette: {
        mode: 'dark',
        divider: lightTheme.border,
        background: {
          default: lightTheme.background,
          paper: lightTheme.drawerBackground,
        },
        primary: {
          main: lightTheme.formBackground,
          dark: lightTheme.dark,
          contrastText: lightTheme.contrastText,
          light: lightTheme.formBorder,
        },
        secondary: {
          main: lightTheme.drawerBackground,
          dark: lightTheme.activeNavBackgroud,
          contrastText: lightTheme.tableBackground,
          light: lightTheme.lightBackground,
        },
        text: {
          primary: lightTheme.dark,
          secondary: lightTheme.formText,
          disabled: lightTheme.disabledText,
        },
        success: {
          main: lightTheme.successGreen,
          contrastText: lightTheme.lightGreen,
        },
        warning: {
          main: lightTheme.lightOrange,
          dark: lightTheme.darkOrange,
        },
        error: {
          main: lightTheme.dangerRed,
        },
        info: {
          main: lightTheme.notificationBg,
          light: lightTheme.lightGrey,
          dark: lightTheme.selectBackgrond,
          contrastText: lightTheme.highlightPurple,
        },
      },
      typography: {
        fontFamily: 'rustica,sans-serif',
        h2: {
          fontWeight: 700,
          fontSize: '1.5rem',
        },
        h4: {
          fontWeight: 700,
          fontSize: '1.125rem',
        },
        h6: {
          fontSize: '1rem',
          fontWeight: 700,
        },
        body1: {
          fontSize: '1rem',
          fontWeight: 400,
        },
        subtitle1: {
          fontSize: '0.87rem',
          fontWeight: 400,
        },
        subtitle2: {
          fontSize: '0.75rem',
          fontWeight: 400,
        },
      },
      overrides: {
        MuiSnackbarContent: {
          root: {
            minWidth: '50px !important',
          },
        },
        MuiButton: {
          root: {
            color: lightTheme.dark,
          },
          contained: {
            backgroundColor: lightTheme.contrastText,
            color: lightTheme.background,
            padding: '0.5rem 1rem 0.55rem 1rem',
            fontSize: '0.875rem',
            fontWeight: 700,
            borderRadius: '0.3rem',
            textTransform: 'none',
            boxShadow: 'none',
            '&:hover': {
              color: lightTheme.background,
              backgroundColor: lightTheme.contrastText,
              boxShadow: 'none',
            },
            '&:disabled': {
              backgroundColor: lightTheme.contrastText,
              opacity: 0.5,
              img: {
                opacity: 0.5,
              },
            },
          },
          outlined: {
            padding: '0.56rem 1rem 0.5rem 0.9rem',
            borderRadius: '0.3rem',
            border: 0,
            backgroundColor: lightTheme.dark,
            fontSize: '0.875rem',
            textTransform: 'none',
            fontWeight: 700,
            color: lightTheme.background,
            '&:hover': {
              backgroundColor: lightTheme.dark,
              color: lightTheme.background,
              border: 0,
            },
          },
        },
        MuiInput: {
          formControl: {
            color: lightTheme.formText,
          },
        },
        MuiSelect: {
          outlined: {
            backgroundColor: lightTheme.background,
            color: lightTheme.dark,
            padding: '0.75rem 0.9rem',
            borderRadius: '0.3rem',

            '&:focus': {
              border: 0,
              borderRadius: '0.3rem',
            },
          },

          select: {
            '&:focus': {
              backgroundColor: 'none',
              borderRadius: 10,
              border: 0,
            },
          },
        },

        MuiDrawer: {
          paper: {
            backgroundColor: lightTheme.drawerBackground,
          },
        },
        MuiListItem: {
          gutters: {
            paddingLeft: '1rem',
          },
          root: {
            '&$selected': {
              backgroundColor: '#18181d',
              color: lightTheme.contrastText,
            },
            fontWeight: 400,
          },
        },
        MuiListItemButton: {
          root: {
            '&$selected': {
              backgroundColor: '#18181d',
              color: lightTheme.contrastText,
            },
          },
        },
        MuiListItemIcon: {
          root: {
            minWidth: '2.2rem',
            fontFamily: 'rustica,sans-serif',
          },
        },
        MuiListItemText: {
          primary: {
            '&$selected': {
              fontWeight: 400,
              color: lightTheme.contrastText,
            },
          },
        },
        MuiMenuItem: {
          root: {
            fontSize: '1rem',
            paddingBlock: '5px',
            paddingLeft: '1.5rem',
            paddingRight: '1.5rem',
            '&:hover': {
              backgroundColor: 'rgba(0,0,0,0.05)',
            },
          },
        },

        MuiIconButton: {
          root: {
            padding: 0,
            color: lightTheme.dark,
          },
        },
        MuiSvgIcon: {
          root: {
            fontSize: '1.3rem',
            padding: 0,
          },
        },
        MuiTextField: {
          root: {
            '& label.Mui-focused': {
              color: lightTheme.background,
            },
            '& .MuiInput-underline:after': {
              border: 0,
            },
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                border: 0,
              },
              '&:hover fieldset': {
                border: 0,
              },
              '&.Mui-focused fieldset': {
                border: 0,
              },
              '& .MuiSvgIcon-root': {
                color: lightTheme.formText,
              },
            },
            // padding: '0.75rem 0.87rem',
            border: `1px solid ${lightTheme.formBorder}`,
            borderRadius: '0.3rem',
            backgroundColor: lightTheme.formBackground,
          },
        },
        MuiAutocomplete: {
          root: {
            '& .MuiOutlinedInput-root .MuiAutocomplete-input': {
              padding: 0,
              '& .MuiAutocomplete-root .MuiAutocomplete-tag': {
                border: '1px solid red',
              },
            },
          },
        },
        MuiFormHelperText: {
          root: {
            marginTop: 0,
            textAlign: 'left',
          },
          contained: {
            backgroundColor: lightTheme.drawerBackground,
            marginLeft: 0,
            marginRight: 0,
            textAlign: 'center',
          },
        },

        MuiTable: {
          root: {
            '& .MuiTableRow-head': {
              fontSize: '0.9rem',
            },

            '& .MuiTableCell-root': {
              borderBottom: 'none',
            },
            '& .MuiTableHead-root': {
              borderTop: 'none',
            },
            '& .MuiTableCell-head': {
              fontSize: '1rem',
              fontWeight: 700,
              paddingTop: 0,
            },

            '& .MuiTableCell-body': {
              fontSize: '0.87rem',
              padding: '12px 15px',
              borderTop: `1px solid ${lightTheme.border}`,
            },
          },
        },
        MuiTabs: {
          indicator: {
            backgroundColor: lightTheme.contrastText,
            height: 2,
            borderRadius: 100,
          },
        },
        MuiList: {
          root: {
            maxHeight: 500,
          },
        },
        MuiPaper: {
          root: {
            backgroundImage: 'none',
            backgroundColor: lightTheme.background,
            boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.05)',
            border: `1px solid ${lightTheme.border}`,
          },
        },
        MuiAccordion: {
          root: {
            '& .MuiAccordionSummary-content': {
              margin: 0,
              padding: 0,
            },
            '& .MuiAccordionSummary-root': {
              padding: 0,
            },
            '& .MuiAccordionSummary-content.Mui-expanded': {
              margin: 0,
            },
            '& .MuiAccordionSummary-root.Mui-expanded': {
              minHeight: 0,
              paddingTop: '0.5rem',
            },
            '&.MuiAccordion-root:before': {
              backgroundColor: 'transparent',
            },
          },
        },
        MuiRadio: {
          root: {
            padding: 0,
            paddingInline: '5px',
          },
        },
      },
    }),
  );
