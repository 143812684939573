import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

export const useStyles = makeStyles((theme: Theme) =>
         createStyles({
           root: {
             flexGrow: 1,
             paddingBlock: theme.spacing(5),
             display: 'flex',
             justifyContent: 'center',
           },
           warningBox: {
             cursor: 'pointer',
             borderRadius: theme.spacing(1),
             marginTop: 20,
             background: '#FFC10714',
             color: '#FFC107',
             fontSize: '1rem',
           },
           notificationBox: {
             cursor: 'pointer',
             borderRadius: theme.spacing(1),
             marginTop: 20,
             background: theme.palette.info.main,
             color: theme.palette.primary.contrastText,
             fontSize: '1rem',
           },
           errorBox: {
             cursor: 'pointer',
             border: '0.5px solid #FF8888',
             borderRadius: theme.spacing(1),
             marginTop: 20,
             background: 'rgba(255, 136, 136, 0.04)',
             color: theme.palette.error.main,
             fontSize: '1rem',
           },
           surveyNameInput: {
             backgroundColor: theme.palette.background.default,
             color: theme.palette.text.disabled,
             border: 0,
             '& .MuiOutlinedInput-input': {
               fontWeight: 700,
               padding: 0,
               paddingBottom: theme.spacing(1),
               border: 0,
               fontSize: '2.19rem',
             },
             '& .MuiOutlinedInput-notchedOutline': {
               border: 0,
               borderRadius: 0,
             },
           },
           questionContainer: {
             position: 'sticky',
             top: 100,
           },
           check: {
             margin: 0,
             padding: 0,
             color: '1px solid rgba(255, 255, 255, 0.05)',
             '&$checked': {
               color: theme.palette.primary.contrastText,
             },
           },
           checked: {},
           inputLabel: {
             marginBottom: theme.spacing(1),
             marginTop: theme.spacing(1),
             color: theme.palette.text.secondary,
           },
           title: {
             fontSize: '1.2rem',
             fontWeight: 700,
           },
           loading: {
             color: theme.palette.primary.contrastText,
           },
           languageText: {
             border: `1px solid ${theme.palette.divider}`,
             color: 'grey',
             borderRadius: theme.spacing(10),
             marginLeft: theme.spacing(1),
             cursor: 'pointer',
           },
           activeLanguageText: {
             backgroundColor: theme.palette.primary.main,
             border: `1px solid black`,
             color: 'black',
             borderRadius: theme.spacing(10),
             marginLeft: theme.spacing(1),
             cursor: 'pointer',
           },

           updateText: {
             border: `1px solid ${theme.palette.primary.contrastText}`,
             borderRadius: '0.3rem',
           },
           editorSelectField: {
             '& .MuiOutlinedInput-notchedOutline': {
               border: 0,
             },
             '& .MuiSvgIcon-root': {
               color: theme.palette.text.primary,
             },
             '& .MuiInputBase-input': {
               border: `1px solid ${theme.palette.divider}`,
               borderRadius: '30px',
               padding: '0.5rem 0.75rem',
               '&:focus': {
                 border: `1px solid ${theme.palette.divider}`,
                 borderRadius: '30px',
               },
             },
           },
           thanksCardBorder: {
             borderTop: `1px solid ${theme.palette.divider}`,
           },
           thankYouBox: {
             border: `1px solid ${theme.palette.divider}`,
             borderRadius: theme.spacing(1),
             position: 'relative',
           },
           languageMenu: {
             width: '180px',
             paddingTop: theme.spacing(1.25),
             paddingBottom: theme.spacing(2.5),
           },
           thanksTopBox: {
             padding: '1.5rem 2.25rem 1rem 2.25rem',
           },
           thanksTextField: {
             '& .MuiOutlinedInput-input': {
               height: '1em',
               padding: '0.75rem 0.87rem',
             },
           },
           platform: {
             backgroundColor: theme.palette.secondary.light,
             border: `1.5px solid ${theme.palette.divider}`,
             borderRadius: 5,
             paddingInline: theme.spacing(1),
           },
           activePlatform: {
             background: `${theme.palette.primary.contrastText}0D`,
             border: `1px solid ${theme.palette.primary.contrastText}`,
             color: theme.palette.primary.contrastText,
             borderRadius: 5,
             paddingInline: theme.spacing(1),
           },
           activeTab: {
             '& $svg rect': { fill: theme.palette.primary.contrastText },
             '& $svg path': { fill: theme.palette.primary.contrastText },
             color: theme.palette.primary.contrastText,
           },
           inactiveTab: {
             '& $svg rect': { fill: '#000' },
             '& $svg path': { fill: '#000' },
             color: theme.palette.primary.dark,
           },

           platformSubLabel: {
             fontSize: 9,
             fontWeight: 400,
             paddingLeft: 5,
             marginTop: -4,
           },

           accordionSummary: {
             display: 'flex',
             alignItems: 'center',
             justifyContent: 'space-between',
             width: '100%',
           },
           eventContainer: {
             border: `1px solid ${theme.palette.divider}`,
             backgroundColor: theme.palette.primary.main,
             marginTop: theme.spacing(0.75),
             borderRadius: '5px',
           },
           eventHeading: {
             display: 'flex',
             alignItems: 'center',
             justifyContent: 'space-between',
             paddingTop: theme.spacing(1),
             paddingInline: theme.spacing(2),
           },
           conditionSection: {
             marginTop: theme.spacing(1.2),
             paddingTop: theme.spacing(1.3),
             paddingBottom: theme.spacing(1.5),
             paddingInline: theme.spacing(2.5),
             borderTop: `1px solid ${theme.palette.divider}`,
             borderBottom: `1px solid ${theme.palette.divider}`,
           },
           eventCoditionItem: {
             display: 'flex',
             alignItems: 'center',
             marginBottom: 5,
           },
           eventSelectField: {
             border: `1px solid ${theme.palette.divider}`,
             marginRight: theme.spacing(1),
             '& .MuiOutlinedInput-notchedOutline': {
               border: 0,
               borderRadius: 0,
             },
             '& .MuiOutlinedInput-input': { padding: '0.35rem 0.87rem' },
           },
           deleteConditionImg: {
             marginTop: 5,
             marginLeft: 'auto',
             cursor: 'pointer',
             width: '19px',
             height: '19px',
           },
           addEventCondition: {
             display: 'flex',
             alignItems: 'center',
             paddingTop: theme.spacing(1),
             paddingBottom: theme.spacing(1.5),
             paddingInline: theme.spacing(2.5),
           },
           addFilterText: {
             fontWeight: 700,
             color: '#5E5E5E',
             cursor: 'pointer',
             fontSize: 12,
           },
           daysField: {
             border: `1px solid ${theme.palette.divider}`,
             width: '8ch',
             marginLeft: theme.spacing(1),
             marginRight: theme.spacing(1),
             borderRadius: theme.spacing(0.5),
             '& .MuiOutlinedInput-input': {
               padding: theme.spacing(0.8),
               fontWeight: 700,
             },
           },
           triggerInactiveBox: {
             display: 'flex',
             position: 'relative',
             border: '1px solid #f0f0f0',
             borderRadius: 8,
             fontSize: 14,
             cursor: 'not-allowed',
             width: 'fit-content',
           },
           triggerTypeBox: {
             display: 'flex',
             border: '1px solid #f0f0f0',
             borderRadius: 8,
             fontSize: 14,
             cursor: 'pointer',
             width: 'fit-content',
           },
           triggerTypeLiveBox: {
             display: 'flex',
             border: '1px solid #201ce0',
             borderRadius: 8,
             fontSize: 14,
             backgroundColor: '#f4f3fe',
             color: '#201ce0',
             cursor: 'pointer',
             width: 'fit-content',
           },
           configureSelectField: {
             border: `1px solid ${theme.palette.divider}`,
             '& .MuiOutlinedInput-notchedOutline': {
               border: 0,
               borderRadius: 0,
             },
             '& .MuiOutlinedInput-input': { padding: '0.35rem 0.5rem' },
           },
           cohortMenuPaper: {
             maxHeight: '300px',
           },
           cohortItemBox: {
             display: 'flex',
             alignItems: 'center',
             justifyContent: 'space-between',
             width: '100%',
           },
           cohortImg: {
             height: '30px',
             width: '30px',
           },
           eventAttributeValueField: {
             '& .MuiOutlinedInput-input': { padding: '0.4rem 0.87rem' },
             backgroundColor: theme.palette.common.white,
             marginRight: theme.spacing(1),
             paddingTop: 0,
             '& .MuiOutlinedInput-root': {
               fontSize: '14px',
             },
           },

           backendTriggerSelect: {
             width: '50%',
             border: `1px solid ${theme.palette.divider}`,
             '& .MuiOutlinedInput-notchedOutline': {
               border: 0,
               borderRadius: 0,
             },
             '& .MuiOutlinedInput-input': { padding: '0.45rem 0.87rem' },
           },
           triggerActiveCount: {
             fontSize: 10,
             color: theme.palette.warning.dark,
           },

           configureAccordion: {
             marginBottom: theme.spacing(1.87),
             borderRadius: theme.spacing(1.1),
             boxShadow: 'none',
             padding: '1rem 2.1rem 1rem 2.1rem',
             '& .MuiAccordionDetails-root': {
               padding: '1.3rem 0rem 0.8rem 0rem',
             },
           },

           responseAccordion: {
             marginBottom: theme.spacing(1.87),
             borderRadius: theme.spacing(1.1),
             boxShadow: 'none',
             padding: '1rem 2.1rem 1rem 2.1rem',
             '& .MuiAccordionDetails-root': {
               padding: '1.3rem 0rem 0rem 0rem',
             },
           },

           customTextField: {
             '& .MuiOutlinedInput-input': { padding: '0.75rem 0.87rem' },
           },
           responseTextField: {
             '& .MuiOutlinedInput-input': { padding: '0.55rem 0.87rem' },
           },
           dateField: {
             '& .MuiOutlinedInput-input': { padding: '0.55rem 0.65rem' },
             marginInline: theme.spacing(1.5),
           },
           continuousTextField: {
             '& .MuiOutlinedInput-input': { padding: '0.55rem 0.65rem' },
           },
           continuousNumberField: {
             '& .MuiOutlinedInput-input': { padding: '0.5rem 0.65rem' },
           },
           expandIcon: {
             color: theme.palette.text.secondary,
           },

           configureWarningBox: {
             cursor: 'pointer',
             border: '0.5px solid #FF8888',
             borderRadius: theme.spacing(1),
             marginTop: 20,
             background: 'rgba(255, 136, 136, 0.04)',
             color: theme.palette.error.main,
             fontSize: '1rem',
           },
           autoTextField: {
             borderRadius: theme.spacing(0.5),
             '& .MuiOutlinedInput-root': {
               backgroundColor: theme.palette.background.default,
               padding: '0.75rem 0.87rem',
               fontSize: '0.87rem',
             },
             '& .MuiAutocomplete-tag': {
               border: `1px solid ${theme.palette.divider}`,
             },
             '& .MuiChip-deleteIcon': {
               fontSize: '1rem',
             },
           },
           triggerImg: {
             '& $svg path': { fill: theme.palette.text.primary },
           },
           removeButton: {
             paddingInline: theme.spacing(6.2),
             backgroundColor: theme.palette.background.default,
             color: theme.palette.text.secondary,
             border: `1px solid ${theme.palette.divider}`,
             fontWeight: 700,
             textTransform: 'unset',
             borderRadius: '0.3rem',
             marginTop: '0.5rem',
           },
           linkBgImg: {
             width: '30vw',
           },
           headingText: {
             paddingBlock: theme.spacing(0.7),
             backgroundColor: theme.palette.secondary.dark,
             display: 'flex',
             justifyContent: 'center',
             alignItems: 'center',
             fontWeight: 700,
             borderRadius: theme.spacing(0.7),
           },
           showBackendBox: {
             border: `1px solid ${theme.palette.divider}`,
             maxWidth: 'fit-content',
             paddingBlock: theme.spacing(0.8),
             paddingInline: theme.spacing(2),
             borderRadius: '5px',
             cursor: 'pointer',
           },
           activatedBackendBox: {
             '& $svg rect': { fill: theme.palette.primary.contrastText },
             '& $svg path': { fill: theme.palette.primary.contrastText },
             border: `1px solid ${theme.palette.primary.contrastText}`,
             backgroundColor: `${theme.palette.primary.contrastText}0D`,
             maxWidth: 'fit-content',
             paddingBlock: theme.spacing(0.8),
             paddingInline: theme.spacing(2),
             borderRadius: '5px',
             cursor: 'pointer',
           },
           eventConditionBox: {
             paddingBlock: theme.spacing(1.2),
             backgroundColor: theme.palette.secondary.dark,
             display: 'flex',
             justifyContent: 'center',
             alignItems: 'center',
             fontWeight: 700,
             borderRadius: theme.spacing(0.7),
           },
           resurveyStepper: {
             '& .MuiStepConnector-line': {
               borderColor: theme.palette.divider,
             },
             '& .MuiStepContent-root': {
               borderColor: theme.palette.divider,
             },
           },
           resurveyStep: {
             '& .MuiStepLabel-root .Mui-active': {
               color: theme.palette.primary.dark,
             },
             '& .MuiStepLabel-root .Mui-active .MuiStepIcon-text': {
               fill: theme.palette.common.white,
             },
             '& .MuiStepLabel-root': {
               padding: 0,
             },
           },
           stepLabel: {
             '& .MuiStepLabel-root .Mui-active': {
               color: theme.palette.text.primary,
             },
             '& .MuiStepLabel-label': {
               fontWeight: 700,
             },
           },
           searchBox: {
             marginInline: theme.spacing(3),
           },
           searchTextField: {
             '& .MuiOutlinedInput-input': {
               paddingRight: '0.6rem',
               paddingBlock: '0.6rem',
             },
           },
           questionPaper: {
             position: 'relative',
             width: '100%',
             marginLeft: theme.spacing(2),
             marginBottom: theme.spacing(1.25),
             cursor: 'pointer',
             border: `1px solid ${theme.palette.divider}`,
             borderRadius: theme.spacing(1),
             '& $h3': { color: theme.palette.text.secondary },

             '& .skipToSelect .MuiSelect-outlined': { padding: 14, paddingTop: 11, paddingBottom: 11 },
           },
           moreIcon: {
             color: theme.palette.text.secondary,
           },
           questionAccordion: {
             marginTop: theme.spacing(2),
             border: 0,
             boxShadow: 'none',
           },
           questionAccordionSummary: {
             justifyContent: 'flex-start',
             '& $svg path': { fill: theme.palette.text.primary },
             '& .MuiSvgIcon-root': { marginTop: '4px', width: '0.8em', height: '0.8em' },
             '& .MuiAccordionSummary-content': {
               flexGrow: 'unset',
             },
           },
           questionAccordionDetails: {
             padding: 0,
           },
           questionInputLabel: {
             marginBottom: theme.spacing(1),
             marginTop: theme.spacing(2),
             color: theme.palette.text.secondary,
           },
           questionVariantSelectField: {
             border: `1px solid ${theme.palette.divider}`,
             '& .MuiOutlinedInput-notchedOutline': {
               border: 0,
               borderRadius: 0,
             },
             '& .MuiOutlinedInput-input': { padding: '0.35rem 0.5rem' },
           },
           uploadImgButton: {
             backgroundColor: `${theme.palette.primary.contrastText}0D`,
             color: theme.palette.primary.contrastText,
             border: `1px solid ${theme.palette.primary.contrastText}`,
             fontSize: '1rem',
             boxShadow: 'none',
             textTransform: 'none',
             paddingTop: theme.spacing(0.35),
             paddingBottom: theme.spacing(0.5),
             paddingLeft: theme.spacing(1.5),
             paddingRight: theme.spacing(3),
             '&:hover': {
               backgroundColor: `${theme.palette.primary.contrastText}0D`,
               boxShadow: 'none',
             },
           },
           questionOrderBox: {
             position: 'absolute',
             left: theme.spacing(-4),
             top: '18px',
             display: 'flex',
             backgroundColor: theme.palette.primary.light,
             color: theme.palette.primary.contrastText,
             borderRadius: '4px',
             padding: theme.spacing(0.5, 1),

             '&:hover': {
               cursor: 'grab',
               transform: 'scale(1.1)',
             },
             '&:active': {
               cursor: 'grabbing',
               transform: 'scale(1.05)',
             },
           },
           selectQuestionOrder: {
             border: `1px solid ${theme.palette.divider}`,
             borderRadius: 5,
             padding: theme.spacing(1),
             paddingBlock: 2,
             fontSize: 12,
           },
           questionImg: {
             width: '200px',
           },
           customTooltip: {
             backgroundColor: theme.palette.common.white,
             color: 'black',
             border: `1px solid ${theme.palette.divider}`,
           },
           surveyOrderBox: {
             padding: theme.spacing(1),
             display: 'flex',
             justifyContent: 'space-between',
             alignItems: 'center',
             fontWeight: 700,
             border: `1px solid ${theme.palette.divider}`,
             backgroundColor: theme.palette.primary.main,
             marginTop: theme.spacing(1),
             borderRadius: '3px',
           },
           exitBtn: {
             position: 'absolute',
             top: '-10px',
             right: '-10px',
             cursor: 'pointer',
             width: '20px',
             height: '20px',
           },
           onSubmitSelect: {
             flexGrow: 1,
             border: `1px solid ${theme.palette.divider}`,
             '& .MuiOutlinedInput-notchedOutline': {
               border: 0,
               borderRadius: 0,
             },
             '& .MuiOutlinedInput-input': {
               height: '1em',
               padding: '0.32rem 0.87rem',
             },
           },
           ruleTypeSelect: {
             '& .MuiOutlinedInput-notchedOutline': {
               border: 0,
               borderRadius: 0,
             },
             '& .MuiOutlinedInput-input': {
               height: '1em',
               padding: '0.32rem 0.87rem',
               backgroundColor: '#EFF0F9',
             },
           },
           textField: {
             '& .MuiOutlinedInput-input': {
               height: '1em',
               padding: '0.52rem 0.87rem',
             },
           },
           questionSelectField: {
             '& .MuiOutlinedInput-notchedOutline': {
               border: 0,
               borderRadius: 0,
             },
             '& .MuiOutlinedInput-input': {
               padding: '0.35rem 0.9rem',
             },
           },
           selectField: {
             border: `1px solid ${theme.palette.divider}`,
             '& .MuiOutlinedInput-notchedOutline': {
               border: 0,
               borderRadius: 0,
             },
             '& .MuiOutlinedInput-input': {
               height: '1em',
               paddingTop: '0.3rem',
               paddingBottom: '0.36rem',
               paddingInline: '0.87rem',
             },
           },
           nonEditableQuestion: {
             padding: '0.35rem 0.9rem',
             backgroundColor: theme.palette.primary.main,
             color: theme.palette.text.secondary,
             borderRadius: 4,
           },
           addChoiceBox: {
             border: `1px dashed ${theme.palette.divider}`,
             padding: '0.45rem 1.25rem',
             borderRadius: '0.3rem',
             width: 'fit-content',
           },

           questionTypeMenu: {
             '& .MuiPaper-root': {
               width: '240px',
             },
           },
           prototypeBox: {
             border: `1px solid ${theme.palette.divider}`,
           },
           prototypeTextField: {
             paddingTop: theme.spacing(2.5),
             paddingBottom: theme.spacing(4),
             paddingInline: theme.spacing(2.3),
             borderRight: `1px solid ${theme.palette.divider}`,
           },
           linkView: {
             display: 'flex',
             alignItems: 'center',
             justifyContent: 'center',
           },
           switchTrack: {
             backgroundColor: 'grey',
             height: '16px',
           },
           switchBase: {
             color: theme.palette.text.secondary,
             height: '40px',
             '&.Mui-checked': {
               color: theme.palette.success.main,
             },
             '&.Mui-checked + .MuiSwitch-track': {
               backgroundColor: '#4CAF50',
             },
           },
           dataTypeField: {
             '& .MuiOutlinedInput-input': { padding: '0.5rem 0.5rem' },
           },
           validationLabel: {
             marginBottom: theme.spacing(0.5),
             color: theme.palette.text.secondary,
           },
           dataField: {
             '& .MuiInputBase-root': { padding: '0.2rem 0.5rem', fontSize: '14px' },
           },
           generateAIBox: {
             border: `1px solid ${theme.palette.info.contrastText}`,
             backgroundColor: `#6951FF0D`,
             paddingBlock: theme.spacing(1),
             paddingInline: theme.spacing(1.5),
             borderRadius: '5px',
             marginTop: theme.spacing(1.5),
             marginBottom: theme.spacing(2),
             cursor: 'pointer',
           },
           aiTitle: {
             marginTop: theme.spacing(2.2),
             marginBottom: theme.spacing(0.8),
           },
           advanceBox: {
             borderBottom: `1px solid #E8E8E8`,
             marginBottom: theme.spacing(1),
             paddingBottom: theme.spacing(1.8),
           },
           advanceOptions: {
             borderBottom: `1px solid #E8E8E8`,
             marginBottom: theme.spacing(1),
           },
           promptTypeRadio: {
             marginRight: theme.spacing(5),
             marginLeft: theme.spacing(0.6),
             marginBottom: theme.spacing(2),
             '& .Mui-checked': {
               color: theme.palette.info.contrastText,
             },
           },
           generateButton: {
             backgroundColor: theme.palette.info.contrastText,
             fontWeight: 500,
             '&:hover': {
               backgroundColor: theme.palette.info.contrastText,
             },
           },
           questionCountSelect: {
             borderColor: theme.palette.info.contrastText,
             '& .MuiOutlinedInput-input': {
               backgroundColor: 'transparent',
             },
             '& $svg path': { fill: theme.palette.info.contrastText },
           },
           progressBarPath: {
             height: '5px',
             borderRadius: theme.spacing(2),
             backgroundColor: '#B8A8CD',
           },
           innerProgressBar: {
             width: '15px',
             height: '5px',
             borderRadius: theme.spacing(2),
             backgroundColor: theme.palette.info.contrastText,
           },
           progress: {
             height: '7px',
             borderRadius: theme.spacing(1),
             backgroundColor: '#B8A8CD',
             '& .MuiLinearProgress-barColorPrimary': {
               backgroundColor: theme.palette.info.contrastText,
             },
           },
           aiTextField: {
             '& .MuiInputBase-root': { padding: '0.2rem 0.5rem' },
           },
           resurveySettingsBox: {
             backgroundColor: theme.palette.divider,
             maxWidth: 'fit-content',
             paddingTop: theme.spacing(0.3),
             paddingBottom: theme.spacing(0.4),
             paddingInline: theme.spacing(1),
             borderRadius: '3px',
             marginTop: theme.spacing(1),
             cursor: 'pointer',
           },
           sequenceDeleteImg: {
             cursor: 'pointer',
             width: '17px',
             height: '17px',
           },

           draggingQuestion: {
             backgroundColor: theme.palette.background.paper,
             boxShadow: theme.shadows[8],
             opacity: 0.9,
             '& $questionOrderBox': {
               backgroundColor: theme.palette.primary.main,
               color: theme.palette.primary.contrastText,
             },
           },
           lockBox: {
             backgroundColor: '#EEEEF8',
             border: `1px solid #A9B0C000`,
             maxWidth: 'fit-content',
             paddingBlock: theme.spacing(0.2),
             paddingInline: theme.spacing(1),
             borderRadius: '2px',
             marginLeft: 18,
             position: 'absolute',
             top: 0,
             right: 0,
           },
         }),
       );
