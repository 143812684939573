import React, { useState, useEffect, FunctionComponent } from 'react';
import { useSelector } from 'react-redux';
import {
  Grid,
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  CircularProgress,
  Tooltip,
  TextField,
} from '@mui/material';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import filterImg from '../../images/surveyPerformance/filter-icon.svg';
import emptyStateImg from '../../images/emptyState/empty-themes.svg';
import surveyIcon from '../../images/survey/survey.svg';

import { StyledTableRow } from '../common/tableStyles';
import { IAllRepoInsights, IInsightStore, IWorkspaceTheme } from '../../types/insight';
import WorkspaceModal from './WorkspaceModal';
import { dateRangeQuery, modifiedDate } from '../../utils/DateUtil';
import { amplitudeEvent } from '../../utils/amplitudeUtil';
import { RootState } from '../../store';
import { DateFilter } from '../spotlight/DateFilter';
import {
  fetchWorkspaceThemes,
  getCSVUploadStatus,
  getInsightsInRespository,
  getInsightsInRespositoryViaSearch,
  getWorkspaceDesc,
} from '../../services/insightService';
import { ColoredPill, insightStyles } from './InsightStyle';
import _ from 'lodash';
import { AddSourceModal } from './AddSourceModal';
import { FilterOptions } from './FilterOptions';
import { sourceInsightImgs } from '../../static/connectionData';
import { handleUniqFilter } from '../../utils/insightUtil';

interface QueryProps {
  insightStore: IInsightStore;
  setInsightStore: React.Dispatch<React.SetStateAction<IInsightStore>>;
  openFilter: boolean;
  setOpenFilter: React.Dispatch<React.SetStateAction<boolean>>;
  themes: Array<IWorkspaceTheme>;
  searchString: string;
  setSearchString: React.Dispatch<React.SetStateAction<string>>;
}

export default function RepositoryComponent() {
  const classes = insightStyles();

  const [isLoading, setLoading] = useState(true);
  const [openFilter, setOpenFilter] = useState<boolean>(false);
  const [searchString, setSearchString] = useState('');
  const [insightStore, setInsightStore] = useState<IInsightStore>({
    sentiment_store: [],
    theme_store: [],
    emotion_store: [],
    source: '',
  });
  const [dateRange, setDateRange] = React.useState({
    type: 'days',
    days_count: 30,
    time_from: '',
    time_to: '',
  });
  const [themes, setThemes] = useState<Array<IWorkspaceTheme>>([]);
  const [repoInsights, setRepoInsights] = useState<Array<IAllRepoInsights>>([]);
  const [showSourceModal, toogleSourceModal] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);
  const [activeShowMore, setActiveShowMore] = useState(true);
  const [openWorkspaceModal, toggleWorkspaceModal] = useState(false);
  const [showCSVLoading, toggleCSVLoading] = useState(true);
  const [activeIdx, setActiveIdx] = useState<number | null>();
  const [searchQuery, setSearchQuery] = useState('');

  //tracking context
  const tracking_info = useSelector((state: RootState) => state.tracking);

  useEffect(() => {
    if (tracking_info) {
      amplitudeEvent(tracking_info, 'search tab viewed', {});
      if (tracking_info && !tracking_info.show_discovery) {
        window.location.href = '/';
      }
    }
  }, [tracking_info]);

  const fetchThemes = async () => {
    const results = await fetchWorkspaceThemes(dateRange);

    if (results && results.data && results.data.themes) {
      setThemes(results.data.themes);
    }
  };

  const handleSearchText = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  const fetchLatestInsights = async () => {
    const insights = await getInsightsInRespository({ ...dateRange, page: pageNumber, source: insightStore.source });
    setLoading(false);
    if (insights && insights.data) {
      setRepoInsights(insights.data);
    }
  };

  const fetchLatestInsightsViaSearch = async () => {
    if (!searchQuery) {
      return;
    }

    const insights = await getInsightsInRespositoryViaSearch({
      ...dateRange,
      page: pageNumber,
      source: insightStore.source,
      search_query: searchQuery,
    });
    setLoading(false);
    if (insights && insights.data) {
      setRepoInsights(insights.data);
    }
  };

  const fetchWorkspaceDescription = async () => {
    const results = await getWorkspaceDesc();
    if (results === '') {
      toggleWorkspaceModal(true);
    }
  };

  const handleDateChanges = (range_type: string, field_name: string, value: string | number) => {
    const curr_date_query = dateRange;
    curr_date_query['type'] = range_type;
    if (field_name === 'days_count') {
      curr_date_query[field_name] = +value;
      const { time_to, time_from } = dateRangeQuery(dateRange);
      setDateRange({
        ...curr_date_query,
        time_to,
        time_from,
      });
      return;
    } else {
      curr_date_query[field_name] = value;
    }
    setDateRange({
      ...curr_date_query,
    });
  };

  useEffect(() => {
    let check = true;
    let csv_loaded = false;
    const interval = setInterval(async () => {
      if (check) {
        const csv_loading = await getCSVUploadStatus();
        if (csv_loading.length === 0) {
          if (csv_loaded) {
            await fetchThemes();
            await fetchLatestInsights();
          }
          toggleCSVLoading(false);
          check = false;
          clearInterval(interval);
        } else {
          csv_loaded = true;
          toggleCSVLoading(true);
        }
      }
    }, 15 * 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    fetchWorkspaceDescription();
  }, []);

  useEffect(() => {
    fetchThemes();
    fetchLatestInsights();
  }, [dateRange, pageNumber]);

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" mt="25%">
        <CircularProgress className={classes.loading} />
      </Box>
    );
  } else {
    let filtered_insights = repoInsights;
    const { sentiment_store, theme_store, emotion_store } = insightStore;
    if (sentiment_store.length > 0) {
      const sentiments = sentiment_store.map(s => s.value);
      filtered_insights = filtered_insights.filter(f => {
        if (['freshdesk', 'zendesk', 'intercom', 'quick_text'].includes(f.source) && f.items && f.items.length > 0) {
          return f.items.some(i => sentiments.includes(i.sentiment));
        }
        return sentiments.includes(f.sentiment);
      });
    }

    if (theme_store.length > 0) {
      const themes = theme_store.map(s => s.value);
      filtered_insights = filtered_insights.filter(f => {
        if (['freshdesk', 'zendesk', 'intercom', 'quick_text'].includes(f.source) && f.items && f.items.length > 0) {
          return f.items.some(i => themes.includes(i.theme_name));
        }
        return themes.includes(f.theme_name);
      });
    }

    if (emotion_store.length > 0) {
      const emotions = emotion_store.map(s => s.value);
      filtered_insights = filtered_insights.filter(f => {
        if (['freshdesk', 'zendesk', 'intercom', 'quick_text'].includes(f.source) && f.items && f.items.length > 0) {
          return f.items.some(i => emotions.includes(i.emotion));
        }
        return emotions.includes(f.emotion);
      });
    }

    return (
      <>
        {showSourceModal && (
          <AddSourceModal
            open={showSourceModal}
            toggleModal={() => {
              toogleSourceModal(false);
            }}
          />
        )}
        {openWorkspaceModal && (
          <WorkspaceModal
            toggleModal={toggleWorkspaceModal}
            open={openWorkspaceModal}
            handleSave={() => fetchWorkspaceDescription()}
          />
        )}
        <Typography variant="h2" mt={1} mb={2}>
          Search
        </Typography>
        <Box display={'flex'}>
          <Box display={'flex'} justifyContent={'space-between'} width={'100%'} alignItems={'center'}>
            <Box display={'flex'}>
              <div className={classes.searchBox}>
                <TextField
                  placeholder="Search Query"
                  className={classes.searchTextField}
                  value={searchQuery}
                  onChange={e => {
                    handleSearchText(e);
                  }}
                />
              </div>
              <Button
                variant="contained"
                style={{ marginLeft: '5px' }}
                onClick={() => {
                  fetchLatestInsightsViaSearch();
                }}
              >
                Search
              </Button>
            </Box>
            <Box display={'flex'}>
              <Typography
                variant={'subtitle1'}
                fontWeight={'bold'}
                color={'primary.contrastText'}
                style={{ cursor: 'pointer', marginBottom: 5, marginRight: 10 }}
                onClick={() => {
                  toogleSourceModal(true);
                }}
                className={classes.uploadButton}
              >
                + Upload Data
              </Typography>

              <DateFilter date_range={dateRange} handleDateChanges={handleDateChanges} hide1D={true} />
              <Button
                className={classes.filterButton}
                style={{
                  color: 'black',
                  marginLeft: 10,
                }}
                onClick={() => {
                  setOpenFilter(true);
                }}
              >
                <Box display={'flex'} alignItems={'center'}>
                  <FilterOptions
                    insightStore={insightStore}
                    setInsightStore={setInsightStore}
                    openFilter={openFilter}
                    setOpenFilter={setOpenFilter}
                    themes={themes}
                    searchString={searchString}
                    setSearchString={setSearchString}
                    onClickHandler={fetchLatestInsights}
                  />
                  <img src={filterImg} alt="" />
                  <Typography variant="subtitle1" fontWeight={700} ml={1}>
                    Filter
                  </Typography>
                </Box>
              </Button>
            </Box>
          </Box>
        </Box>

        <Grid container pt={3}>
          {filtered_insights.length === 0 ? (
            <div style={{ marginLeft: 'auto', marginRight: 'auto' }}>
              <Box pt={25} style={{ textAlign: 'center' }}>
                <img src={emptyStateImg} alt="" />
                <Typography
                  variant={'subtitle1'}
                  maxWidth={250}
                  textAlign={'center'}
                  style={{ marginLeft: 'auto', marginRight: 'auto' }}
                >
                  Add a feedback source and wait while we build insights for you...
                </Typography>
              </Box>
            </div>
          ) : (
            <>
              {showCSVLoading && (
                <Box display={'flex'} width={150} alignItems={'center'} marginLeft={'auto'} marginTop={1}>
                  <CircularProgress className={classes.loading} size={16} />
                  <Typography
                    variant={'subtitle1'}
                    maxWidth={250}
                    textAlign={'center'}
                    style={{ marginLeft: 'auto', marginRight: 'auto' }}
                  >
                    Insights loading
                  </Typography>
                </Box>
              )}
              <TableContainer className={classes.triggerTable}>
                <Table size="medium">
                  <TableHead>
                    <TableRow>
                      <TableCell width={'8%'}>User Id</TableCell>
                      <TableCell width={'32%'}>Content</TableCell>
                      <TableCell width={'10%'}>Topic</TableCell>
                      <TableCell width={'10%'}>Sentiment</TableCell>
                      <TableCell width={'10%'}>Emotion</TableCell>
                      <TableCell width={'10%'}>Intent</TableCell>
                      <TableCell width={'10%'}>Created at</TableCell>
                      <TableCell width={'10%'}>Source</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filtered_insights.map((option, index) => (
                      <>
                        <StyledTableRow
                          key={index}
                          onClick={
                            ['freshdesk', 'zendesk', 'intercom', 'quick_text'].includes(option.source)
                              ? () => {
                                  setActiveIdx(activeIdx == +index ? null : +index);
                                }
                              : () => {}
                          }
                          style={
                            ['freshdesk', 'zendesk', 'intercom', 'quick_text'].includes(option.source)
                              ? { cursor: 'pointer' }
                              : {}
                          }
                        >
                          <TableCell component="th" scope="row" width={'8%'}>
                            <Tooltip title={option.user_id}>
                              <Typography
                                variant={'subtitle1'}
                                flexWrap={'wrap'}
                                style={{
                                  width: '12ch',
                                  whiteSpace: 'nowrap',
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                }}
                              >
                                {option.user_id}
                              </Typography>
                            </Tooltip>
                          </TableCell>
                          <TableCell width={'32%'}>
                            {['freshdesk', 'zendesk', 'intercom', 'quick_text'].includes(option.source) &&
                              option.items &&
                              option.items[0] &&
                              option.items[0].title && (
                                <Typography
                                  variant={'subtitle1'}
                                  lineHeight={1.3}
                                  ml={1}
                                  mb={1}
                                  flexWrap={'wrap'}
                                  color={'gray'}
                                >
                                  <span style={{ textDecoration: 'underline', fontWeight: 'bold' }}>Subject: </span>
                                  {option.items[0].title}
                                </Typography>
                              )}
                            <Typography variant={'subtitle1'} lineHeight={1.3} ml={1} flexWrap={'wrap'}>
                              {option.response}
                            </Typography>
                          </TableCell>
                          <TableCell width={'12%'}>
                            {['freshdesk', 'zendesk', 'intercom', 'quick_text'].includes(option.source) ? (
                              <div>
                                {option.items &&
                                  handleUniqFilter(option.items, 'theme_name').map(o => (
                                    <Typography variant="subtitle1" pl={0.5} flexWrap={'wrap'}>
                                      {o.theme_name ? o.theme_name : ''}
                                    </Typography>
                                  ))}
                              </div>
                            ) : (
                              <Typography variant="subtitle1" pl={0.5} flexWrap={'wrap'}>
                                {option.theme_name ? option.theme_name : ''}
                              </Typography>
                            )}
                          </TableCell>
                          <TableCell width={'10%'}>
                            {['freshdesk', 'zendesk', 'intercom', 'quick_text'].includes(option.source) ? (
                              <div>
                                {option.items &&
                                  handleUniqFilter(option.items, 'sentiment').map(o => (
                                    <Box display={'flex'}>
                                      <ColoredPill sentiment={o.sentiment} add_strong={false} />
                                    </Box>
                                  ))}
                              </div>
                            ) : (
                              <Box>
                                <ColoredPill sentiment={option.sentiment} add_strong={false} />
                              </Box>
                            )}
                          </TableCell>
                          <TableCell width={'10%'}>
                            {['freshdesk', 'zendesk', 'intercom', 'quick_text'].includes(option.source) ? (
                              <div>
                                {option.items &&
                                  handleUniqFilter(option.items, 'emotion').map(o => (
                                    <Box display={'flex'}>
                                      <ColoredPill sentiment={o.emotion} add_strong={false} />
                                    </Box>
                                  ))}
                              </div>
                            ) : (
                              <Box>
                                <ColoredPill sentiment={option.emotion} add_strong={false} />
                              </Box>
                            )}
                          </TableCell>
                          <TableCell width={'10%'}>
                            {['freshdesk', 'zendesk', 'intercom', 'quick_text'].includes(option.source) ? (
                              <div>
                                {option.items &&
                                  handleUniqFilter(option.items, 'intent').map(o => (
                                    <Typography variant={'subtitle1'} lineHeight={1.3} ml={1} flexWrap={'wrap'}>
                                      {o.intent ? o.intent : ''}
                                    </Typography>
                                  ))}
                              </div>
                            ) : (
                              <Typography variant={'subtitle1'} lineHeight={1.3} ml={1} flexWrap={'wrap'}>
                                {option.intent ? option.intent : ''}
                              </Typography>
                            )}
                          </TableCell>
                          <TableCell width={'10%'}>
                            <Typography variant={'subtitle1'}>{modifiedDate(option.created_at, 'll')}</Typography>
                          </TableCell>
                          <TableCell width={'10%'}>
                            {sourceInsightImgs[option.source] ? (
                              <img src={sourceInsightImgs[option.source]} height={16} alt={option.source} />
                            ) : (
                              ''
                            )}

                            {['freshdesk', 'zendesk', 'intercom', 'quick_text'].includes(option.source) &&
                              (activeIdx === +index ? (
                                <ArrowDropUpIcon style={{ marginLeft: 8 }} fontSize={'medium'} />
                              ) : (
                                <ArrowDropDownIcon style={{ marginLeft: 8 }} fontSize={'medium'} />
                              ))}
                          </TableCell>
                        </StyledTableRow>
                        {['freshdesk', 'zendesk', 'intercom', 'quick_text'].includes(option.source) &&
                          activeIdx === +index && (
                            <StyledTableRow>
                              <TableCell component="th" scope="row" width={'8%'}></TableCell>
                              <TableCell colSpan={6} width={'80%'}>
                                <Typography
                                  variant={'subtitle1'}
                                  lineHeight={1.3}
                                  ml={1}
                                  flexWrap={'wrap'}
                                  color={'gray'}
                                >
                                  {option.source !== 'quick_text' && (
                                    <span style={{ textDecoration: 'underline', fontWeight: 'bold' }}>Subject: </span>
                                  )}
                                  {option.items[0].title}
                                  {option.source !== 'quick_text' && ` #${option.ticket_id}`}
                                </Typography>

                                {option.items.map(o => (
                                  <Box mt={2} mb={2}>
                                    <Typography
                                      variant={'subtitle1'}
                                      lineHeight={1.3}
                                      ml={1}
                                      flexWrap={'wrap'}
                                      style={{ wordBreak: 'break-all', maxWidth: '65vw' }}
                                    >
                                      {option.source !== 'quick_text' && (
                                        <>
                                          <span style={{ fontWeight: 'bold' }}>
                                            {!o.incoming ? 'Agent' : 'User'} (
                                            {option.source !== 'quick_text' && o.user_id})
                                          </span>
                                          :
                                        </>
                                      )}
                                      {o.response}
                                    </Typography>
                                    <Box display={'flex'} justifyContent={'space-between'} mt={1}>
                                      <Box display={'flex'} ml={1}>
                                        <Typography
                                          variant={'subtitle2'}
                                          lineHeight={1.3}
                                          flexWrap={'wrap'}
                                          mr={0.5}
                                          mt={0.5}
                                        >
                                          {o.theme_name}
                                        </Typography>
                                        <ColoredPill sentiment={o.emotion} add_strong={false} />
                                        <ColoredPill sentiment={o.sentiment} add_strong={false} />
                                        <ColoredPill sentiment={o.intent} add_strong={false} />
                                      </Box>
                                      <Box>
                                        <Typography variant={'subtitle1'} color={'gray'}>
                                          {modifiedDate(o.created_at, 'll')}
                                        </Typography>
                                      </Box>
                                    </Box>
                                  </Box>
                                ))}
                              </TableCell>
                              {option && option.items && option.items[0] && option.source !== 'quick_text' && (
                                <TableCell component="th" scope="row" width={'8%'}>
                                  <a
                                    href={`https://${option.items[0].source_url}/a/tickets/${option.items[0].primary_ticket_id}`}
                                    target="_blank"
                                  >
                                    <OpenInNewIcon fontSize={'medium'} style={{ color: 'blue' }} />
                                  </a>
                                </TableCell>
                              )}
                            </StyledTableRow>
                          )}
                      </>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>

              {activeShowMore && searchString.length === 0 && (
                <Typography
                  variant={'subtitle2'}
                  fontWeight={700}
                  marginTop={1.5}
                  marginLeft={'auto'}
                  onClick={() => {
                    setPageNumber(pageNumber + 1);
                  }}
                  style={{
                    cursor: 'pointer',
                  }}
                >
                  Show more &#8594;
                </Typography>
              )}
            </>
          )}
        </Grid>
      </>
    );
  }
}
