import React, { useEffect } from 'react';
import { Box, Button, Paper, Typography } from '@mui/material';
import parse from 'html-react-parser';
import copy from 'copy-to-clipboard';
import { Theme } from '@mui/system';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { TextArea } from '../../styles/customStyles';
import { useSelector } from 'react-redux';

import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import ThumbDownOffAltIcon from '@mui/icons-material/ThumbDownOffAlt';

import SendIcon from '@mui/icons-material/Send';
import StopCircleIcon from '@mui/icons-material/StopCircle';
import { addChatData, getChatHistory } from '../../services/surveyService';
import { ISurveyChat } from '../../types/survey';

import dotImg from '../../images/surveyPerformance/blue-dot.svg';
import { amplitudeEvent } from '../../utils/amplitudeUtil';
import { RootState } from '../../store';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
      backgroundColor: '#f9f9f9',
      height: 'calc(80vh - 64px - 20px)',
      maxHeight: '500px !important',
      minHeight: '250px !important',
      display: 'flex',
      overflowY: 'auto',
      flexDirection: 'column',
      justifyContent: 'end',
    },
    bubbleLeftContainer: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      marginBottom: 10,
      width: '85%',
    },
    bubbleRightContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      marginBottom: 10,
      width: '85%',
    },
    rightBubble: {
      borderRadius: '25px',
      borderRadiusBottomLeft: '0px',
      margin: '5px',
      padding: '10px 20px',
      display: 'inline-block',
      backgroundColor: `#eef0f4`,
    },
  }),
);

export default function SurveyChatComponent(props: { survey_id: string; show_textbox: boolean }) {
  const classes = useStyles();
  const { survey_id, show_textbox } = props;

  const [chatHistory, setChatHistory] = React.useState<Array<ISurveyChat>>([]);
  const [chat, setChat] = React.useState<string>('');
  const [isLoading, setLoading] = React.useState<boolean>(false);

  //tracking context
  const tracking_info = useSelector((state: RootState) => state.tracking);

  const fetchChatHistory = async () => {
    const results = await getChatHistory(survey_id);
    if (results && results.length > 0) {
      setChatHistory(results.reverse());
    }
  };

  const addChatInfo = async () => {
    if (!chat) return;
    setLoading(true);
    setChatHistory([
      {
        chat,
        incoming: true,
        chat_id: '1',
        created_at: '',
      },
      ...chatHistory,
    ]);
    setChat('');
    const results = await addChatData(survey_id, {
      chat,
      incoming: true,
    });
    if (results) {
      amplitudeEvent(tracking_info, 'chat added', {
        survey_id: survey_id,
        chat,
      });
      setLoading(false);
      await fetchChatHistory();
    }
  };

  useEffect(() => {
    fetchChatHistory();
  }, []);

  return (
    <Box className={classes.root}>
      {chatHistory.length === 0 && (
        <Box textAlign={'center'}>
          <Typography color={'gray'} paddingBottom={2} fontSize={16}>
            Get answers from <br /> Blitzllama’s AI assistant
          </Typography>
          <Typography color={'gray'} paddingBottom={20} fontSize={14}>
            <span
              style={{
                fontWeight: 'bold',
              }}
            >
              Tip:
            </span>{' '}
            Start the conversation once <br /> responses start coming in
          </Typography>
        </Box>
      )}
      <Box
        style={{
          overflowY: 'scroll',
          display: 'flex',
          flexDirection: 'column-reverse',
        }}
      >
        {isLoading && (
          <Box className={classes.bubbleLeftContainer}>
            <Box m={0.5} display={'flex'}>
              <img
                src={dotImg}
                style={{
                  marginTop: 1,
                  marginRight: 4,
                }}
              />
              <Typography textAlign={'left'} fontSize={12} fontStyle={'italic'} color={'blue'}>
                Thinking...
              </Typography>
            </Box>
          </Box>
        )}
        {chatHistory.map((item, index) => (
          <>
            {!item.incoming ? (
              <Box className={classes.bubbleLeftContainer}>
                <Box m={0.5}>
                  <Typography textAlign={'left'} fontSize={14}>
                    {parse(item.chat)}
                  </Typography>
                  <Box
                    textAlign={'left'}
                    justifyContent={'flex-start'}
                    style={{
                      cursor: 'pointer',
                    }}
                    mt={1}
                  >
                    <ContentCopyIcon
                      style={{
                        fontSize: '12',
                        color: 'gray',
                      }}
                      onClick={() => copy(item.chat)}
                    />
                    <ThumbUpOffAltIcon
                      style={{
                        fontSize: '14',
                        color: 'gray',
                        marginLeft: 5,
                      }}
                      onClick={() => {
                        amplitudeEvent(tracking_info, 'chat history', {
                          tab: 'thumbs up',
                          survey_id: survey_id,
                          chat: item.chat,
                        });
                      }}
                    />
                    <ThumbDownOffAltIcon
                      style={{
                        fontSize: '14',
                        color: 'gray',
                        marginLeft: 5,
                      }}
                      onClick={() => {
                        amplitudeEvent(tracking_info, 'chat history', {
                          tab: 'thumbs down',
                          survey_id: survey_id,
                          chat: item.chat,
                        });
                      }}
                    />
                  </Box>
                </Box>
              </Box>
            ) : (
              <Box
                className={classes.bubbleRightContainer}
                style={{
                  justifyContent: 'flex-end !important',
                }}
                marginLeft={'auto'}
              >
                <Box className={classes.rightBubble}>
                  <Typography textAlign={'left'} fontSize={14}>
                    {item.chat}
                  </Typography>
                </Box>
              </Box>
            )}
          </>
        ))}
      </Box>

      {show_textbox && (
        <Box
          display={'flex'}
          style={{
            position: 'relative',
            width: '100%',
          }}
        >
          <TextArea
            placeholder="Type your message here"
            style={{
              width: '100%',
              paddingRight: 30,
              fontSize: 16,
              fontFamily: 'rustica',
            }}
            onChange={e => setChat(e.target.value)}
            value={chat}
            onKeyDown={e => {
              if (e.key === 'Enter' && !e.shiftKey && 'form' in e.target) {
                e.preventDefault();
                if (!isLoading) {
                  addChatInfo();
                }
              }
            }}
          />
          {!isLoading ? (
            <SendIcon
              fontSize={'medium'}
              style={{
                color: 'blue',
                position: 'absolute',
                right: 5,
                top: 13,
                cursor: 'pointer',
              }}
              onClick={() => {
                addChatInfo();
              }}
            />
          ) : (
            <StopCircleIcon
              fontSize={'medium'}
              style={{
                color: 'black',
                position: 'absolute',
                right: 5,
                top: 13,
              }}
            />
          )}
        </Box>
      )}
    </Box>
  );
}
