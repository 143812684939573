import React, { Dispatch, FunctionComponent, SetStateAction, useState } from 'react';
import { useSelector } from 'react-redux';
import { Bar, Line } from 'react-chartjs-2';
import 'chartjs-plugin-datalabels';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { useStyles as commonStyles } from './PerformanceStyle';
import { Typography, Box, Theme, Tooltip, IconButton } from '@mui/material';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { ReactComponent as PinImg } from '../../images/surveyPerformance/pin-icon.svg';
import { ReactComponent as ChartImg } from '../../images/surveyPerformance/charts-icon.svg';
import { ReactComponent as ArrowImg } from '../../images/surveyPerformance/right-arrow.svg';
import { ReactComponent as BlackDotImg } from '../../images/surveyPerformance/black-dot.svg';
import ThumbUpOutlinedIcon from '@mui/icons-material/ThumbUpOutlined';
import ThumbDownOutlinedIcon from '@mui/icons-material/ThumbDownOutlined';
import aiStarImg from '../../images/editor/ai-star.svg';
import rightArrowButton from '../../images/surveyPerformance/arrow-button.svg';
import prototypeLinkImg from '../../images/editor/prototype-link.svg';

import { chartDataOptions, getChartDataSets, graphOptions } from '../../utils/graphUtil';
import {
  addChartToBoard,
  createChartDetails,
  createTakeawayDetails,
  fetchAllBoard,
} from '../../services/spotlightService';

import { sentimentIcon, actionTheme, actionIcon } from '../../static/staticData';
import { QuestionStats, npsData, npsGraphData, RuleItem, ISurvey, takeawayType, IOthersText } from '../../types/survey';
import { IMemberDetails } from '../../types/member';
import { getModifiedText, getNps, getRuleType } from '../../utils/questionUtil';
import { getSentimentTheme, npsBackgroundColors } from '../../static/colorData';
import { questionTypes } from '../../static/questionRule';
import AlertUtil from '../../utils/AlertUtil';
import { spotlightInitialData } from '../spotlight/constants';
import { IBoard, IDateRange, ISpotlightQuery } from '../spotlight/spotlight';
import { PinChartModal } from '../spotlight/modal/CreateChartModal';
import { amplitudeEvent } from '../../utils/amplitudeUtil';
import { modifiedDate } from '../../utils/DateUtil';
import { getPrototypeLink } from '../../utils/stringUtil';
import { LinearProgressWithLabel } from '../../utils/ProgressUtil';
import { RootState } from '../../store';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    questionBox: {
      border: `1px solid ${theme.palette.divider}`,
      borderRadius: theme.spacing(1),
      marginBottom: theme.spacing(1.6),
      position: 'relative',
    },
    questionText: {
      wordBreak: 'break-word',
      marginBottom: theme.spacing(1),
      marginRight: theme.spacing(2),
    },
    prototypeInputTable: {
      minWidth: 650,
      maxHeight: '500px',
      overflowY: 'auto',
      '& .MuiTable-root .MuiTableCell-root': {
        border: 0,
      },
      '& .MuiTableCell-head': {
        fontSize: '0.8rem',
        fontWeight: 400,
        padding: 0,
        color: theme.palette.text.secondary,
      },
      '& .MuiTableHead-root': {
        borderTop: 0,
      },
      '& .MuiTableCell-body': {
        fontSize: '0.85rem',
        padding: 0,
        paddingBlock: '10px',
      },
      '&::-webkit-scrollbar': {
        width: '4px',
      },
      '&::-webkit-scrollbar-track': {
        background: '#F5F6FC',
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#B7B7D2',
      },
    },
    table: {
      minWidth: 650,
      maxHeight: '500px',
      overflowY: 'scroll',
      '& .MuiTable-root .MuiTableCell-root': {
        border: 0,
      },
      '& .MuiTableCell-head': {
        fontSize: '0.8rem',
        fontWeight: 400,
        padding: 0,
        color: theme.palette.text.secondary,
      },
      '& .MuiTableHead-root': {
        borderTop: 0,
      },

      '& .MuiTableCell-body': {
        fontSize: '0.85rem',
        padding: 0,
        paddingBlock: '10px',
      },

      '& .MuiTableRow-root.MuiTableRow-hover:hover': {
        backgroundColor: `${theme.palette.background.paper}CC`,
      },
    },
    sentimentBox: {
      // width: '68px',
      maxWidth: 'fit-content',
      borderRadius: '2px',
      padding: theme.spacing(0.75),
      paddingBlock: theme.spacing(0.35),
      justifyContent: 'center',
    },
    actionBox: {
      padding: theme.spacing(0.5),
      paddingBlock: theme.spacing(0.2),
      border: `1px solid ${theme.palette.divider}`,
      borderRadius: '2px',
      width: '90px',

      justifyContent: 'center',
    },

    pinText: {
      cursor: 'pointer',
    },
    editField: {
      '& .MuiOutlinedInput-input': {
        fontSize: '1rem',
        fontWeight: 400,
        padding: '7px 12px',
      },
      marginBottom: 5,
    },
    savePinButton: {
      paddingBlock: 6,
      paddingLeft: 7,
      paddingRight: 7,
      fontSize: 14,
      marginLeft: 5,
      marginRight: 5,
      marginTop: -5,
    },
    questionImgContainer: {
      paddingTop: theme.spacing(1),
      maxWidth: 'fit-content',
    },
    questionImg: {
      maxHeight: '61px',
    },
    questionOrderBox: {
      backgroundColor: theme.palette.secondary.dark,
      border: '1px solid rgba(255, 255, 255, 0.05)',
      borderRadius: 5,
      padding: 8,
      paddingBlock: 3,
      position: 'absolute',
      left: -17,
    },
    conditionOrder: {
      border: `1px solid ${theme.palette.divider}`,
      borderRadius: 5,
      padding: 8,
      paddingBlock: 3,
      display: 'inline',
      marginLeft: 5,
    },
    pinBox: {
      border: `1px solid ${theme.palette.divider}`,
      borderRadius: theme.spacing(0.8),
      cursor: 'pointer',
    },
    activePinBox: {
      border: `1px solid ${theme.palette.primary.contrastText}`,
      backgroundColor: '#F5F5FF',
      borderRadius: theme.spacing(0.8),
      cursor: 'pointer',
      '& $svg path': { fill: theme.palette.primary.contrastText },
    },
    pinnedBox: {
      backgroundColor: '#7978A5',
      padding: theme.spacing(1.4),
      paddingBlock: theme.spacing(0.4),
      maxWidth: 'fit-content',
      position: 'absolute',
      top: 12,
      right: 25,
      borderRadius: 2,
    },
    npsStats: {
      fontSize: '1.37rem',
      fontWeight: 700,
      lineHeight: theme.spacing(2.5),
    },
    userBox: {
      color: theme.palette.primary.contrastText,
      textDecoration: 'underline',
      cursor: 'pointer',
    },
    avatar: {
      height: 22,
      width: 22,
    },
    prototypeLinkText: {
      color: theme.palette.primary.contrastText,
      textDecoration: 'underline',
      wordBreak: 'break-all',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      display: '-webkit-box',
      '-webkit-line-clamp': 1,
      '-webkit-box-orient': 'vertical',
    },
    takeawayItem: {
      border: '1px solid #DFD0F1',
      borderRadius: 6,
      paddingTop: theme.spacing(1.5),
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(0.5),
    },
    thumbBox: {
      padding: theme.spacing(1),
      marginLeft: -1,
      '&:hover': { borderRadius: '50%', backgroundColor: '#f1f3f4' },
    },
  }),
);

interface IPerformanceGraph {
  questionStats: Array<QuestionStats>;
  handleInputResponseModal: (
    item: any,
    question_text: string,
    theme_name: string,
    theme_text: string,
    total_count: number,
  ) => void;
  getRuleSets?: (question_id: string) => Array<RuleItem>;
  surveyResponses?: number;
  member: IMemberDetails;
  chartData: any;
  chartQuestionIds: Array<string>;
  chartToggleHandler: (question_id: string) => void;
  surveyId: string;
  startDate: string;
  endDate: string;
  dateRange: IDateRange;
  research_type: string;
  handleUserDrawer: (e: any, user_id: string, open: boolean) => void;
  takeaways: Array<takeawayType>;
  handleOthersModal: (others_text: Array<IOthersText>) => void;
}

const npsPlottingData = (options: Array<npsData>) => {
  const npsNewData = [] as Array<npsGraphData>;

  for (let i = 0; i < options.length; i++) {
    npsNewData.push({
      x: options[i].nps_value.toString(),
      y: options[i].count,
    });
  }
  return {
    datasets: [
      {
        label: 'Count',
        data: npsNewData,
        barThickness: 20,
        backgroundColor: npsBackgroundColors,
      },
    ],
  };
};

const GraphComponent: FunctionComponent<IPerformanceGraph> = ({
  questionStats,
  handleInputResponseModal,
  getRuleSets,
  surveyResponses,
  member,
  chartToggleHandler,
  chartQuestionIds,
  chartData,
  surveyId,
  startDate,
  endDate,
  research_type,
  handleUserDrawer,
  takeaways,
  handleOthersModal,
}) => {
  const classes = useStyles();
  const common = commonStyles();
  const { w_role } = member;

  const [pinChart, setPinChart] = useState<{ search_request: ISpotlightQuery; chart_type: string }>();
  const [openPinChartToggle, togglePinChartModal] = useState(false);
  const [boardList, setBoardList] = useState<Array<IBoard>>([]);
  const [thumbsList, setThumbsList] = useState<Array<{ id: number; action: boolean }>>([]);

  const fetchBoardsList = async () => {
    const boards = await fetchAllBoard();
    if (boards) {
      setBoardList(boards);
    }
  };

  //tracking context
  const tracking_info = useSelector((state: RootState) => state.tracking);

  const progressQuestionTypes = [
    'multi_select_feedback',
    'single_select_feedback',
    'scale_feedback',
    'star_feedback',
    'emoji_feedback',
  ];

  const addChartToBoards = async (board_ids: Array<string>, chart_type: string, chart_id: string) => {
    if (tracking_info) {
      amplitudeEvent(tracking_info, 'chart pinned', {
        tab: 'spotlight',
        chart_type: chart_type,
        'chart id': chart_id,
      });
    }
    //add chart to board_ids api
    const addResult = await addChartToBoard(
      {
        board_ids,
      },
      chart_id,
    );

    if (addResult && addResult.message) {
      AlertUtil.fire({
        icon: 'success',
        title: 'chart pinned successfully',
      });
    }
  };

  const createNewChart = async (name: string, board_ids: Array<string>) => {
    if (pinChart && pinChart.search_request) {
      const { search_request, chart_type } = pinChart;
      const results = await createChartDetails({
        search_request,
        name,
        chart_type,
        spotlight_type: 'survey_details',
      });

      if (results && results.chart_id) {
        await addChartToBoards(board_ids, chart_type, results.chart_id);
      }
    }
  };

  const createTakeawayChart = async (takeaway_name: string, board_ids: Array<string>) => {
    const addResult = await createTakeawayDetails({
      survey_id: surveyId,
      board_ids,
      takeaway_name,
    });

    if (addResult) {
      AlertUtil.fire({
        icon: 'success',
        title: 'Takeaway pinned successfully',
      });
    }
  };

  const handleTakeawayPinClick = () => {
    fetchBoardsList();
    togglePinChartModal(true);
  };

  const handlePinClick = (question_type: string, question_id: string, is_chart: boolean) => {
    const chart_type = is_chart ? 'daily_chart' : 'cumulated';
    const search_request = {
      ...spotlightInitialData,
      surveys: {
        type: 'all',
        data: [surveyId],
        rule_type: 'in',
      },
      question_type,
      questions: {
        type: 'all',
        data: [question_id],
        rule_type: 'in',
      },
      date_range: {
        type: 'custom',
        days_count: 0,
        time_from: startDate,
        time_to: endDate,
      },
    };
    setPinChart({ search_request, chart_type });
    fetchBoardsList();
    togglePinChartModal(true);
  };

  const questionTypeText = (value: string) => {
    const question_text = questionTypes.find(q => q.value === value);
    return question_text?.label ? question_text?.label : '';
  };

  /**
   * chart Data tranformer
   * @param question_id question_id string
   * @returns formatted chart response
   */
  const getChartData = (question_id: string, question_type: string) => {
    const defaultChartData: any = { labels: [], datasets: [] };
    if (chartData[question_id]) {
      const { x_axis, data_groups } = chartData[question_id];
      if (x_axis && data_groups) {
        defaultChartData.labels = x_axis;
        const currDataSets: Array<any> = [];
        let hiddenCount = 0;
        for (const i in data_groups) {
          let { label } = data_groups[i];
          const { values } = data_groups[i];
          const datasets = getChartDataSets(+i, question_type === 'nps_feedback');
          let hidden = hiddenCount >= 3 && question_type === 'input_feedback' ? true : false;
          if (!hidden) {
            hiddenCount++;
          }
          if (label === 'untagged' && data_groups.length < 4) {
            hidden = false;
          }

          if (question_type === 'single_select_feedback' || question_type === 'multi_select_feedback') {
            if (label.includes('____')) {
              const option = label.split('____')[0];
              label = option;
            }
          }

          const chartItem = { ...datasets, label, data: values, hidden };
          currDataSets.push(chartItem);
        }
        defaultChartData.datasets = currDataSets;
      }
    }
    return defaultChartData;
  };

  return (
    <>
      {openPinChartToggle && (
        <PinChartModal
          toggleModal={togglePinChartModal}
          open={openPinChartToggle}
          createNewChart={pinChart && pinChart.chart_type ? createNewChart : createTakeawayChart}
          boards={boardList}
        />
      )}

      {takeaways && takeaways.length > 0 && (
        <Box className={common.takeawayBox}>
          <Box display="flex" alignItems="center" justifyContent={'space-between'} mx={2.5}>
            <Box display="flex">
              <Typography variant="subtitle1" color="black" pl={0.5} fontWeight="bold">
                Key Takeaways
              </Typography>
            </Box>
            <Box
              ml={1.2}
              px={1.08}
              pt={1.1}
              pb={0.5}
              className={common.pinBox}
              onClick={() => {
                handleTakeawayPinClick();
              }}
            >
              <PinImg />
            </Box>
          </Box>
          <Box display="flex" flexDirection={'row'} flexWrap={'nowrap'} style={{ overflowX: 'auto' }}>
            {takeaways.map(({ title, desc, job }, idx) => (
              <Box mt={1.5} width={'65%'} style={{ flex: '0 0 auto' }} className={classes.takeawayItem}>
                <Box display={'flex'} justifyContent={'space-between'}>
                  <div className={common.takeawayOrderBox}>
                    <Typography variant="subtitle2" fontWeight="bold">
                      {`Takeaway ${+idx + 1} of ${takeaways.length}`}
                    </Typography>
                  </div>
                  <div style={{ marginRight: 20 }}>
                    <Tooltip title="Helpful">
                      <IconButton className={classes.thumbBox}>
                        <ThumbUpOutlinedIcon
                          onClick={() => {
                            amplitudeEvent(tracking_info, 'takeaway', {
                              tab: 'thumbs up',
                              survey_id: surveyId,
                              order: +idx + 1,
                              takeaway: `Takeaway #${+idx + 1} - ${title}`,
                            });
                            const filterThumbsList = thumbsList.filter(t => t.id !== +idx);
                            setThumbsList([{ id: +idx, action: true }, ...filterThumbsList]);
                          }}
                          style={{
                            color: thumbsList.some(t => t.id === +idx && t.action) ? 'green' : '#5F516F',
                            cursor: 'pointer',
                            fontSize: '18px',
                          }}
                        />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Not helpful">
                      <IconButton className={classes.thumbBox}>
                        <ThumbDownOutlinedIcon
                          onClick={() => {
                            amplitudeEvent(tracking_info, 'takeaway', {
                              tab: 'thumbs down',
                              survey_id: surveyId,
                              order: +idx + 1,
                              takeaway: `Takeaway #${+idx + 1} - ${title}`,
                            });
                            const filterThumbsList = thumbsList.filter(t => t.id !== +idx);
                            setThumbsList([{ id: +idx, action: false }, ...filterThumbsList]);
                          }}
                          style={{
                            color: thumbsList.some(t => t.id === +idx && !t.action) ? 'red' : '#5F516F',
                            cursor: 'pointer',
                            fontSize: '18px',
                          }}
                        />
                      </IconButton>
                    </Tooltip>
                  </div>
                </Box>

                <Box style={{ borderRadius: 6 }}>
                  <Typography variant={'subtitle1'} p={1.5} lineHeight={1.5} color="#555" fontSize={'14px'}>
                    {title}
                  </Typography>

                  <Box display="flex" alignItems="center" pl={1.5}>
                    <img src={aiStarImg} alt="" />
                    <Typography variant="subtitle1" color="info.contrastText" pl={0.5} fontWeight={'bold'}>
                      User Expection
                    </Typography>
                  </Box>

                  <Typography
                    variant={'subtitle1'}
                    p={1.5}
                    lineHeight={1.5}
                    color="#555"
                    fontSize={'14px'}
                    fontStyle={'italic'}
                  >
                    {job}
                  </Typography>

                  {desc && (
                    <Typography
                      mt={-0.2}
                      lineHeight={1.5}
                      p={1.5}
                      style={{ backgroundColor: '#DFD0F14D', color: '#64458C', fontSize: '14px' }}
                    >
                      💡{desc}
                    </Typography>
                  )}
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
      )}

      {questionStats &&
        questionStats.map(
          (
            {
              question_id,
              question_text,
              question_type,
              question_img,
              total_count,
              options,
              response_texts,
              nps_options,
              prototype_link,
            },
            idx,
          ) => (
            <Box className={classes.questionBox} alignItems="center" key={idx}>
              <Box px={4.56} pt={5} pb={3}>
                <Box display="flex" justifyContent="space-between" alignItems="flex-start">
                  <Box>
                    <div className={classes.questionOrderBox}>
                      <Typography>#{idx + 1}</Typography>
                    </div>

                    <Typography variant={'h4'} className={classes.questionText}>
                      {question_text}
                    </Typography>
                    <Box display="inline" alignItems={'center'}>
                      <Typography variant={'subtitle1'} display="inline">
                        {questionTypeText(question_type)}
                      </Typography>
                      <BlackDotImg style={{ paddingLeft: 5, paddingRight: 5, paddingTop: 4, display: 'inline' }} />
                      <Typography variant={'subtitle1'} display="inline">
                        {total_count} Responses
                      </Typography>
                    </Box>
                    {prototype_link && (
                      <a href={getPrototypeLink(prototype_link)} target="_blank" rel="noopener noreferrer">
                        <Box display={'flex'} alignItems={'center'}>
                          <img src={prototypeLinkImg} alt="" style={{ paddingTop: 4, display: 'inline' }} />
                          <Typography variant={'subtitle1'} pl={0.5} className={classes.prototypeLinkText}>
                            {prototype_link}
                          </Typography>
                        </Box>
                      </a>
                    )}

                    {question_img && (
                      <div className={classes.questionImgContainer}>
                        <img src={question_img} alt="" className={classes.questionImg} />
                      </div>
                    )}
                  </Box>
                  {w_role === 'editor' && (
                    <Box display={'flex'} alignItems="center">
                      <Box
                        ml={1.2}
                        px={1.08}
                        pt={1.1}
                        pb={0.5}
                        className={chartQuestionIds.includes(question_id) ? classes.activePinBox : classes.pinBox}
                        onClick={() => {
                          chartToggleHandler(question_id);
                        }}
                      >
                        <ChartImg style={{ width: '16px', height: '16px' }} />
                      </Box>
                      <Box
                        ml={1.2}
                        px={1.08}
                        pt={1.1}
                        pb={0.5}
                        className={classes.pinBox}
                        onClick={() =>
                          handlePinClick(question_type, question_id, chartQuestionIds.includes(question_id))
                        }
                      >
                        <PinImg />
                      </Box>
                    </Box>
                  )}
                </Box>
              </Box>

              {/* continous survey chart logic */}
              {chartQuestionIds.includes(question_id) ? (
                <Box px={4.56} pb={5.12}>
                  <Line
                    height={100}
                    data={getChartData(question_id, question_type)}
                    options={{
                      ...chartDataOptions(false),
                      onClick: function(evt, el) {},
                    }}
                  />
                </Box>
              ) : (
                <div>
                  {progressQuestionTypes.includes(question_type) && (
                    <Box px={4.56} pb={1.37} alignItems="center">
                      {options &&
                        options.length > 0 &&
                        options.map(
                          (
                            { label, option_text, percent, count, has_text_input = false, select_input_texts = [] },
                            idx,
                          ) => (
                            <LinearProgressWithLabel
                              value={percent * 100}
                              label={label ? label : option_text}
                              count={count}
                              key={idx}
                              has_text_input={has_text_input}
                              handleOthersModal={handleOthersModal}
                              select_input_texts={select_input_texts}
                            />
                          ),
                        )}
                    </Box>
                  )}

                  {question_type === 'matrix_feedback' && (
                    <Box px={4.56} pb={1.37} alignItems="center">
                      {options &&
                        options.length > 0 &&
                        options.map(({ option_text, columns = [] }, idx) => (
                          <Box mb={2}>
                            <Typography variant={'body1'} mb={1.2}>
                              {option_text}
                            </Typography>
                            {columns.map(({ label, value, percent, count }, idx) => (
                              <LinearProgressWithLabel
                                value={percent * 100}
                                label={value}
                                count={count}
                                key={idx}
                                has_text_input={false}
                                handleOthersModal={handleOthersModal}
                                select_input_texts={[]}
                              />
                            ))}
                          </Box>
                        ))}
                    </Box>
                  )}

                  {question_type === 'input_feedback' && response_texts && response_texts.length > 0 && (
                    <Box px={4.56} pb={5.12}>
                      {research_type === 'prototype' &&
                      response_texts &&
                      response_texts[0].theme_name === 'untagged' ? (
                        <TableContainer className={classes.prototypeInputTable} style={{ minWidth: 'unset' }}>
                          <Table aria-label="caption table">
                            <TableHead>
                              <TableRow>
                                <TableCell width={'30%'}>
                                  <Typography variant={'subtitle2'} color={'text.secondary'}>
                                    USER
                                  </Typography>
                                </TableCell>
                                <TableCell width={'45%'}>
                                  <Typography variant={'subtitle2'} color={'text.secondary'}>
                                    RESPONSE
                                  </Typography>
                                </TableCell>
                                <TableCell width={'25%'}>
                                  <Typography variant={'subtitle2'} color={'text.secondary'}>
                                    TIME
                                  </Typography>
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {response_texts[0].responses.map(({ user_id, survey_input, created_at }, idx) => (
                                <TableRow key={idx} hover>
                                  <TableCell>
                                    <Box
                                      display="flex"
                                      alignItems={'center'}
                                      className={!user_id.startsWith('anonymous') ? classes.userBox : ''}
                                    >
                                      <Box className={classes.avatar}>
                                        <img src={'https://cdn.blitzllama.com/default/default-img.svg'} alt="" />
                                      </Box>
                                      <Typography
                                        variant={'subtitle1'}
                                        width={'18ch'}
                                        pl={0.5}
                                        noWrap
                                        onClick={e =>
                                          !user_id.startsWith('anonymous') && handleUserDrawer(e, user_id, true)
                                        }
                                      >
                                        {user_id}
                                      </Typography>
                                    </Box>
                                  </TableCell>
                                  <TableCell>{survey_input}</TableCell>
                                  <TableCell>{modifiedDate(created_at, 'lll')}</TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      ) : (
                        <TableContainer className={classes.table} style={{ minWidth: 'unset' }}>
                          <Table aria-label="caption table">
                            <TableHead>
                              <TableRow>
                                <TableCell width={'30%'}>
                                  <Typography variant={'subtitle2'} color={'text.secondary'}>
                                    TOPICS
                                  </Typography>
                                </TableCell>
                                <TableCell width={'20%'}>
                                  <Typography variant={'subtitle2'} color={'text.secondary'}>
                                    RESPONSES
                                  </Typography>
                                </TableCell>
                                <TableCell width={'20%'}>
                                  <Typography variant={'subtitle2'} color={'text.secondary'}>
                                    SENTIMENT
                                  </Typography>
                                </TableCell>
                                <TableCell width={'20%'}>
                                  <Typography variant={'subtitle2'} color={'text.secondary'}>
                                    RECOMMENDATION
                                  </Typography>
                                </TableCell>
                                <TableCell width={'10%'}></TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {response_texts &&
                                response_texts.map(
                                  (
                                    { theme_name, theme_text, theme_responses_count, sentiment, action, responses },
                                    idx,
                                  ) => (
                                    <TableRow key={idx} hover>
                                      <TableCell>
                                        <Typography
                                          variant={'subtitle1'}
                                          width={'20ch'}
                                          style={{ wordBreak: 'break-word' }}
                                        >
                                          {theme_name}
                                        </Typography>
                                      </TableCell>
                                      <TableCell>{theme_responses_count}</TableCell>
                                      <TableCell>
                                        <Box
                                          display="flex"
                                          alignItems="center"
                                          justifyContent="center"
                                          className={classes.sentimentBox}
                                          style={getSentimentTheme(sentiment)}
                                        >
                                          {sentimentIcon[sentiment]}
                                          <Typography variant={'subtitle2'} pl={0.5}>
                                            {getModifiedText(sentiment)}
                                          </Typography>
                                        </Box>
                                      </TableCell>
                                      <TableCell>
                                        <Box
                                          display="flex"
                                          alignItems="center"
                                          className={classes.actionBox}
                                          style={{ color: actionTheme[action] }}
                                        >
                                          {actionIcon[action]}
                                          <Typography variant={'subtitle2'} pl={0.5} noWrap>
                                            {getModifiedText(action)}
                                          </Typography>
                                        </Box>
                                      </TableCell>
                                      <TableCell style={{ textAlign: 'right' }}>
                                        <Box
                                          ml={0.5}
                                          onClick={e => {
                                            e.stopPropagation();
                                            handleInputResponseModal(
                                              responses,
                                              question_text,
                                              theme_name,
                                              theme_text,
                                              theme_responses_count,
                                            );
                                          }}
                                          style={{ cursor: 'pointer' }}
                                        >
                                          <img src={rightArrowButton} alt="" />
                                        </Box>
                                      </TableCell>
                                    </TableRow>
                                  ),
                                )}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      )}
                    </Box>
                  )}

                  {question_type === 'data_collection' && (
                    <div>
                      <Box px={4.56} pb={5.12}>
                        <Box width={1} display="flex" alignItems="center">
                          <div>
                            <Typography variant={'h4'}>{total_count}</Typography>
                            <Typography variant={'subtitle1'}>ENTRIES</Typography>
                          </div>
                          <div style={{ paddingLeft: 30 }}>
                            <Typography variant={'h4'}>
                              {surveyResponses ? `${((total_count / surveyResponses) * 100).toFixed(1)}%` : '0%'}
                            </Typography>

                            <Typography variant={'subtitle1'}>RESPONDENTS</Typography>
                          </div>
                        </Box>
                      </Box>
                    </div>
                  )}

                  {question_type === 'nps_feedback' && options && (
                    <div>
                      <Box px={4.56} mt={-1} pb={5}>
                        <Box display="flex" alignItems="center" justifyContent="space-between">
                          <div>
                            <Typography variant={'h2'} style={{ color: '#F36A83' }}>
                              {getNps(options)}
                            </Typography>
                            <Typography variant={'subtitle1'}>NPS SCORE</Typography>
                          </div>
                          <div>
                            <Bar data={npsPlottingData(nps_options)} width={500} options={graphOptions} />
                          </div>
                        </Box>
                      </Box>
                      <Box px={4} pb={5}>
                        <Box display="flex" justifyContent="space-between">
                          {options.map(({ label, count, percent }, idx) => (
                            <div key={idx}>
                              <Typography className={classes.npsStats}>{(percent * 100).toFixed(1)}%</Typography>
                              <Typography variant={'subtitle1'}>
                                {label} ({count})
                              </Typography>
                            </div>
                          ))}
                          <div style={{ marginTop: 'auto', width: '35%' }}>
                            <Typography variant={'subtitle2'}>NPS SCORE = % Promoters - % Detractors</Typography>
                          </div>
                        </Box>
                      </Box>
                    </div>
                  )}

                  {question_type === 'intro_prompt' && (
                    <div>
                      <Box px={4.56} pb={5.12}>
                        <Box width={1} display="flex" alignItems="center">
                          <div>
                            <Typography variant={'h4'}>{total_count}</Typography>
                            <Typography variant={'subtitle1'}>CLICKS</Typography>
                          </div>
                          <div style={{ paddingLeft: 30 }}>
                            <Typography variant={'h4'}>
                              {surveyResponses ? `${((total_count / surveyResponses) * 100).toFixed(1)}%` : '0%'}
                            </Typography>

                            <Typography variant={'subtitle1'}>RESPONDENTS CLICKED</Typography>
                          </div>
                        </Box>
                      </Box>
                    </div>
                  )}
                </div>
              )}

              {getRuleSets !== undefined && getRuleSets(question_id).length > 0 && (
                <Box px={4.56} pb={5.12} alignItems="center">
                  <Typography variant={'subtitle1'}>If the answer to this question :</Typography>
                  <div style={{ borderBottom: '1px solid rgba(255, 255, 255, 0.05)', marginBlock: 12 }}></div>
                  {getRuleSets(question_id).map(({ rule_type, option, options, skip_to }, id) => (
                    <>
                      <Box display="flex" mt={2} alignItems="center">
                        <ArrowImg />
                        <Typography
                          display="inline"
                          variant="subtitle2"
                          style={{ marginLeft: 5, whiteSpace: 'nowrap' }}
                        >
                          {getRuleType(rule_type)}
                        </Typography>
                        {getRuleType(rule_type) !== 'On submit' && (
                          <>
                            {options && options.length > 0 && (
                              <Typography
                                maxWidth={'25ch'}
                                display="inline"
                                style={{ marginLeft: 5, fontSize: 12 }}
                                noWrap
                              >
                                {options.map((item, i) => (options.length > 1 ? `${item}, ` : `${item}`))}
                              </Typography>
                            )}
                            {option && (
                              <Typography display="inline" style={{ marginLeft: 5, fontSize: 12 }}>
                                {option}
                              </Typography>
                            )}
                          </>
                        )}

                        <Typography
                          display="inline"
                          variant="subtitle2"
                          style={{ marginLeft: 5, whiteSpace: 'nowrap' }}
                        >
                          then jump to
                        </Typography>
                        <div className={classes.conditionOrder}>
                          <Typography variant={'subtitle2'}>
                            {skip_to === -1 ? 'End of survey' : `#${skip_to}`}
                          </Typography>
                        </div>
                        <Typography variant={'subtitle2'} ml={0.8}>
                          {questionStats[skip_to - 1]?.question_text}
                        </Typography>
                      </Box>
                      {id < getRuleSets(question_id).length - 1 && (
                        <div style={{ borderBottom: '1px solid rgba(255, 255, 255, 0.05)', marginBlock: 12 }}></div>
                      )}
                    </>
                  ))}
                </Box>
              )}
            </Box>
          ),
        )}
    </>
  );
};

export default GraphComponent;
