import React, { useEffect, useState } from 'react';
import copy from 'copy-to-clipboard';
import url from 'url';
import {
  Box,
  Typography,
  Drawer,
  Button,
  Theme,
  TextField,
  InputAdornment,
  Select,
  MenuItem,
  IconButton,
  Checkbox,
} from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import deleteImg from '../../images/delete-icon.svg';
import binImg from '../../images/editor/delete-icon.svg';
import arrowImg from '../../images/connection/blue-arrow.svg';
import copyImg from '../../images/connection/copy-icon.svg';
import dotImg from '../../images/connection/green-dot.svg';

import {
  createNewConnection,
  createSlackConnection,
  createWebhookConnection,
  deleteWebhook,
  disableConnection,
  disableSlackChannel,
  editWebhook,
  fetchSlackChannel,
  fetchWebhookList,
  updateSlackChannel,
} from '../../services/connectionService';
import AlertUtil from '../../utils/AlertUtil';
import { connectedMainPlatforms, connectedOtherPlatforms } from '../../static/connectionData';
import { modifiedDate } from '../../utils/DateUtil';
import CONFIG from '../../config';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { IConnectionData, IWebhook } from '../../types/connection';
import { CopiedTextTooltip } from '../../styles/customStyles';
import _, { capitalize } from 'lodash';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawerPaper: {
      backgroundColor: theme.palette.background.default,
    },

    deleteImg: {
      paddingLeft: theme.spacing(4),
      height: '36px',
      cursor: 'pointer',
    },
    border: {
      paddingTop: theme.spacing(5),
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
    borderLeft: {
      borderLeft: `1px solid ${theme.palette.divider}`,
      height: 'calc(100vh - 300px)',
    },

    disconnectButton: {
      background: theme.palette.background.default,
      border: '1px solid #FF7C94',
      color: '#FF7C94',
      '&:hover': {
        background: theme.palette.background.default,
        color: '#FF7C94',
      },
    },
    greenBox: {
      background: '#3ABD8E1A',
      display: 'flex',
      alignItems: 'center',
      color: '#3ABD8E',
      padding: theme.spacing(2),
      paddingBlock: theme.spacing(1.2),
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(1.5),
    },
    textFieldBox: {
      backgroundColor: theme.palette.secondary.light,
      padding: theme.spacing(2.5),
      paddingBlock: theme.spacing(1),
      borderRadius: theme.spacing(1),
      border: `1px solid ${theme.palette.divider}`,
      display: 'flex',
      justifyContent: 'space-between',
      overflowY: 'scroll',
      whiteSpace: 'nowrap',
    },
    copyBox: {
      cursor: 'pointer',
      background: 'rgba(34, 238, 226, 0.05)',
      border: `1px solid ${theme.palette.primary.contrastText}`,
      color: theme.palette.primary.contrastText,
      borderRadius: 5,
      padding: theme.spacing(1.5),
      paddingBlock: theme.spacing(0.6),
      textTransform: 'none',
    },

    copiedIcon: {
      color: theme.palette.primary.contrastText,
    },
    connectionTextField: {
      '& .MuiOutlinedInput-input': { padding: '0.75rem 0.87rem' },
      '& .MuiSvgIcon-root': {
        color: theme.palette.primary.contrastText,
      },
    },
    selectField: {
      border: `1px solid ${theme.palette.divider}`,
      width: '60%',
      '& .MuiOutlinedInput-notchedOutline': {
        border: 0,
        borderRadius: 0,
      },
      '& .MuiOutlinedInput-input': { padding: '0.35rem 0.5rem' },
    },
    check: {
      margin: 0,
      padding: 0,
      color: '1px solid rgba(255, 255, 255, 0.05)',
      '&$checked': {
        color: theme.palette.primary.contrastText,
      },
    },
    checked: {},
  }),
);

const getStreamUrl = (type: string) => {
  if (type === 'segment_stream') {
    return 'https://api.segment.io/v1/track';
  }

  if (type === 'amplitude_destination') {
    return 'https://api2.amplitude.com/2/httpapi';
  }

  if (type === 'clevertap_destination') {
    return 'https://api.clevertap.com/1/upload';
  }

  if (type === 'mixpanel_destination') {
    return 'https://api.mixpanel.com/import?strict=1&project_id=';
  }

  return '';
};

const initialState = [
  { webhook_url: '', webhook_secret: '', webhook_id: '', type: 'default', webhook_type: 'default' },
];

export default function ConnectionDrawer(props: {
  anchor: 'right';
  toggleDrawer: (event: React.KeyboardEvent | React.MouseEvent) => void;
  rightState: boolean;
  fetchConnections: () => void;
  connectionItem: IConnectionData;
  copyButtonText: string;
  setCopyButtonText: (string) => void;
  orgPlan: string;
}) {
  const {
    anchor,
    toggleDrawer,
    rightState,
    fetchConnections,
    connectionItem,
    copyButtonText,
    setCopyButtonText,
    orgPlan,
  } = props;
  const classes = useStyles();
  const [copiedText, setCopiedText] = useState('');
  const [channel, setChannel] = useState('');
  const [webhookList, setWebhookList] = useState<Array<IWebhook>>(initialState);
  const [slackChannels, setSlackChannels] = useState<Array<string>>([]);
  const [slackResponseAlert, setSlackResponseAlert] = useState<boolean>(false);
  const [message, setMessage] = useState('');
  const isConnected = connectionItem.connection_key ? true : false;

  const [showSecret, setShowSecret] = useState(false);

  //mixpanel destination
  const [projectId, setProjectId] = useState('');
  const [mixpanelUsername, setMixpanelUsername] = useState('');
  const [mixpanelUserSecret, setMixpanelUserSecret] = useState('');

  const [edit, setEdit] = useState(false);

  // get webhook list
  const fetchAllWebhooks = async () => {
    const webhooks = await fetchWebhookList();

    if (connectionItem.connection_value === 'webhook') {
      const filtered_webhooks = webhooks.filter(w => w.webhook_type === 'default');
      if (filtered_webhooks.length !== 0) {
        setWebhookList([...filtered_webhooks]);
      } else {
        setWebhookList([
          ...[
            {
              webhook_url: '',
              webhook_secret: '',
              webhook_id: '',
              type: 'default',
              webhook_type: 'default',
            },
          ],
        ]);
      }
    }

    if (
      connectionItem.connection_value === 'segment_stream' ||
      connectionItem.connection_value === 'mixpanel_destination' ||
      connectionItem.connection_value === 'amplitude_destination' ||
      connectionItem.connection_value === 'clevertap_destination' ||
      connectionItem.connection_value === 'rudderstack_destination'
    ) {
      const stream_item = webhooks.find(w => w.webhook_type === connectionItem.connection_value);
      if (stream_item) {
        setWebhookList([...[stream_item]]);

        if (
          connectionItem.connection_value === 'mixpanel_destination' ||
          connectionItem.connection_value === 'clevertap_destination'
        ) {
          const url_parts = url.parse(stream_item.webhook_url, true);
          const query = url_parts.query;
          const existing_project_id = query && query.project_id ? query.project_id.toString() : '';
          setProjectId(existing_project_id);

          if (stream_item.webhook_secret) {
            const service_username = stream_item.webhook_secret.split(':')[0];
            const service_secret = stream_item.webhook_secret.split(':')[1]
              ? stream_item.webhook_secret.split(':')[1]
              : '';

            setMixpanelUsername(service_username);
            setMixpanelUserSecret(service_secret);
          }
        }
      } else {
        setWebhookList([
          ...[
            {
              webhook_url: getStreamUrl(connectionItem.connection_value),
              webhook_secret: '',
              webhook_id: '',
              type: 'default',
              webhook_type: connectionItem.connection_value,
            },
          ],
        ]);
      }
    }

    if (
      connectionItem.connection_value === 'freshdesk' ||
      connectionItem.connection_value === 'zendesk' ||
      connectionItem.connection_value === 'intercom'
    ) {
      const stream_item = webhooks.find(w => w.webhook_type === connectionItem.connection_value);
      if (stream_item) {
        setWebhookList([...[stream_item]]);
      }
    }
  };

  useEffect(() => {
    const fetchAllSlackChannels = async () => {
      const data = await fetchSlackChannel();
      if (data) {
        const { channel_names = [], alert_every_response = false } = data;
        setSlackChannels(channel_names);
        setSlackResponseAlert(alert_every_response);
      }
    };

    // if the connection item is slack
    if (connectionItem.connection_value === 'slack') {
      fetchAllSlackChannels();
      const channelItem = connectionItem.channel ? connectionItem.channel : '';
      setChannel(channelItem);
    }

    // if the connection item is webhook
    if (
      connectionItem.connection_value === 'webhook' ||
      connectionItem.connection_value === 'segment_stream' ||
      connectionItem.connection_value === 'mixpanel_destination' ||
      connectionItem.connection_value === 'amplitude_destination' ||
      connectionItem.connection_value === 'clevertap_destination' ||
      connectionItem.connection_value === 'rudderstack_destination' ||
      connectionItem.connection_value === 'freshdesk' ||
      connectionItem.connection_value === 'zendesk' ||
      connectionItem.connection_value === 'intercom'
    ) {
      fetchAllWebhooks();
    }
  }, []);

  function copyText(str: string) {
    copy(str);
    setCopyButtonText('copied');
  }

  function copyUserDetails(str: string, copied_type: string) {
    copy(str);
    setCopiedText(copied_type);
    setTimeout(() => setCopiedText(''), 1000);
  }

  // create connection for all the platforms except slack and webhook
  const createConnection = async (id: string) => {
    const newConnection = await createNewConnection(id, {});

    if (id === 'amplitude' && orgPlan === 'company') {
      await createNewConnection('amplitude_stream', {});
    }

    if (id === 'segment' && orgPlan === 'company') {
      await createNewConnection('segment_event_stream', {});
    }

    // if (id === 'mixpanel' && orgPlan === 'company') {
    //   await createNewConnection('mixpanel_stream', {});
    // }

    if (newConnection && newConnection.data) {
      AlertUtil.fire({
        icon: 'success',
        title: 'Connected Successfully',
      });

      fetchConnections();
    }
  };

  // handle remove connection for all the platforms except slack and webhook
  const removeConnection = async (id: string) => {
    const disableResult = await disableConnection(id, {});

    if (id === 'amplitude') {
      await disableConnection('amplitude_stream', {});
    }

    if (id === 'segment') {
      await disableConnection('segment_event_stream', {});
    }

    // if (id === 'mixpanel') {
    //   await disableConnection('mixpanel_stream', {});
    // }

    //delete webhook too if segment_stream
    if (
      connectionItem.connection_value === 'segment_stream' ||
      connectionItem.connection_value === 'mixpanel_destination' ||
      connectionItem.connection_value === 'amplitude_destination' ||
      connectionItem.connection_value === 'clevertap_destination'
    ) {
      if (webhookList && webhookList[0] && webhookList[0].webhook_id) {
        await deleteWebhook(webhookList[0].webhook_id, {});
        setWebhookList([
          ...[
            {
              webhook_url: getStreamUrl(connectionItem.connection_value),
              webhook_secret: '',
              webhook_id: '',
              type: 'default',
              webhook_type: connectionItem.connection_value,
            },
          ],
        ]);
      }
    }
    if (disableResult && disableResult.data) {
      AlertUtil.fire({
        icon: 'success',
        title: 'Disconnected Successfully',
      });
      fetchConnections();
    }
  };

  // handle new slack connection
  const newSlackConnection = async () => {
    const newConnection = await createSlackConnection();
    if (newConnection && newConnection.data) {
      const slack_btn_url = `${CONFIG.slack_redirect_url}${newConnection.data}`;

      window.open(slack_btn_url, '_blank');
    }
  };

  // Remove slack connection
  const removeSlackConnection = async () => {
    const removeConnection = await disableSlackChannel();
    if (removeConnection && removeConnection.data) {
      AlertUtil.fire({
        icon: 'success',
        title: 'Disconnected Successfully',
      });
      fetchConnections();
    }
  };

  // update slack channel
  const updateChannel = async () => {
    const channelValue = await updateSlackChannel({ channel, alert_every_response: slackResponseAlert });
    if (channelValue) {
      setMessage('Updated Successfully');
      fetchConnections();
      setTimeout(() => setMessage(''), 2000);
    }
  };

  // handle webhook url
  const handleWebhookUrl = (idx: number, value: string) => {
    webhookList[+idx].webhook_url = value;
    setWebhookList([...webhookList]);
  };

  // handle webhook secret
  const handleWebhookSecret = (idx: number, value: string) => {
    webhookList[+idx].webhook_secret = value;
    setWebhookList([...webhookList]);
  };

  // handle webhook type
  const handleWebhookType = (idx: number, value: string) => {
    webhookList[+idx].type = value;
    setWebhookList([...webhookList]);
  };

  // handle mixpanel url
  const handleMixpanelProjectId = (idx: number, value: string) => {
    webhookList[+idx].webhook_url = getStreamUrl(connectionItem.connection_value) + value.trim();
    setWebhookList([...webhookList]);
    setProjectId(value);
  };

  const handleMixpanelValues = (idx: number, type: string, value: string) => {
    if (type === 'user_name') {
      setMixpanelUsername(value);
      webhookList[+idx].webhook_secret = value.trim() + ':' + mixpanelUserSecret;
    } else {
      setMixpanelUserSecret(value);
      webhookList[+idx].webhook_secret = mixpanelUsername + ':' + value.trim();
    }
    setWebhookList([...webhookList]);
  };

  // handle delete webhook connection
  const handleDeleteWebhook = async (idx: number, webhook_id: string) => {
    if (webhook_id === '') {
      webhookList.splice(idx, 1);
    } else {
      const deleteItem = await deleteWebhook(webhook_id, {});
      if (!deleteItem && !deleteItem.data) {
        return;
      }
    }

    setMessage('Deleted successfully');
    setTimeout(() => setMessage(''), 2000);

    await fetchAllWebhooks();
  };

  const getConnectionName = (conn: string) => {
    if (!conn) {
      return '';
    }

    if (conn === 'segment_stream') {
      return 'Segment Destination';
    }

    if (conn === 'mixpanel_destination') {
      return 'Mixpanel Destination';
    }

    if (conn === 'amplitude_destination') {
      return 'Amplitude';
    }

    if (conn === 'amplitude_stream') {
      return 'Amplitude Stream';
    }

    if (conn === 'mixpanel_stream') {
      return 'Mixpanel Stream';
    }

    if (conn === 'clevertap_destination') {
      return 'CleverTap';
    }

    if (conn === 'rudderstack_destination') {
      return 'Rudderstack';
    }

    if (conn === 'freshdesk') {
      return 'Freshdesk';
    }

    if (conn === 'zendesk') {
      return 'Zendesk';
    }

    if (conn === 'intercom') {
      return 'Intercom';
    }

    return conn;
  };

  const handleWebhookCreation = async (
    webhook_id: string,
    webhook_url: string,
    webhook_secret: string,
    webhook_type: string,
    type: string,
  ) => {
    webhook_url = webhook_type === 'intercom' ? 'https://api.intercom.io' : webhook_url;
    if (webhook_id) {
      const editResult = await editWebhook(webhook_id, {
        webhook_url,
        webhook_secret,
        webhook_type,
        type,
      });
      if (editResult && editResult.message) {
        setMessage('Saved successfully');
        setTimeout(() => setMessage(''), 2000);
      }
    } else {
      const createResult = await createWebhookConnection({
        webhook_url,
        webhook_secret,
        webhook_type,
        type,
      });
      if (createResult && createResult.message) {
        setMessage('Created successfully');
        setTimeout(() => setMessage(''), 2000);
      }
    }
    setEdit(false);
    await fetchAllWebhooks();
  };

  const closeDrawer = e => {
    setWebhookList([]);
    toggleDrawer(e);
  };

  const copyURLTextBox = (text: string) => {
    return (
      <TextField
        fullWidth
        className={classes.connectionTextField}
        value={text}
        InputProps={{
          readOnly: true,
          endAdornment: (
            <InputAdornment position="end">
              <CopiedTextTooltip
                arrow
                PopperProps={{
                  disablePortal: true,
                }}
                placement="top"
                open={copiedText === 'url'}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                title="Copied"
              >
                <ContentCopyIcon
                  style={{ cursor: 'pointer', color: copiedText === 'url' ? '#201CE0' : '' }}
                  onClick={() => {
                    copyUserDetails(text ? text : '', 'url');
                  }}
                />
              </CopiedTextTooltip>
            </InputAdornment>
          ),
        }}
      />
    );
  };

  const copyKeyValue = (connection_key: string) => {
    return (
      <Box width={1}>
        <Typography variant={'body1'} mb={1}>
          {connectionItem.connection_secret ? 'Username' : 'API Key'}
        </Typography>
        <TextField
          fullWidth
          className={classes.connectionTextField}
          value={connection_key}
          InputProps={{
            readOnly: true,
            endAdornment: (
              <InputAdornment position="end">
                <CopiedTextTooltip
                  arrow
                  PopperProps={{
                    disablePortal: true,
                  }}
                  placement="top"
                  open={copiedText === 'user_name'}
                  disableFocusListener
                  disableHoverListener
                  disableTouchListener
                  title="Copied"
                >
                  <ContentCopyIcon
                    style={{
                      cursor: 'pointer',
                      color: copiedText === 'user_name' ? '#201CE0' : '',
                    }}
                    onClick={() => {
                      copyUserDetails(connection_key ? connection_key : '', 'user_name');
                    }}
                  />
                </CopiedTextTooltip>
              </InputAdornment>
            ),
          }}
        />
      </Box>
    );
  };

  return (
    <Drawer
      anchor={anchor}
      open={rightState}
      onClose={closeDrawer}
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <Box pt={5} sx={{ width: 800 }}>
        <img src={deleteImg} alt="" onClick={closeDrawer} className={classes.deleteImg} />
        <Box mt={1} ml={10} mr={9}>
          <Box display="flex">
            <img src={connectionItem?.connection_img} alt="" style={{ width: '81px', height: '81px' }} />

            <Box width="100%" ml={3} mr={1}>
              <Typography variant={'h4'}>{connectionItem?.connection_title}</Typography>
              <Typography lineHeight={1.5} mt={0.2}>
                {connectionItem?.connection_intro}
              </Typography>
            </Box>
          </Box>
          {connectedOtherPlatforms.includes(connectionItem?.connection_value) && (
            <>
              {isConnected && (
                <Box className={classes.greenBox}>
                  <img src={dotImg} alt="" />
                  <Typography style={{ fontSize: '0.8rem', fontWeight: 700, paddingLeft: '8px' }}>
                    {connectionItem.last_active_at
                      ? ` Last activity ${modifiedDate(new Date(connectionItem.last_active_at).toString(), '', true)}`
                      : `Your blitzllama account is connected with ${getConnectionName(
                          connectionItem.connection_value,
                        )}`}
                  </Typography>
                </Box>
              )}

              {connectionItem.connection_value !== 'webhook' && (
                <>
                  {!isConnected ? (
                    <Button
                      variant="contained"
                      fullWidth
                      style={{ marginTop: '36px' }}
                      onClick={() =>
                        connectionItem.connection_value === 'slack'
                          ? newSlackConnection()
                          : createConnection(connectionItem?.connection_value)
                      }
                    >
                      Connect source
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      fullWidth
                      className={classes.disconnectButton}
                      onClick={() =>
                        connectionItem.connection_value === 'slack'
                          ? removeSlackConnection()
                          : removeConnection(connectionItem?.connection_value)
                      }
                    >
                      Disable
                    </Button>
                  )}
                </>
              )}
            </>
          )}
        </Box>

        {isConnected && connectedMainPlatforms.includes(connectionItem.connection_value) && (
          <>
            <div className={classes.border}></div>
            <Box pl={9} pr={9} pt={4}>
              <Typography variant={'h6'}>
                <span role="img" aria-label="emoji">
                  🔌
                </span>
                {connectionItem.key}
              </Typography>
              <Typography variant="subtitle1">
                {connectionItem.key} to identify your product from Blitzllama’s SDK{' '}
              </Typography>
              <Box display="flex" alignItems="center" mt={1.6}>
                <Box width={3 / 4} mr={2} className={classes.textFieldBox}>
                  <Typography>{connectionItem.connection_key}</Typography>
                </Box>
                <Box display="flex">
                  <Button
                    variant={'contained'}
                    onClick={() => copyText(connectionItem.connection_key ? connectionItem.connection_key : '')}
                  >
                    <img src={copyImg} alt="" />
                    <Typography style={{ paddingLeft: 5 }}>{copyButtonText}</Typography>
                  </Button>
                </Box>
              </Box>
            </Box>
          </>
        )}

        {isConnected &&
          connectedOtherPlatforms.includes(connectionItem.connection_value) &&
          connectionItem.connection_value !== 'slack' &&
          connectionItem.connection_value !== 'segment_stream' &&
          connectionItem.connection_value !== 'mixpanel_destination' &&
          connectionItem.connection_value !== 'amplitude_destination' &&
          connectionItem.connection_value !== 'clevertap_destination' &&
          connectionItem.connection_value !== 'rudderstack_destination' &&
          connectionItem.connection_value !== 'freshdesk' &&
          connectionItem.connection_value !== 'zendesk' &&
          connectionItem.connection_value !== 'intercom' && (
            <>
              <div className={classes.border}></div>
              <Box pl={9} pr={9} pt={4}>
                <Typography variant="h6" mb={2}>
                  Use the following details to connect your {connectionItem.connection_title} to Blitzllama
                </Typography>
                {connectionItem.connection_url && (
                  <Typography variant={'body1'} mb={1}>
                    Url
                  </Typography>
                )}
                {connectionItem.connection_url && copyURLTextBox(connectionItem.connection_url)}
                {copyKeyValue(connectionItem.connection_key ? connectionItem.connection_key : '')}
                <Box display="flex" alignItems="center" mt={2}>
                  {connectionItem.connection_secret && (
                    <Box ml={2} width={3 / 4}>
                      <Typography variant={'body1'} mb={1}>
                        Password
                      </Typography>
                      <TextField
                        fullWidth
                        className={classes.connectionTextField}
                        value={connectionItem.connection_secret}
                        InputProps={{
                          readOnly: true,
                          endAdornment: (
                            <InputAdornment position="end">
                              <CopiedTextTooltip
                                arrow
                                PopperProps={{
                                  disablePortal: true,
                                }}
                                placement="top"
                                open={copiedText === 'password'}
                                disableFocusListener
                                disableHoverListener
                                disableTouchListener
                                title="Copied"
                              >
                                <ContentCopyIcon
                                  style={{
                                    cursor: 'pointer',
                                    color: copiedText === 'password' ? '#201CE0' : '',
                                  }}
                                  onClick={() => {
                                    copyUserDetails(
                                      connectionItem.connection_secret ? connectionItem.connection_secret : '',
                                      'password',
                                    );
                                  }}
                                />
                              </CopiedTextTooltip>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Box>
                  )}

                  {(connectionItem.connection_value === 'amplitude' || connectionItem.connection_value === 'segment') &&
                    orgPlan === 'company' && (
                      <Box display={'flex'} flexDirection={'column'}>
                        <Typography variant="h6" mb={2}>
                          Use the following details to get {capitalize(connectionItem.connection_value)} Stream events
                          to Blitzllama
                        </Typography>
                        <Typography variant={'body1'} mb={1}>
                          Stream URL
                        </Typography>
                        {copyURLTextBox(
                          `https://events.blitzllama.com/api/v1/connections/${connectionItem.connection_value}/hook/event`,
                        )}

                        {copyKeyValue(connectionItem.stream_key ? connectionItem.stream_key : '')}
                      </Box>
                    )}

                  {(connectionItem.connection_value === 'amplitude' ||
                    connectionItem.connection_value === 'segment' ) &&
                    orgPlan !== 'company' && (
                      <Box
                        width={'100%'}
                        display={'flex'}
                        flexDirection={'column'}
                        style={{ border: '1px solid #e8e8e8', borderRadius: 8 }}
                        p={2}
                      >
                        <Typography textAlign={'center'} variant={'body1'} mb={1} color={'gray'}>
                          {capitalize(connectionItem.connection_value)} Event Stream is only available in Scale Plan
                        </Typography>
                        <Typography variant={'subtitle2'} textAlign={'center'} color={'gray'}>
                          Contact the Support Chat to upgrade Plan
                        </Typography>
                      </Box>
                    )}
                </Box>
              </Box>
            </>
          )}

        {connectionItem.connection_value === 'webhook' && (
          <div>
            {webhookList.map(({ webhook_url, webhook_secret, webhook_id, webhook_type, type }, idx) => (
              <>
                <div className={classes.border}></div>
                <Box pl={9} pr={9} pt={4} key={idx}>
                  <Box width={1} mb={2}>
                    <Typography variant={'body1'} mb={1.5}>
                      Webhook events to listen to:
                    </Typography>
                    {!edit && webhook_id !== '' ? (
                      <Box display="flex" alignItems="center">
                        <Checkbox
                          classes={{
                            root: classes.check,
                            checked: classes.checked,
                          }}
                          checked={true}
                        />
                        <Typography style={{ paddingLeft: 5, cursor: 'pointer' }}>
                          {type === 'default' ? 'Individual survey response' : ' Completed Survey Response Group'}
                        </Typography>
                      </Box>
                    ) : (
                      <Box display="flex" alignItems="center">
                        <Box display="flex" alignItems="center" onClick={() => handleWebhookType(idx, 'default')}>
                          <Checkbox
                            classes={{
                              root: classes.check,
                              checked: classes.checked,
                            }}
                            checked={type === 'default'}
                          />
                          <Typography style={{ paddingLeft: 5, cursor: 'pointer' }}>
                            Individual survey response
                          </Typography>
                        </Box>

                        <Box
                          display="flex"
                          alignItems="center"
                          pl={4}
                          onClick={() => handleWebhookType(idx, 'response_group')}
                        >
                          <Checkbox
                            classes={{
                              root: classes.check,
                              checked: classes.checked,
                            }}
                            checked={type === 'response_group'}
                          />
                          <Typography style={{ paddingLeft: 5, cursor: 'pointer' }}>
                            Completed Survey Response Group
                          </Typography>
                        </Box>
                      </Box>
                    )}
                  </Box>
                  <Typography variant={'body1'} mb={1}>
                    Webhook Url
                  </Typography>

                  <TextField
                    fullWidth
                    disabled={!edit && webhook_id !== ''}
                    placeholder="Enter Webhook url"
                    className={classes.connectionTextField}
                    value={webhook_url}
                    onChange={e => handleWebhookUrl(idx, e.target.value)}
                    autoComplete="off"
                  />

                  <Box width={1} mt={2}>
                    <Typography variant={'body1'} mb={1}>
                      Secret
                    </Typography>
                    <Box display="flex" alignItems="center" justifyContent={'space-between'}>
                      <TextField
                        name="password"
                        fullWidth
                        disabled={!edit && webhook_id !== ''}
                        placeholder="Webhook secret"
                        className={classes.connectionTextField}
                        style={{ width: '80%' }}
                        type={showSecret ? 'text' : 'password'}
                        value={webhook_secret}
                        autoComplete="off"
                        onChange={e => handleWebhookSecret(idx, e.target.value)}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => setShowSecret(!showSecret)}
                                size="large"
                              >
                                {showSecret ? <Visibility /> : <VisibilityOff />}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                      <Box display={'flex'} alignItems={'center'}>
                        {webhook_id === '' || edit ? (
                          <Button
                            variant="contained"
                            onClick={() =>
                              handleWebhookCreation(webhook_id, webhook_url, webhook_secret, webhook_type, type)
                            }
                          >
                            Save
                          </Button>
                        ) : (
                          <Button variant="outlined" onClick={() => setEdit(true)}>
                            Edit
                          </Button>
                        )}
                        {!edit && webhook_id !== '' && (
                          <img
                            src={binImg}
                            alt=""
                            style={{ marginLeft: '0.5rem', cursor: 'pointer' }}
                            onClick={() => handleDeleteWebhook(idx, webhook_id)}
                          />
                        )}
                      </Box>
                    </Box>
                  </Box>

                  {message && (
                    <Typography
                      variant={'subtitle1'}
                      color={message === 'Deleted successfully' ? `error.main` : `success.main`}
                      pt={1}
                    >
                      {message}
                    </Typography>
                  )}
                </Box>
              </>
            ))}
          </div>
        )}

        {isConnected && connectionItem.connection_value === 'segment_stream' && (
          <div>
            {webhookList.map(({ webhook_url, webhook_secret, webhook_id, webhook_type, type }, idx) => (
              <>
                <div className={classes.border}></div>
                <Box pl={9} pr={9} pt={4}>
                  <Typography variant={'body1'} mb={1}>
                    Segment HTTP API Key
                  </Typography>

                  <TextField
                    fullWidth
                    placeholder="Enter API Key"
                    className={classes.connectionTextField}
                    value={webhook_secret}
                    onChange={e => handleWebhookSecret(idx, e.target.value)}
                  />

                  <Button
                    variant="contained"
                    style={{ marginTop: 20 }}
                    onClick={() => handleWebhookCreation(webhook_id, webhook_url, webhook_secret, webhook_type, type)}
                  >
                    Save
                  </Button>

                  {message && (
                    <Typography
                      variant={'subtitle1'}
                      color={message === 'Deleted successfully' ? `error.main` : `success.main`}
                      pt={1}
                    >
                      {message}
                    </Typography>
                  )}
                </Box>
              </>
            ))}
          </div>
        )}

        {isConnected && connectionItem.connection_value === 'amplitude_destination' && (
          <div>
            {webhookList.map(({ webhook_url, webhook_secret, webhook_id, webhook_type, type }, idx) => (
              <>
                <div className={classes.border}></div>
                <Box pl={9} pr={9} pt={4}>
                  <Typography variant={'body1'} mb={1}>
                    Amplitude API Key
                  </Typography>

                  <TextField
                    fullWidth
                    placeholder="Enter API Key"
                    className={classes.connectionTextField}
                    value={webhook_secret}
                    onChange={e => handleWebhookSecret(idx, e.target.value)}
                  />

                  <Button
                    variant="contained"
                    style={{ marginTop: 20 }}
                    onClick={() => handleWebhookCreation(webhook_id, webhook_url, webhook_secret, webhook_type, type)}
                  >
                    Save
                  </Button>

                  {message && (
                    <Typography
                      variant={'subtitle1'}
                      color={message === 'Deleted successfully' ? `error.main` : `success.main`}
                      pt={1}
                    >
                      {message}
                    </Typography>
                  )}
                </Box>
              </>
            ))}
          </div>
        )}

        {isConnected && connectionItem.connection_value === 'mixpanel_destination' && (
          <div>
            {webhookList.map(({ webhook_url, webhook_secret, webhook_id, webhook_type, type }, idx) => (
              <>
                <div className={classes.border}></div>
                <Box pl={9} pr={9} pt={4}>
                  <Typography variant={'body1'} mb={1}>
                    Mixpanel Project Id
                  </Typography>

                  <TextField
                    fullWidth
                    placeholder={'Enter Project Id'}
                    className={classes.connectionTextField}
                    value={projectId}
                    onChange={e => handleMixpanelProjectId(idx, e.target.value)}
                  />

                  <Typography variant={'body1'} mb={1} mt={3}>
                    Mixpanel Service UserName
                  </Typography>

                  <TextField
                    fullWidth
                    placeholder={'Enter username'}
                    className={classes.connectionTextField}
                    value={mixpanelUsername}
                    onChange={e => handleMixpanelValues(idx, 'user_name', e.target.value)}
                  />

                  <Typography variant={'body1'} mb={1} mt={2}>
                    Mixpanel Service Secret
                  </Typography>

                  <TextField
                    fullWidth
                    placeholder={'Enter secret'}
                    className={classes.connectionTextField}
                    value={mixpanelUserSecret}
                    onChange={e => handleMixpanelValues(idx, 'user_secret', e.target.value)}
                  />

                  <Button
                    variant="contained"
                    style={{ marginTop: 20 }}
                    onClick={() => handleWebhookCreation(webhook_id, webhook_url, webhook_secret, webhook_type, type)}
                  >
                    Save
                  </Button>

                  {message && (
                    <Typography
                      variant={'subtitle1'}
                      color={message === 'Deleted successfully' ? `error.main` : `success.main`}
                      pt={1}
                    >
                      {message}
                    </Typography>
                  )}
                </Box>
              </>
            ))}
          </div>
        )}

        {isConnected && connectionItem.connection_value === 'clevertap_destination' && (
          <div>
            {webhookList.map(({ webhook_url, webhook_secret, webhook_id, webhook_type, type }, idx) => (
              <>
                <div className={classes.border}></div>
                <Box pl={9} pr={9} pt={4}>
                  <Typography variant={'body1'} mb={1}>
                    Account ID
                  </Typography>

                  <TextField
                    fullWidth
                    placeholder={'Enter Account ID'}
                    className={classes.connectionTextField}
                    value={mixpanelUsername}
                    onChange={e => handleMixpanelValues(idx, 'user_name', e.target.value)}
                  />

                  <Typography variant={'body1'} mb={1} mt={2}>
                    Passcode
                  </Typography>

                  <TextField
                    fullWidth
                    placeholder={'Enter Passcode'}
                    className={classes.connectionTextField}
                    value={mixpanelUserSecret}
                    onChange={e => handleMixpanelValues(idx, 'user_secret', e.target.value)}
                  />

                  <Button
                    variant="contained"
                    style={{ marginTop: 20 }}
                    onClick={() => handleWebhookCreation(webhook_id, webhook_url, webhook_secret, webhook_type, type)}
                  >
                    Save
                  </Button>

                  {message && (
                    <Typography
                      variant={'subtitle1'}
                      color={message === 'Deleted successfully' ? `error.main` : `success.main`}
                      pt={1}
                    >
                      {message}
                    </Typography>
                  )}
                </Box>
              </>
            ))}
          </div>
        )}

        {isConnected && connectionItem.connection_value === 'rudderstack_destination' && (
          <div>
            {webhookList.map(({ webhook_url, webhook_secret, webhook_id, webhook_type, type }, idx) => (
              <>
                <div className={classes.border}></div>
                <Box pl={9} pr={9} pt={4} key={idx}>
                  <Typography variant={'body1'} mb={1}>
                    Data Plane Url
                  </Typography>

                  <TextField
                    fullWidth
                    placeholder="Enter Data Plane url"
                    className={classes.connectionTextField}
                    value={webhook_url}
                    onChange={e => handleWebhookUrl(idx, e.target.value)}
                    autoComplete="off"
                  />

                  <Box width={1} mt={2}>
                    <Typography variant={'body1'} mb={1}>
                      Write Key
                    </Typography>
                    <Box display="flex" alignItems="center" justifyContent={'space-between'}>
                      <TextField
                        name="password"
                        fullWidth
                        placeholder="Enter Write Key"
                        className={classes.connectionTextField}
                        type={showSecret ? 'text' : 'password'}
                        value={webhook_secret}
                        autoComplete="off"
                        onChange={e => handleWebhookSecret(idx, e.target.value)}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => setShowSecret(!showSecret)}
                                size="large"
                              >
                                {showSecret ? <Visibility /> : <VisibilityOff />}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Box>
                  </Box>

                  <Button
                    variant="contained"
                    style={{ marginTop: 20 }}
                    onClick={() => handleWebhookCreation(webhook_id, webhook_url, webhook_secret, webhook_type, type)}
                  >
                    Save
                  </Button>

                  {message && (
                    <Typography
                      variant={'subtitle1'}
                      color={message === 'Deleted successfully' ? `error.main` : `success.main`}
                      pt={1}
                    >
                      {message}
                    </Typography>
                  )}
                </Box>
              </>
            ))}
          </div>
        )}

        {isConnected && ['freshdesk', 'zendesk', 'intercom'].includes(connectionItem.connection_value) && (
          <div>
            {webhookList.map(({ webhook_url, webhook_secret, webhook_id, webhook_type, type }, idx) => (
              <>
                <div className={classes.border}></div>
                <Box pl={9} pr={9} pt={4} key={idx}>
                  {connectionItem.connection_value !== 'intercom' && (
                    <>
                      <Typography variant={'body1'} mb={1}>
                        {_.capitalize(connectionItem.connection_value)} URL
                      </Typography>

                      <TextField
                        fullWidth
                        placeholder={`Enter ${connectionItem.connection_value} url (e.g: xxxx.${connectionItem.connection_value}.com)`}
                        className={classes.connectionTextField}
                        value={webhook_url}
                        onChange={e => handleWebhookUrl(idx, e.target.value)}
                        autoComplete="off"
                      />
                    </>
                  )}

                  <Box width={1} mt={2}>
                    <Typography variant={'body1'} mb={1}>
                      API Key
                    </Typography>
                    <Box display="flex" alignItems="center" justifyContent={'space-between'}>
                      <TextField
                        name="password"
                        fullWidth
                        placeholder="Enter API Key"
                        className={classes.connectionTextField}
                        type={showSecret ? 'text' : 'password'}
                        value={webhook_secret}
                        autoComplete="off"
                        onChange={e => handleWebhookSecret(idx, e.target.value)}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => setShowSecret(!showSecret)}
                                size="large"
                              >
                                {showSecret ? <Visibility /> : <VisibilityOff />}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Box>
                  </Box>

                  <Button
                    variant="contained"
                    style={{ marginTop: 20 }}
                    onClick={() =>
                      handleWebhookCreation(
                        webhook_id,
                        webhook_url,
                        webhook_secret,
                        connectionItem.connection_value,
                        type,
                      )
                    }
                  >
                    Save
                  </Button>

                  {message && (
                    <Typography
                      variant={'subtitle1'}
                      color={message === 'Deleted successfully' ? `error.main` : `success.main`}
                      pt={1}
                    >
                      {message}
                    </Typography>
                  )}
                </Box>
              </>
            ))}
          </div>
        )}

        {isConnected && connectionItem.connection_value === 'slack' && (
          <div>
            <div className={classes.border}></div>
            <Box pl={9} pr={9} pt={4}>
              <Typography variant={'body1'} mb={1}>
                Channel to get daily Blitzllama updates
              </Typography>

              <Box display={'flex'} alignItems={'center'}>
                <Select
                  variant="outlined"
                  displayEmpty
                  fullWidth
                  className={classes.selectField}
                  renderValue={() => <Typography>{channel ? `${channel}` : `Choose channel`}</Typography>}
                  value={channel}
                >
                  <Box style={{ maxHeight: 240 }}>
                    {slackChannels.map((item, key) => (
                      <MenuItem key={key} value={item} onClick={e => setChannel(item)}>
                        <Typography variant={'subtitle1'}>{item}</Typography>
                      </MenuItem>
                    ))}
                  </Box>
                </Select>
                <Button variant="contained" style={{ marginLeft: '10px' }} onClick={() => updateChannel()}>
                  Save
                </Button>
              </Box>

              <Box
                display={'flex'}
                alignItems={'center'}
                mt={2}
                onClick={() => setSlackResponseAlert(!slackResponseAlert)}
              >
                <Checkbox
                  classes={{
                    root: classes.check,
                    checked: classes.checked,
                  }}
                  checked={slackResponseAlert}
                />
                <Typography style={{ paddingLeft: 5, cursor: 'pointer' }}>Alert on every survey response</Typography>
              </Box>
              {message && (
                <Typography variant={'subtitle1'} color={'success.main'}>
                  Updated Succesfully
                </Typography>
              )}
            </Box>
          </div>
        )}

        <div className={classes.border}></div>
        <Box display="flex">
          <Box p={9} pt={2.5} pb={5} pr={3}>
            <Box display="flex">
              <img src={arrowImg} alt="" width="10px" />
              <a href={connectionItem.documentation}>
                <Typography variant={'subtitle1'} pl={0.8} color={'primary.contrastText'} width="14ch">
                  Documentation
                </Typography>
              </a>
            </Box>
            {connectionItem.connection_value === 'csv' && (
              <Box display="flex" mt={2}>
                <img src={arrowImg} alt="" width="10px" />
                <a href={'/sample.csv'} download>
                  <Typography variant={'subtitle1'} pl={0.8} color={'primary.contrastText'} width="14ch">
                    Sample File
                  </Typography>
                </a>
              </Box>
            )}
          </Box>
          <div
            style={{
              height: connectionItem.connection_key ? 'calc(100vh - 600px)' : 'calc(100vh - 300px)',
            }}
          ></div>
          <Box pt={2.5} p={3} width={1} className={classes.borderLeft}>
            <Typography variant="h6">Developer Effort</Typography>
            <Typography variant={'subtitle1'}>{connectionItem.developer_effort}</Typography>
            <Typography variant="h6" pt={4.6} pb={1.8}>
              Best used for
            </Typography>
            {connectionItem.best_use?.map((item, idx) => (
              <Typography key={idx} variant={'subtitle1'}>
                - {item}
              </Typography>
            ))}
            <Typography variant="h6" pt={4.6} pb={1.8}>
              What you’ll need
            </Typography>
            {connectionItem.need?.map((item, idx) => (
              <Typography key={idx} variant={'subtitle1'}>
                - {item}
              </Typography>
            ))}
          </Box>
        </Box>
      </Box>
    </Drawer>
  );
}
